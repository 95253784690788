import PropTypes from 'prop-types';
import React, { memo } from 'react';

/**
 * Stateless component for search result table head.
 *
 * @param props {Object}.
 * @param props.companiesCount {number} Number of company in searching result.
 * @param props.peopleCount {number} Number of person in searching result.
 * @param props.onTabChange {function} Function for handling tab change.
 * @param props.activeTab {string} Name of the current active tab.
 * @returns {React.Component}
 * @class
 */
const SearchResultHeadComponent = ({
  hasAccess,
  companiesCount,
  peopleCount,
  onTabChange,
  activeTab,
}) => {
  const peopleTab = hasAccess ? (
    <li className={activeTab === 'people' ? 'active' : ''} role="presentation">
      <a
        aria-controls="home"
        aria-expanded="false"
        data-toggle="tab"
        href="#people"
        onClick={() => onTabChange('people')}
        role="tab"
      >
        People <strong>({peopleCount})</strong>
      </a>
    </li>
  ) : null;

  return (
    <ul className="nav nav-pills search-nav" role="tablist">
      <li className={activeTab === 'companies' ? 'active' : ''} role="presentation">
        <a
          aria-controls="home"
          aria-expanded="true"
          className="btn-xs"
          data-toggle="tab"
          href="#companies"
          onClick={() => onTabChange('companies')}
          role="tab"
        >
          Companies <strong>({companiesCount})</strong>
        </a>
      </li>
      {peopleTab}
    </ul>
  );
};

SearchResultHeadComponent.propTypes = {
  activeTab: PropTypes.string.isRequired,
  companiesCount: PropTypes.number.isRequired,
  onTabChange: PropTypes.func.isRequired,
  peopleCount: PropTypes.number.isRequired,
};

export const SearchResultHead = memo(SearchResultHeadComponent);
