import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import FormGroup from '../helpers/FormGroup';
import Select from '../helpers/Select';

/**
 * Stateless component for role dropdownlist.
 *
 * @param props {Object}.
 * @param props.roles {Immutable.List} List of system roles.
 * @param props.formMode {string} Disabled state of control (view|edit).
 * @param props.formError {Immutable.Map} List of errors on form.
 * @param props.selectedUser {Immutable.Map} Current selected user.
 * @returns {React.Component}
 * @class
 */
const UserRoleListComponent = ({ formMode, formError, selectedUser, roles }) => {
  const hasError = formError.get('role', '').length > 0 && formMode === 'edit';
  const value = selectedUser.get('role', '');

  return (
    <FormGroup className={hasError ? 'has-error' : ''}>
      <Select
        className="form-control input-sm"
        defaultText="Select Roles..."
        disabled={formMode === 'view'}
        id="roleSelect"
        label="Role"
        name="role"
        nameKey="name"
        options={roles}
        value={value}
        valueKey="slug"
      />
    </FormGroup>
  );
};

UserRoleListComponent.propTypes = {
  formError: PropTypes.instanceOf(Immutable.Map).isRequired,
  formMode: PropTypes.string.isRequired,
  roles: PropTypes.instanceOf(Immutable.List).isRequired,
  selectedUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export const UserRoleList = memo(UserRoleListComponent);
