import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';
import classNames from 'classnames';

import config from '../../../config';

/**
 * Project text area component.
 *
 * @param props {Object}.
 * @param props.id {String} Id of control.
 * @param props.name {String} Name of control.
 * @param props.value {String} Value of control.
 * @param props.label {String} Label of control.
 * @returns {React.Component}
 */
const ProjectTextAreaComponent = ({ id, name, field, label, onTextChange }) => {
  const className = classNames({ 'dirty-field': field.get('dirty') }, 'form-control', 'project-top-panel-textarea');
  const type = config.values.getIn(['project', 'types', 'PROJECT']);

  return (
    <div className="col-md-2">
      <div className="form-group">
        <label htmlFor={id}>{label}</label>
        <textarea
          className={className}
          id={id}
          name={name}
          onChange={event => onTextChange({ event, data: { type } })}
          rows="3"
          value={field.get('name')}
        />
      </div>
    </div>
  );
};

ProjectTextAreaComponent.propTypes = {
  field: PropTypes.instanceOf(Immutable.Map).isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onTextChange: PropTypes.func.isRequired,
};

export const ProjectTextArea = memo(ProjectTextAreaComponent);
