import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { List } from 'immutable';
import { SortableContainer } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

import Select from '../../helpers/Select';
import { sortByNumber } from '../../../utils/sorting';
import SorterRow from '../../helpers/SorterRow';

/**
 * Stateless component for sort panel.
 *
 * @param props {Object}.
 * @param props.sorts {Immutable.List} List of user's sorters.
 * @param props.onChangeSort {Function} To handle changing a sorting field.
 * @param props.onChangeOrder {Function} To handle changing sorting order.
 * @returns {React.Component} Component.
 */
class Sorter extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      draggingIndex: null,
      items: this.getColumns(props.sorts),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      items: this.getColumns(nextProps.sorts),
    });
  }

  /**
   * Get list of sorting column from inputted sort list.
   *
   * @param sorts {Immutable.List} List being sorted.
   * @returns {Array} Columns list.
   */
  getColumns(sorts) {
    return sortByNumber(
      sorts.filter(s => s.get('included') === true),
      'order',
      -1,
    ) // -1 = increased order
      .map(col => ({
        name: col.get('si'),
        text: col.get('text'),
        direction: col.get('sd') || 1,
      }))
      .toJS();
  }

  /**
   * Map sorted items in list to object.
   *
   * @param items {Array}.
   */
  mapToSorts(items) {
    const htbItems = {};

    for (let i = 0; i < items.length; i++) {
      htbItems[items[i].name] = i;
    }

    return htbItems;
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ items }) => ({
        items: arrayMoveImmutable(items, oldIndex, newIndex),
      }),
      () => {
        const { draggingIndex, items } = this.state;
        const { onChangeOrder } = this.props;

        if (draggingIndex === null) {
          onChangeOrder(this.mapToSorts(items));
        }
      },
    );
  };

  render() {
    const { onChangeSort, sorts } = this.props;
    const { items } = this.state;

    return (
      <div>
        <p>Sort Order</p>
        <SortableList distance={1} items={items} lockAxis="y" onChangeSort={onChangeSort} onSortEnd={this.onSortEnd} />
        <Select
          defaultText="Add..."
          id="sortFieldSelect"
          label="Sort fields"
          name="sorts"
          nameKey="text"
          onChange={event => onChangeSort(event.target.value, 1, true, items.length)}
          options={sorts}
          value="Add..."
          valueKey="si"
        />
      </div>
    );
  }
}

const SortableList = SortableContainer(({ items, onChangeSort }) => (
  <div className="sort-menu">
    {items.map((c, i) => (
      <SorterRow
        key={i}
        childProps={{
          columnName: c.name,
          direction: c.direction,
          onChangeSort,
        }}
        index={i}
      >
        {c.text}
      </SorterRow>
    ))}
  </div>
));

Sorter.propTypes = {
  onChangeOrder: PropTypes.func.isRequired,
  onChangeSort: PropTypes.func.isRequired,
  sorts: PropTypes.instanceOf(List).isRequired,
};

export default Sorter;
