import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';
import uniqueId from 'src/utils/uniqueId';

import DropDown, { DropDownElement } from '../../components/helpers/DropDown';

import { BrowseCheckbox } from './BrowseCheckbox';

/**
 * Browse drop down component.
 *
 * @param props {Object}.
 * @param props.filter {Immutable.Map} Filter Object.
 * @param props.info {Immutable.Map} Info Object.
 * @param props.onChange {Function} Handle event of change.
 * @param props.children {React.ReactNode} Children elements.
 * @returns {React.Component}
 * @class
 */
const BrowseDropDownComponent = ({ filter, info, onChange }) => {
  const componentId = `dropdown${uniqueId()}`;

  const handleDropDownChange = event => {
    const { value } = event.target;

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selected: {
          name: value,
          value,
        },
      },
    });
  }


  const dropdownContent = filter.get('options').map((item, i) => (
    <DropDownElement key={i} value={item.get('value')}>
      {item.get('name')}
    </DropDownElement>
  ));

  return (
    <BrowseCheckbox
      filter={filter}
      info={info}
      onChange={onChange}
    >
      <DropDown
        btnContent={info.get('selected')}
        className="btn btn-default btn-xs"
        id={componentId}
        name={componentId}
        onChange={handleDropDownChange}
      >
        {dropdownContent}
      </DropDown>
    </BrowseCheckbox>
  );
}

BrowseDropDownComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

/** Browse drop down component. */
export const BrowseDropDown = memo(BrowseDropDownComponent);
