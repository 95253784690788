import PropTypes from 'prop-types';
import React, { memo } from 'react';

/**
 * Footer component.
 *
 * @param props {Object}.
 * @param props.savePending {Boolean} If true show spinner and disable save button.
 * @param props.onExport {Function} To handle exporting.
 * @param props.onSave {Function} To handle saving form.
 * @param props.onReload {Function} To handle reloading form.
 * @returns {React.Component}
 */
const FooterComponent = ({ savePending, onExport, onSave, onReload } ) => {
  return (
    <nav className="navbar-fixed-bottom">
      <div className="container-fluid">
        <div className="project-footer">
          <a className="btn btn-default btn-xs" onClick={onExport}>
            <span className="fa-external-link fa" />
            Export Universe
          </a>
          <a className="btn btn-default btn-xs" disabled={!savePending} onClick={onSave}>
            <span className="fa-check fa" />
            Save
          </a>
          <a className="btn btn-default btn-xs" onClick={onReload}>
            <span className="fa-refresh fa" />
            Reload
          </a>
        </div>
      </div>
    </nav>
  );
};

FooterComponent.propTypes = {
  onExport: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  savePending: PropTypes.bool.isRequired,
};

export const Footer = memo(FooterComponent);
