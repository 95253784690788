import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map, List } from 'immutable';

import PrettyError from '../decorators/PrettyError';
import DisplayError from '../decorators/DisplayError';
import _Input from '../helpers/Input';
import { BacklogTable } from './BacklogTable';
import { UserList } from './UserList';

const Input = PrettyError(DisplayError(_Input));

const BacklogComponent = ({
  users,
  backlogs,
  columnConfigs,
  tableHeight,
  userLoading,
  backlogLoading,
  currentPage,
  totalPages,
  onChangeUser,
  onSort,
  onClick,
  onDoubleClick,
  getHeaderRef,
  onGetNextPageData,
  onReloadClick,
  onOneOffApproachClick,
  onGridReady,
  onDateChange,
  dateQuery,
}) => {
  const datepicker = (
    <Input
      className="input-sm"
      disabled={false}
      name="dateQuery"
      onChange={onDateChange}
      placeholder="Date"
      root={Map({ dateQuery })}
      type="date"
      deep
    />
  );

  return (
    <div className="container" role="main">
      <div className="starter-template">
        <div className="row">
          <div ref={getHeaderRef} className="wrap-mailing-backlog">
            <div className="row">
              <div className="col-xs-12 col-sm-2 col-sm-offset-4">
                <h1 className="text-center">Mailing Backlog</h1>
              </div>
              <div className="col-xs-12 col-sm-1">
                <h1 className="text-center">{datepicker}</h1>
              </div>
            </div>
            <UserList
              loading={userLoading}
              onChange={onChangeUser}
              onClick={onClick}
              onOneOffApproachClick={onOneOffApproachClick}
              onReloadClick={onReloadClick}
              users={users}
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="table-responsive">
              <BacklogTable
                backlogs={backlogs}
                columnConfigs={columnConfigs}
                currentPage={currentPage}
                height={tableHeight}
                loading={backlogLoading}
                onDoubleClick={onDoubleClick}
                onGetNextPageData={onGetNextPageData}
                onGridReady={onGridReady}
                onSort={onSort}
                totalPages={totalPages}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BacklogComponent.propTypes = {
  backlogLoading: PropTypes.bool.isRequired,
  backlogs: PropTypes.instanceOf(List).isRequired,
  columnConfigs: PropTypes.instanceOf(List).isRequired,
  currentPage: PropTypes.number.isRequired,
  getHeaderRef: PropTypes.func.isRequired,
  onChangeUser: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onGridReady: PropTypes.func.isRequired,
  onOneOffApproachClick: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  tableHeight: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  userLoading: PropTypes.bool.isRequired,
  users: PropTypes.instanceOf(List).isRequired,
};

/** Backlog component. */
export const Backlog = memo(BacklogComponent);
