import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

const TagComponent = ({ value = '', id, exclude = false, index, onRemoveTag, onChangeStatus }) => {
  const tagClassName = classNames('MultiselectItem', { exclude });

  return (
    <div className={tagClassName} onClick={() => onChangeStatus(index, id)}>
      {value}
      <button
        aria-hidden="true"
        aria-label="close"
        className="MultiselectItem-close close btn-xs"
        name={`tag${index}`}
        onClickCapture={event => onRemoveTag(event, id)}
        type="button"
      >
        &times;
      </button>
    </div>
  );
};

TagComponent.propTypes = {
  exclude: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  value: PropTypes.any,
};

/** Tag component. */
export const Tag = memo(TagComponent);
