import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import AddHarvcoTagPopup from '../../components/Popup/AddHarvcoTagPopup';

class AddHarvcoTagPopupContainer extends Component {
  constructor(props, context) {
    super(props, context);

    this.tag = '';
    this.onChange = this.onChange.bind(this);
    this.onAddTag = this.onAddTag.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onChange(event) {
    const {
      target: { name, value },
    } = event;

    if (!name) return;
    this.tag = value;
  }

  onAddTag() {
    const onAddTag = this.props.popup.getIn(['props', 'onAddTag'], () => {});
    const refreshGrid = this.props.popup.getIn(['props', 'refreshGrid'], () => {});

    onAddTag(this.tag, {
      beforeStart: this.context.startPopupLoading,
      afterSuccess: () => {
        refreshGrid();
        this.context.endPopupLoading();
      },
      afterError: ({ resBody }) => {
        this.context.endPopupLoading(resBody);
      },
    });
  }

  onClose() {
    this.context.closePopup();
  }

  render() {
    return (
      <div>
        <AddHarvcoTagPopup
          isPopupFetching={this.props.popup.get('isPopupFetching')}
          isPopupFetchingError={this.props.popup.get('isPopupFetchingError')}
          onAddTag={this.onAddTag}
          onChange={this.onChange}
          onClose={this.onClose}
        />
      </div>
    );
  }
}

AddHarvcoTagPopupContainer.contextTypes = {
  closePopup: PropTypes.func.isRequired,
  startPopupLoading: PropTypes.func.isRequired,
  endPopupLoading: PropTypes.func.isRequired,
};

export { AddHarvcoTagPopupContainer };
export default connect(() => ({}), {}, mergeProps, connectOptions)(AddHarvcoTagPopupContainer);
