import PropTypes from 'prop-types';
import React, {memo} from 'react';
import Immutable from 'immutable';

import { TableBody } from './TableBody';

/**
 * User table component.
 *
 * @param props {Object}.
 * @param props.tableCells {Immutable.List} Array of cells in table.
 * @param props.users {Immutable.List} List of users needed to display in the table.
 * @param props.onRowClick {function} Handle row click.
 * @param props.selectedUserId {number} Selected user id.
 * @returns {React.Component}
 */
const UserTableComponent = ({ tableCells, users, selectedUserId, userLoading, onRowClick, onRemove2FaClick }) => {
  return (
    <div className="table-responsive mb20 user-table">
      <TableBody
        columns={tableCells}
        loading={userLoading}
        onRemove2FaClick={onRemove2FaClick}
        onRowClick={onRowClick}
        selectedId={selectedUserId}
        users={users}
      />
    </div>
  );
};

UserTableComponent.propTypes = {
  onRowClick: PropTypes.func.isRequired,
  selectedUserId: PropTypes.number,
  tableCells: PropTypes.instanceOf(Immutable.List).isRequired,
  userLoading: PropTypes.bool,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

export const UserTable = memo(UserTableComponent);
