import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map, fromJS } from 'immutable';

import { BrowseCheckbox } from './BrowseCheckbox';
import { Tag } from './Tag';

class BrowseInputTag extends PureComponent {
  constructor(props) {
    super(props);

    this.handleRemoveTag = this.handleRemoveTag.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleAddTag = this.handleAddTag.bind(this);
    this.handleEnterKeyPress = this.handleEnterKeyPress.bind(this);
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.updateListId = this.updateListId.bind(this);
  }

  handleTextChange(event) {
    const { value } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        text: value,
      },
    });
  }

  handleEnterKeyPress(event) {
    if (event.key === 'Enter' && this.props.submitOnEnter === true) {
      this.handleAddTag(false);
    }
  }

  handleAddTag(displayBIC = true) {
    const { info } = this.props;
    const text = info.get('text');
    let selectedList = info.get('selectedList');

    if (text && !selectedList.includes(text)) {
      selectedList = selectedList.push(
        new Map({
          value: text,
        }),
      );

      this.props.onChange({
        filterName: this.props.filter.get('name'),
        filterData: {
          selectedList,
          text: '',
        },
      });
    }

    if (displayBIC && this.props.isBIC) {
      this.context.openPopup('TagsManagementPopup', {
        addNew: false,
        selectedTags: info.get('selectedTags', Map()).toJS(),
        selectedModels: info.get('selectedModels', Map()).toJS(),
        callback: this.updateListId,
      });
    }
  }

  updateListId(tagSelecteds, modelSelecteds) {
    const { info } = this.props;
    const prevSelectedTags = info.get('selectedTags', Map());
    const prevModelSelecteds = info.get('modelSelecteds', Map());

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selectedTags: fromJS(this.mapTags(tagSelecteds, prevSelectedTags)),
        selectedModels: fromJS(this.mapTags(modelSelecteds, prevModelSelecteds)),
      },
    });
  }

  mapTags(selecteds, prevList) {
    return selecteds.reduce((acc, it) => {
      acc[it.id] = {
        value: it.category,
        id: it.id,
        exclude: prevList.has(it.id) ? prevList.getIn([it.id, 'exclude']) : false,
      };

      return acc;
    }, {});
  }

  handleChangeStatus(index) {
    const { filter, info, onChange } = this.props;
    const selectedList = info.get('selectedList');

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList: selectedList.setIn([index, 'exclude'], !selectedList.getIn([index, 'exclude'])),
      },
    });
  }

  handleChangeStatusBIC(id, name) {
    const { filter, info, onChange } = this.props;
    const selectedList = info.get(name);

    onChange({
      filterName: filter.get('name'),
      filterData: {
        [name]: selectedList.setIn([id, 'exclude'], !selectedList.getIn([id, 'exclude'])),
      },
    });
  }

  handleRemoveTag(event) {
    event.stopPropagation();

    const { name } = event.target;
    const tagIndex = parseInt(name.replace('tag', ''), 10);
    let selectedList = this.props.info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  handleRemoveTagBIC(event, id, name) {
    event.stopPropagation();

    let selectedList = this.props.info.get(name);

    selectedList = selectedList.delete(id);

    this.props.onChange({
      key: name,
      filterName: this.props.filter.get('name'),
      filterData: {
        [name]: selectedList,
      },
    });
  }

  render() {
    const { filter, info } = this.props;
    const selectedList = info.get('selectedList');
    const description = filter.get('description') ? <em>{filter.get('description')}</em> : null;
    const tagListContent = selectedList.map((tag, i) => (
      <Tag
        key={i}
        exclude={tag.get('exclude')}
        index={i}
        onChangeStatus={this.handleChangeStatus}
        onRemoveTag={this.handleRemoveTag}
        value={tag.get('value')}
      />
    ));

    let industryContent = null;
    let modelContent = null;

    if (this.props.isBIC) {
      const tmp = info.toJS();

      industryContent = Object.entries(tmp.selectedTags || {}).map(([key, val], i) => (
        <Tag
          key={key}
          exclude={val.exclude}
          id={key}
          index={i}
          onChangeStatus={(index, id) => this.handleChangeStatusBIC(id, 'selectedTags')}
          onRemoveTag={(event, id) => this.handleRemoveTagBIC(event, id, 'selectedTags')}
          value={val.value}
        />
      ));
      modelContent = Object.entries(tmp.selectedModels || {}).map(([key, val], i) => (
        <Tag
          key={key}
          exclude={val.exclude}
          id={key}
          index={i}
          onChangeStatus={(index, id) => this.handleChangeStatusBIC(id, 'selectedModels')}
          onRemoveTag={(event, id) => this.handleRemoveTagBIC(event, id, 'selectedModels')}
          value={val.value}
        />
      ));
    }

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--add">
          {description}
          {tagListContent}
          {industryContent}
          {modelContent}
          <div className="form-inline">
            <div className="form-group input-group">
              <input
                className="form-control"
                onChange={this.handleTextChange}
                onKeyPress={this.handleEnterKeyPress}
                type="text"
                value={info.get('text')}
              />
              <span className="input-group-addon" onClick={this.handleAddTag}>
                <i aria-hidden="true" className="fa fa-plus" />
              </span>
            </div>
          </div>
        </div>
      </BrowseCheckbox>
    );
  }
}
BrowseInputTag.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  submitOnEnter: PropTypes.bool,
};
BrowseInputTag.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export default BrowseInputTag;
