import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

/**
 * Column header component.
 *
 * @param props {Object}.
 * @param props.column {Immutable.List} Column list.
 * @returns {React.Component}
 */
const TableHeaderComponent = ({ columns }) => {
  const mapCol = (col, index) => (
    <th key={index} style={{ width: `${col.get('width')}%` }}>
      {col.get('title')}
    </th>
  );

  return (
    <div className="user-tb-header-bg">
      <table className="table table table-bordered table-hover table-fixed mb0" id="userTableHeader">
        <tbody>
          <tr>{columns.map(mapCol)}</tr>
        </tbody>
      </table>
    </div>
  );
};

TableHeaderComponent.propTypes = {
  columns: PropTypes.instanceOf(Immutable.List).isRequired,
};

export const TableHeader = memo(TableHeaderComponent);
