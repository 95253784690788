import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import $ from 'jquery';
import { List, Map, fromJS } from 'immutable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import classNames from 'classnames';
import { push } from 'connected-react18-router';
import { createSelector } from 'reselect';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import copyToClipboard from '../../utils/copyToClipboard';
import confirm from '../decorators/confirm';
import stopPropagation from '../../decorators/stopPropagation';
import AutoCompleteContainer from '../AutoComplete';
import deletionCheck from '../services/deletionCheck';
import { isNew } from '../../utils/uniqueId';
import { preventDrop, allowDrop } from '../../utils/dropPrevent';
import endingRemoval from '../../utils/endingRemoval';
import { orderContactService } from '../services/contact';
import { unwrap, getChanged, isDeepChanged, toJS } from '../../utils/ChangeSpy';
import configureEmailMergedFields from '../../utils/configureEmailMergedFields';
import getContactMailLink from '../../utils/getContactMailLink';
import config from '../../config';

import {
  enterCompanyDealNote,
  enterCompanyEmail,
  leaveCompanyDealNote,
  leaveCompanyEmail,
} from '../../actions/company/companyEvents';
import * as companyTargetActions from '../../actions/company/companyTarget';
import { leadMemoReportRequest } from '../../actions/company/target/leadMemoReport';
import { updateDealNoteWidth } from '../../actions/auth';
import { reloadMainInfo } from '../../actions/companyDetail';
import {
  getTradeShows,
  loadSelectedTradeShow,
  removeTradeShow,
  saveCompanyContact,
  saveTradeShows,
  updateLastResearcher,
} from '../../actions/company/companyTarget';
import { fetchTargetStatuses } from '../../actions/statuses';
import { fetchEmailTemplates } from '../../actions/emailTemplates';

import CompanyTarget from '../../components/Company/CompanyTarget';

import { checkAccess, isResearcher, basicAccess } from '../../utils/checkPermissions';
import { showError, showInformationCustomTitle } from '../../utils/MessagePopup';
import { getLastEventBuyerText } from '../../helpers/getLastEventBuyerText';
import precheckDetail from './target/entitiesPreCheck';
import prepareDetail from './target/entitiesApiPrepape';
import { BUSINESS_MODELS, INDUSTRY_CATEGORIES, INDUSTRY_CATEGORY_ERROR } from '../../utils/industryTagsHelper';

const emptyList = List();

const normalizeField = data => {
  ['fyeMonth', 'dataQuality', 'corporationType'].forEach(field => {
    if (field in data) {
      data[field] = data[field] || null;
    }
  });

  return data;
};

const normalizeDate = date => {
  if (!date) return null;
  try {
    date = date.format('YYYY-MM-DD');
  } catch (err) {
    date = moment().format('YYYY-MM-DD');
  }

  return date;
};

const HOURS_LIMIT = 24;

export class CompanyTargetContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSubmit = this.onSubmit.bind(this);
    this.onDetailSubmit = this.onDetailSubmit.bind(this);
    this.onBoardIndustrySubmit = this.onBoardIndustrySubmit.bind(this);
    this.onDetailSubmitItem = this.onDetailSubmitItem.bind(this);
    this.getTradeShowsSuggest = this.getTradeShowsSuggest.bind(this);
    this.onTradeShowSelect = this.onTradeShowSelect.bind(this);
    this.onTradeShowDelete = this.onTradeShowDelete.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.onFormChange = this.onFormChange.bind(this);
    this.onSuggestChange = this.onSuggestChange.bind(this);
    this.onLeadMemoReport = this.onLeadMemoReport.bind(this);
    this.bindMouseEvents();
    this.bindContact(context);

    this.handleDeleteFile = stopPropagation(confirm('Delete file?', this.handleDeleteFile.bind(this), context));

    this.onBuyerAdd = this.onBuyerAdd.bind(this);
    this.onBuyerClick = this.onBuyerClick.bind(this);
    this.onBuyerActivate = this.onBuyerActivate.bind(this);
    this.onBuyerDel = stopPropagation(
      deletionCheck(this.props.user, confirm('Delete this buyer?', this.onBuyerDel.bind(this), context), context),
    );
    this.onBuyerBlur = this.onBuyerBlur.bind(this);
    this.onBuyerChangeApprove = this.onBuyerChangeApprove.bind(this);

    this.onFiscalYearChange = this.onFiscalYearChange.bind(this);
    this.onFiscalBlur = this.onFiscalBlur.bind(this);
    this.onFiscalEdit = this.onFiscalEdit.bind(this);
    this.onFiscalAdd = this.onFiscalEdit.bind(this);
    this.onFiscalDel = stopPropagation(confirm('Delete this year?', this.onFiscalDel.bind(this), context));
    this.onFiscalErrorClose = this.onFiscalErrorClose.bind(this);

    this.addNewIndustry = this.addNewIndustry.bind(this);
    this.onDetailAdd = this.onDetailAdd.bind(this);
    this.onDetailDel = stopPropagation(confirm('Delete this?', this.onDetailDel.bind(this), context));
    this.onDetailChange = this.onDetailChange.bind(this);
    this.onDetailEdit = this.onDetailEdit.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);
    this.reorderContacts = this.reorderContacts.bind(this);
    this.reorderChannels = this.reorderChannels.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.saveContact = this.saveContact.bind(this);
    this.handleUpdateTags = this.handleUpdateTags.bind(this);
    this.handleUpdateHighStatus = this.handleUpdateHighStatus.bind(this);
    this.validate = this.validate.bind(this);
    this.updateDescription = this.updateDescription.bind(this);
    this.handleInstructionClick = this.handleInstructionClick.bind(this);
    this.handleDescriptionBlur = this.handleDescriptionBlur.bind(this);
    this.getCompanyErrors = this.getCompanyErrors.bind(this);
    this.handleOpenEmail = this.handleOpenEmail.bind(this);
    this.getEmailMergedFields = this.getEmailMergedFields.bind(this);
    this.handleAfterRequestSuccess = this.handleAfterRequestSuccess.bind(this);
    this.handleAfterRequestError = this.handleAfterRequestError.bind(this);

    // This flag will be set to true after component finish checking target role first time
    this.didCheckTargetRole = false;

    this.state = {
      isClosed: this.props.dealNotes.size === 0,
      industryCategory: '',
      businessModel: '',
      showInstruction: false,
      hasPermissions: true,
      roleIsResearcher: false,
      isBasicAccess: false,
      isUnapproved: false,
      isOwnerResearcher: false,
      isLoadingLeadMemoReport: false,
    };
  }

  addNewIndustry(text) {
    this.context.openPopup('NewIndustryPopup', {
      afterClose: value => {
        const index = this.props.company.getIn(['industries']).findIndex(industry => industry.get('isEditing'));

        this.onChange({
          name: `industries.${index}.industryLabel`,
          value: value.label,
        });
        this.onChange({
          name: `industries.${index}.id`,
          value: value.id,
        });
        this.onChange({
          name: `industries.${index}.pivot.industryId`,
          value: value.id,
        });
        this.onDetailSubmit(null, 'industries');
      },
      initialText: text,
    });
  }

  getChildContext() {
    return {
      onFiscalErrorClose: this.onFiscalErrorClose,
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
      addNewIndustry: this.addNewIndustry,
    };
  }

  bindContact(context) {
    this.addContact = this.addContact.bind(this);
    this.openContact = this.openContact.bind(this);
    this.delContact = stopPropagation(confirm('Delete contact?', this.delContact.bind(this), context));
    this.delContactChannel = stopPropagation(
      confirm('Delete this field from contact?', this.delContactChannel.bind(this), context),
    );
    this.editContact = this.editContact.bind(this);
    this.editContactChannel = this.editContactChannel.bind(this);
  }

  onErrorClose(event, field) {
    if (field === INDUSTRY_CATEGORIES) {
      this.setState({ industryCategory: '', businessModel: '' });
    }
    this.props.closeValidationError({ field });
  }

  componentWillUnmount() {
    this.context.deleteValidateCallbacks(this.validate);
    this.context.removeOnSaveCallback(this.onSubmit);
    allowDrop();
  }

  componentDidMount() {
    const {
      user,
      getFiles,
      getSubsidiaries,
      getBuyers,
      getTradeShows,
      fetchTargetStatuses,
      match: {
        params: { companyId },
      },
    } = this.props;
    const hasPermissions = checkAccess(user.getIn(['roles', 0]));
    const roleIsResearcher = isResearcher(user.getIn(['roles', 0, 'slug']));
    const isBasicAccess = basicAccess(user.getIn(['roles', 0, 'slug']));

    this.setState({ hasPermissions, roleIsResearcher, isBasicAccess });
    this.context.addValidateCallBacks(this.validate);
    this.context.addOnSaveCallback(this.onSubmit);
    getFiles({ companyId });
    getSubsidiaries({ companyId });
    getBuyers({ companyId });
    getTradeShows(companyId);
    fetchTargetStatuses();
    preventDrop();
  }

  componentDidUpdate() {
    const {
      user,
      buyerType,
      loading,
      company,
      dealNotes,
      companyInfo,
      match: {
        params: { companyId },
      },
    } = this.props;

    if (!this.didCheckTargetRole) {
      const isTarget = company.get('isTarget', false);

      if (loading === false) {
        if (buyerType === 'financial' || isTarget === false) {
          return this.props.push(`/company/${companyId}/buyer`);
        }
        this.didCheckTargetRole = true;
      }
    }

    // Check if there are deal notes, expand deal note section for first time loading
    if (!this.didCheckOpenState) {
      if (dealNotes.size > 0) {
        this.setState({
          isClosed: false,
        });
        this.didCheckOpenState = true;
      }
    }

    const isUnapproved = !companyInfo.get('isApproved');
    const researcherId = companyInfo.get('createdResearcherId');
    const isOwnerResearcher = researcherId === user.get('id');

    if (this.state.isUnapproved !== isUnapproved) this.setState({ isUnapproved });
    if (this.state.isOwnerResearcher !== isOwnerResearcher) this.setState({ isOwnerResearcher });
  }

  bindMouseEvents() {
    this.onDialNotesMouseEnter = this.onDialNotesMouseEnter.bind(this);
    this.onDialNotesMouseLeave = this.onDialNotesMouseLeave.bind(this);
    this.onEmailMouseEnter = this.onEmailMouseEnter.bind(this);
    this.onEmailMouseLeave = this.onEmailMouseLeave.bind(this);
  }

  uploadFile(event) {
    const { currentTarget } = event;
    const formData = new FormData(currentTarget);

    event.preventDefault();

    const { companyId } = this.props.match.params;
    const afterSuccess = () => currentTarget.reset();

    this.props.uploadFile({ companyId, formData, afterSuccess });
  }

  handleDeleteFile(event, file) {
    const {
      deleteFile,
      getFiles,
      match: {
        params: { companyId },
      },
    } = this.props;

    deleteFile(
      {
        id: file.get('id'),
        companyId: file.get('companyId'),
      },
      () => {
        this.context.closePopup();
        getFiles({ companyId });
      },
    );
  }

  /** Deal Notes methods. */
  onDialNotesMouseEnter(event, eventId) {
    this.props.enterCompanyDealNote({ eventId });
  }

  onDialNotesMouseLeave(event, eventId) {
    this.props.leaveCompanyDealNote({ eventId });
  }

  /** Emails methods. */
  onEmailMouseEnter(event, eventId) {
    this.props.enterCompanyEmail({ eventId });
  }

  onEmailMouseLeave(event, eventId) {
    this.props.leaveCompanyEmail({ eventId });
  }

  onLeadMemoReport(companyId) {
    this.setState({ isLoadingLeadMemoReport: true });
    this.props.leadMemoReportRequest({ companyId }, this.handleAfterRequestSuccess, this.handleAfterRequestError);
  }

  /**
   * After successful request handler.
   *
   * @param {object} payload Server payload data.
   * @param {object} payload.response Server success response.
   */
  handleAfterRequestSuccess({ response }) {
    const { fileName, fileUrl, errors } = response.data;
    const message = [
      {
        name: fileName,
        url: fileUrl,
        headers: {},
      },
    ];

    if (Array.isArray(errors)) {
      showError(this.context.openPopup, [errors[0]]);
    } else {
      showInformationCustomTitle(this.context.openPopup, 'The report has been generated successfully', message);
    }

    this.setState({ isLoadingLeadMemoReport: false });
  }

  /** After request error handler. */
  handleAfterRequestError({ resBody }) {
    const errorMessage = resBody.message;

    showError(this.context.openPopup, [errorMessage]);
    this.setState({ isLoadingLeadMemoReport: false });
  }

  openContact(event, index) {
    this.props.openContact({ index });
  }

  static copyContact(event, params) {
    event.stopPropagation();
    copyToClipboard(params.value);
  }

  delContact(event, contact) {
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const contactId = contact.id;
    const { entityContactsId } = contact;
    const { companyId } = this.props.match.params;

    this.props.delContact({ contactId, entityContactsId, companyId }, this.context.closePopup);
  }

  delContactChannel(event, channelType, channelIndex) {
    const {
      user,
      contacts,
      openedContact,
      delContactChannel,
      updateLastResearcher,
      match: {
        params: { companyId },
      },
    } = this.props;
    const { roleIsResearcher, isUnapproved, isBasicAccess } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const contactId = contacts.getIn([openedContact, 'id']);
    const channelId = contacts.getIn([openedContact, channelType, channelIndex, 'id']);

    delContactChannel({ companyId, contactId, type: channelType, channelId }, () => {
      this.context.closePopup();
      if (isBasicAccess) {
        updateLastResearcher(user.get('userName'));
      }
    });
  }

  editContact(event) {
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const { name, value } = event.target;
    const {
      openedContact,
      handleUpdateCompany,
      match: {
        params: { companyId },
      },
    } = this.props;

    if (name === 'notes') {
      handleUpdateCompany({
        name: `contacts.${openedContact}.pivot.notes`,
        value,
      });
    } else {
      this.context.openPopup('EditContactPopup', { openedContact, companyId });
    }
  }

  // Save contact notes on blur event.
  saveContact() {
    const {
      openedContact,
      contacts,
      saveCompanyContact,
      match: {
        params: { companyId },
      },
    } = this.props;
    const contact = contacts.get(openedContact);

    if (contact) {
      const contactId = contact.get('id');
      const saved = getChanged(contact);
      const { pivot = {} } = saved;

      saved.fullName = unwrap(contact.get('fullName'));
      saved.firstName = unwrap(contact.get('firstName'));
      saved.lastName = unwrap(contact.get('lastName'));
      saved.nick = unwrap(contact.get('nick'));
      saved.pronounce = unwrap(contact.get('pronounce'));

      const prefix = unwrap(contact.get('prefix')).get('value');
      const suffix = unwrap(contact.get('suffix')).get('value');
      const body = { ...pivot, ...saved, prefix, suffix };

      saveCompanyContact({ companyId, contactId, body, saved }, () => {});
    }
  }

  addContact() {
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;
    this.context.openPopup('AddCompanyContactPopup');
  }

  editContactChannel(channelType, channelIndex) {
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const {
      openedContact,
      contacts,
      match: { params },
      companyInfo,
    } = this.props;
    const contactId = contacts.getIn([openedContact, 'id']);
    const channelId = contacts.getIn([openedContact, channelType, channelIndex, 'id']);
    const props = {
      params,
      contactId,
      channelIndex,
      openedContact,
      channelId,
      companyInfo,
    };

    if (channelType === 'phones') {
      this.context.openPopup('EditPhoneContactChannelPopup', props);
    } else {
      this.context.openPopup('EditEmailContactChannelPopup', props);
    }
  }

  static getSuggestIdName(name) {
    switch (name) {
      case 'suggestDirector':
        return 'recordOwnerId';

      case 'suggestAnalyst':
        return 'recordSubOwnerId';

      case 'suggestDealMaker':
        return 'dealMakerId';

      case 'suggestResearcher':
        return 'lastResearcherId';

      case 'industryLabel':
        return 'pivot.industryId';

      case 'showName':
        return 'pivot.tradeshowId';

      default:
        return 'id';
    }
  }

  onSuggestChange({ name, value }) {
    this.onChange({ name, value });
  }

  onFormChange({ target: { name, value } }) {
    const {
      company,
      statuses,
      match: {
        params: { companyId },
      },
    } = this.props;
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const buyer = company.getIn(['buyers', 0]);
    const currStatus = buyer ? buyer.get('currentStatus') : undefined;
    const newValue = roleIsResearcher && name === 'lastResearchedDate' ? moment() : value;

    if (currStatus && name === 'buyers.0.currentStatus') {
      const curr = parseFloat(unwrap(currStatus));
      const newStatus = parseFloat(newValue);

      if (curr === 4 && newStatus >= 2 && newStatus <= 3.9) {
        this.context.openPopup('StatusCodePopup', {
          start: unwrap(currStatus),
          end: newValue,
          companyId,
          buyerId: buyer.get('id'),
        });
      }
    }

    this.onChange({
      name,
      value: newValue,
      summary: statuses.getIn([statuses.findKey(status => status.get('value') === newValue), 'name']),
    });
  }

  onFiscalYearChange(event) {
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;
    event.stopPropagation();

    const {
      target: { name, value },
    } = event;
    const id = parseInt(event.target.getAttribute('data-id'), 10);
    const index = this.props.company.get('targetFiscalYears').findIndex(target => target.get('id') === id);

    this.onChange({
      name: `targetFiscalYears.${index}.${name}`,
      value: value.replace(/^\$/, ''),
    });
  }

  onFiscalErrorClose(event, name, i) {
    this.props.closeFiscalError(name, i);
  }

  onFiscalBlur() {
    const {
      company,
      saveFiscalYear,
      match: {
        params: { companyId },
      },
    } = this.props;
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    company
      .get('targetFiscalYears')
      .filter(year => isDeepChanged(year))
      .forEach(year => {
        const body = getChanged(year);
        const yearId = year.get('id');

        body.fiscalYear = unwrap(year.get('fiscalYear'));

        return saveFiscalYear({ companyId, yearId, body });
      });
  }

  onFiscalDel(event, data) {
    const {
      delFiscalYear,
      match: {
        params: { companyId },
      },
    } = this.props;
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const yearId = (data && data.id) || 0;

    delFiscalYear(
      {
        yearId,
        companyId,
      },
      this.context.closePopup,
    );
  }

  onFiscalEdit(event, data) {
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const { companyId } = this.props.match.params;
    const yearId = (data && data.id) || 'new';

    this.context.openPopup('EditFiscalYearPopup', { companyId, yearId });
  }

  onChange({ name, value, summary }) {
    const { user, updateDealNoteWidth, handleUpdateCompany } = this.props;

    if (name === 'dealNotes') {
      updateDealNoteWidth({
        userId: user.get('id'),
        width: value.size.width,
      });
    }

    if (summary) {
      handleUpdateCompany({ name, value, summary });
    } else {
      handleUpdateCompany({ name, value });
    }
  }

  updateDescription(value) {
    let showInstruction = false;

    if (value) {
      const words = value.trim().split(' ');
      const first = words[0].toLowerCase();
      let last = words[words.length - 1];

      if (['a', 'an', 'the'].indexOf(first) > -1) {
        words[0] = first;
      } else {
        showInstruction = true;
      }

      if (words.length > 1) {
        words[words.length - 1] = last = endingRemoval(['.', '!', '?'], last);
      }

      if (
        [
          ',',
          ';',
          ':',
          '"',
          "'",
          '~',
          '`',
          '@',
          '#',
          '$',
          '%',
          '^',
          '&',
          '*',
          '(',
          ')',
          '-',
          '_',
          '+',
          '=',
          '|',
          '\\',
          '/',
          '<',
          '>',
          '{',
          '}',
          '[',
          ']',
        ].indexOf(last[last.length - 1]) > -1
      ) {
        showInstruction = true;
      }

      this.props.handleUpdateCompany({
        name: 'description',
        value: words.join(' '),
      });
      this.setState({ showInstruction });
    }
  }

  validate() {
    let isValid = true;
    const { company } = this.props;
    const industryCategories = unwrap(company.get(INDUSTRY_CATEGORIES, emptyList));
    const businessModels = unwrap(company.get(BUSINESS_MODELS, emptyList));

    if (!businessModels.size) {
      this.setState({ businessModel: 'Business model is required' });
      isValid = false;
    }

    if (!industryCategories.size) {
      this.setState({
        industryCategory: INDUSTRY_CATEGORY_ERROR,
      });
      isValid = false;
    }

    return isValid;
  }

  /**
   * Get array with field errors.
   */
  getCompanyErrors() {
    const { company } = this.props;
    const errors = company.get('errors').toJS();

    return Object.keys(errors).reduce((acc, key) => {
      acc.push(company.getIn(['errors', key]).trim());

      return acc;
    }, []);
  }

  onSubmit(event) {
    event.preventDefault();

    const { roleIsResearcher, isUnapproved, isBasicAccess } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const {
      user,
      company,
      match: { params },
      reloadMainInfo,
      saveInfo,
      updateLastResearcher,
      contacts,
    } = this.props;

    if (!company.get('isValid')) {
      const errorMessage = this.getCompanyErrors();

      showError(this.context.openPopup, errorMessage);

      return;
    }

    const body = getChanged(company);
    const { companyId } = params;
    let afterSuccess = null;

    delete body.targetFiscalYears;
    delete body.businessModels;
    delete body.industryCategories;

    if ('lastResearchedDate' in body) body.lastResearchedDate = normalizeDate(body.lastResearchedDate);
    if ('balanceSheetDate' in body) body.balanceSheetDate = normalizeDate(body.balanceSheetDate);

    if ('description' in body || 'researchNotes' in body) {
      afterSuccess = () => {
        reloadMainInfo(companyId);
      };
    }

    const currentStatus = Number(company.getIn(['buyers', 0, 'currentStatus', 'value']));
    const isBasicAccessL1 = user.get('roles').some(role => role.get('name') === 'BasicAccessL1');

    this.onDetailSubmit();
    this.onBoardIndustrySubmit();

    if (isBasicAccessL1 && currentStatus >= 2) {
      updateLastResearcher(user.get('userName'));
      this.setState({ industryCategory: '', businessModel: '' });

      return;
    }

    if (Object.keys(body).length) {
      if ('tradeShowTargetsSpyList' in body) {
        this.props.saveTradeShows(toJS(company.get('tradeShowTargetsSpyList')), companyId);
      }
      saveInfo({
        companyId,
        body: normalizeField(body),
        afterSuccess,
      });
    }

    this.onBuyerBlur();
    this.onFiscalBlur();

    if (isDeepChanged(contacts)) {
      this.saveContact();
    }

    if (isBasicAccess) {
      updateLastResearcher(user.get('userName'));
    }
    this.setState({ industryCategory: '', businessModel: '' });
  }

  getSuggest(name) {
    const { company, findDirectors, findAnalysts, findUsers } = this.props;
    const find = name === 'suggestDirector' ? findDirectors : name === 'suggestAnalyst' ? findAnalysts : findUsers;

    return (
      <AutoCompleteContainer
        change={this.onSuggestChange}
        find={find}
        idName={CompanyTargetContainer.getSuggestIdName(name)}
        idValue={company.get(CompanyTargetContainer.getSuggestIdName(name))}
        suggestions={company.get('suggests')}
        suggestsName="suggests"
        value={company.get(name)}
        valueName={name}
      />
    );
  }

  onTradeShowDelete(event, detailName, data, menu, name, index) {
    this.props.removeTradeShow(index);
  }

  onDetailAdd(event, detailName) {
    this.props.addDetailEntity({ name: detailName });
  }

  onDetailDel(event, detailName, data, menu, name) {
    const id = unwrap(data.id);
    const {
      user,
      company,
      delDetailEntity,
      match: {
        params: { companyId },
      },
    } = this.props;
    const entities = company.get(detailName);
    const body = toJS(entities)
      .filter(entity => entity.id !== id)
      .map(entity => {
        Object.keys(entity).forEach(key => {
          if (entity[key] instanceof moment) {
            try {
              entity[key] = entity[key].format('YYYY-MM-DD');
            } catch (err) {
              console.error("Can't transform ", key, entity[key], err);
            }
          }
        });

        return entity;
      });

    delDetailEntity(
      {
        name: detailName,
        errorKey: name,
        id,
        companyId,
        body,
        realBody: isNew(id) ? null : prepareDetail(detailName, body, user.get('id')),
      },
      () => {
        setTimeout(() => {
          $(`#${name.replace(/\./gi, '_')}`).remove();
          this.context.closePopup();
        }, 1);
      },
    );
  }

  onDetailEdit(field, detailName, detailIndex) {
    this.props.startEditDetailEntity({
      field,
      name: detailName,
      index: detailIndex,
    });
  }

  onDetailChange(event) {
    const {
      target: { name, value },
    } = event;

    this.onChange({ name, value });
  }

  onDetailSubmit() {
    [
      'customFields',
      'harvcoTags',
      'industries',
      'targetCompetitors',
      'targetCustomers',
      'targetFacilities',
      'targetMarkets',
      'targetProducts',
      'tradeshowBuyers',
      'tradeShowTargets',
    ].forEach(f => {
      this.onDetailSubmitItem(f);
    });
  }

  onBoardIndustrySubmit() {
    const {
      company,
      saveIndustryBoard,
      handleUpdateCompany,
      match: {
        params: { companyId },
      },
    } = this.props;
    const industryCategoryIds = unwrap(company.get(INDUSTRY_CATEGORIES))
      .map(t => t.get('id'))
      .toJS();

    const businessModelIds = unwrap(company.get(BUSINESS_MODELS))
      .map(t => t.get('id'))
      .toJS();

    saveIndustryBoard({
      industryCategoryIds: industryCategoryIds.concat(businessModelIds),
      companyId,
      afterSuccess: data => {
        handleUpdateCompany({
          name: 'suggestResearcher',
          value: data.response.data.researchUser.userName,
        });
      },
    });
  }

  onDetailSubmitItem(detailName) {
    const {
      user,
      company,
      inputErrors,
      checkValidity,
      saveCompanyDetailEntity,
      match: {
        params: { companyId },
      },
    } = this.props;
    const base = company.get(detailName);
    const precheck = precheckDetail(detailName);

    if (!isDeepChanged(base)) return true;
    if (!company.get(`${detailName}IsValid`, true)) {
      window.__emitter__.emit('invokeErrorTooltip', inputErrors.toJS());

      return true;
    }

    const body = toJS(base);

    if (!precheck(body)) {
      checkValidity();

      return true;
    }
    body.forEach(entity => {
      Object.keys(entity).forEach(key => {
        if (entity[key] instanceof moment) {
          try {
            entity[key] = entity[key].format('YYYY-MM-DD');
          } catch (err) {
            console.error("Can't transform ", key, entity[key], err);
          }
        }
      });
    });

    let realBody = null;

    try {
      realBody = prepareDetail(detailName, body, user.get('id'));
    } catch (err) {
      return true;
    }

    saveCompanyDetailEntity({
      companyId,
      name: detailName,
      body,
      realBody,
    });
  }

  onTradeShowSelect(suggestion) {
    this.props.loadSelectedTradeShow(suggestion.id);
  }

  getTradeShowsSuggest(detailField, index, name, columnIndex, addNew, value) {
    const {
      company,
      findTradeShows,
      match: {
        params: { companyId },
      },
    } = this.props;

    const prefix = `${detailField}.${index}.`;
    const suggestsName = 'tradeShowsSuggests';
    const currentRowData = toJS(this.props.company.get('tradeShowTargets'))[index];

    if ('event' in currentRowData) {
      return <span className="min-sizes">{value}</span>;
    }

    return (
      <AutoCompleteContainer
        addNew={addNew}
        change={this.onSuggestChange}
        find={params => {
          findTradeShows({
            ...params,
            companyId,
          });
        }}
        idName={prefix + CompanyTargetContainer.getSuggestIdName(name)}
        idValue={company.getIn([detailField, index, CompanyTargetContainer.getSuggestIdName(name)])}
        inputProps={{ autoFocus: columnIndex === 0, name: prefix + name }}
        onSelectCallback={this.onTradeShowSelect}
        rootPath={['targetCompany', 'target']}
        suggestions={company.get(suggestsName)}
        suggestsName={suggestsName}
        suggestsPath={prefix + suggestsName}
        valueName={prefix + name}
      />
    );
  }

  UNSAFE_componentWillReceiveProps(oldProps) {
    [
      'customFields',
      'harvcoTags',
      'industries',
      'targetCompetitors',
      'targetCustomers',
      'targetFiscalYears',
      'targetFacilities',
      'targetMarkets',
      'targetProducts',
      'tradeshowBuyers',
      'tradeShowTargets',
    ].some(key => {
      if (oldProps.company.get(key) !== this.props.company.get(key)) {
        this.__details = null;

        return true;
      }

      return false;
    });
  }

  getDetails() {
    this.__details =
      this.__details ||
      this.props.company.filter(
        (v, key) =>
          [
            'customFields',
            'harvcoTags',
            'industries',
            'targetCompetitors',
            'targetCustomers',
            'targetFacilities',
            'targetMarkets',
            'targetProducts',
            'tradeshowBuyers',
            'tradeShowTargets',
          ].indexOf(key) !== -1,
      );

    return this.__details;
  }

  getDetailsCallback() {
    this.__detailsCallback = this.__detailsCallback || {
      onAdd: this.onDetailAdd,
      onDelete: this.onDetailDel,
      onChange: this.onDetailChange,
      onClick: this.onDetailEdit,
      onTradeShowDelete: this.onTradeShowDelete,
    };

    return this.__detailsCallback;
  }

  onBuyerChangeApprove(event, { target }, index) {
    if (this.props.isDuplicateCompany) return;

    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;
    this.props.changeBuyerApprove({
      target,
      index,
    });
  }

  onBuyerClick(event, index) {
    this.props.openBuyer({
      index,
    });
  }

  onBuyerActivate(event, index) {
    if (this.props.isDuplicateCompany) return;

    this.props.activeBuyer({
      index,
    });
  }

  onBuyerDel(event, data) {
    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;
    this.onBuyerBlur();
    setTimeout(() => {
      const { companyId } = this.props.match.params;
      const id = (data && data.id) || 0;

      this.props.delBuyer(
        {
          id,
          companyId,
        },
        this.context.closePopup,
      );
    }, 50);
  }

  onBuyerBlur() {
    const {
      user,
      company,
      saveBuyers,
      match: {
        params: { companyId },
      },
    } = this.props;
    const { roleIsResearcher, isUnapproved, isBasicAccess } = this.state;

    if (!roleIsResearcher && isUnapproved) return;
    if (company.get('isValid')) {
      const buyersToSave = company
        .get('buyers')
        .map((buyer, i) => buyer.set('buyerIndex', i))
        .filter(buyer => isDeepChanged(buyer));

      buyersToSave.map(buyer => {
        const body = getChanged(buyer);
        const buyerId = buyer.get('buyerId');
        const id = buyer.get('id');
        const buyerIndex = buyer.get('buyerIndex');

        saveBuyers({ companyId, buyerIndex, buyerId, id, body });

        if (isBasicAccess) {
          updateLastResearcher(user.get('userName'));
        }
      });
    }
  }

  getBuyersSuggests() {
    const { company, findApprovalLists, findProjects } = this.props;

    if (company.get('buyers') !== this.__savedBuyers) {
      this.__savedBuyers = company.get('buyers');
      this.__savedBuyersSuggests = null;
    }

    if (!this.__savedBuyersSuggests) {
      let activeBuyer = 0;

      this.__savedBuyers.some((buyer, i) => {
        if (buyer.get('isOpened')) {
          activeBuyer = i;

          return true;
        }

        return false;
      });

      const prefix = `buyers.${activeBuyer}.`;
      const buyerId = unwrap(company.getIn(`${prefix}buyerId`.split('.')));
      const projectId = unwrap(company.getIn(`${prefix}projectId`.split('.')));

      this.__savedBuyersSuggests = {
        suggestApprovalList: (
          <AutoCompleteContainer
            change={this.onSuggestChange}
            find={opts => {
              findApprovalLists({
                ...opts,
                field: `${prefix}dsplApplistLabel`,
                buyerIndex: activeBuyer,
                buyerId,
                projectId,
              });
            }}
            idName={`${prefix}approvalListId`}
            inputProps={{
              name: `${prefix}dsplApplistLabel`,
              disabled: !projectId,
            }}
            rootPath={['targetCompany', 'target']}
            suggestsName={`${prefix}approveSuggests`}
            valueName={`${prefix}dsplApplistLabel`}
          />
        ),
        suggestProject: (
          <AutoCompleteContainer
            change={this.onSuggestChange}
            find={opts => {
              findProjects({
                ...opts,
                field: `${prefix}dsplProjectCategory`,
                buyerIndex: activeBuyer,
                buyerId,
              });
            }}
            idName={`${prefix}projectId`}
            inputProps={{
              name: `${prefix}dsplProjectCategory`,
            }}
            render={suggestion => {
              const className = classNames({
                'text-muted': !suggestion.isActive,
              });

              return <span className={className}>{suggestion.text}</span>;
            }}
            rootPath={['targetCompany', 'target']}
            suggestsName={`${prefix}projectSuggests`}
            valueName={`${prefix}dsplProjectCategory`}
          />
        ),
      };
    }

    return this.__savedBuyersSuggests;
  }

  onBuyerAdd() {
    if (this.props.isDuplicateCompany) return;

    const { roleIsResearcher, isUnapproved } = this.state;

    if (!roleIsResearcher && isUnapproved) return;

    const { companyId } = this.props.match.params;

    this.context.openPopup('AddCompanyBuyerPopup', { companyId });
  }

  onToggle() {
    this.didCheckOpenState = true;
    this.setState(prevState => ({
      isClosed: !prevState.isClosed,
    }));
  }

  static getBuyerName(buyer) {
    if (!buyer) return null;

    return unwrap(buyer.get('dsplBuyerAbbrName')) ? buyer.get('dsplBuyerAbbrName') : buyer.get('dsplBuyerLegalName');
  }

  static getCompanyName(company) {
    return unwrap(company.get('abbrName')) ? company.get('abbrName') : company.get('legalName');
  }

  reorderContacts(contacts) {
    const { companyId } = this.props.match.params;
    const sendToApi = contacts
      .map(contact => ({
        id: contact.get('id'),
        entityContactsId: contact.get('entityContactsId'),
      }))
      .toJS();

    this.props.reorderContacts(contacts, companyId, sendToApi);
  }

  reorderChannels(contacts) {
    const { openedContact, reorderChannels } = this.props;

    reorderChannels(contacts, openedContact);
  }

  handleUpdateTags() {
    const { company, updateIndustryCategory, selectBusinessModels } = this.props;
    const tags = unwrap(company.get(INDUSTRY_CATEGORIES));
    const models = unwrap(company.get(BUSINESS_MODELS));

    this.context.openPopup('TagsManagementPopup', {
      addNew: false,
      clientView: true,
      selectedTags: tags.reduce((acc, t) => {
        acc[t.get('id')] = true;

        return acc;
      }, {}),
      selectedModels: models.reduce((acc, m) => {
        acc[m.get('id')] = true;

        return acc;
      }, {}),
      callback: (tagSelecteds, modelSelecteds) => {
        updateIndustryCategory(tagSelecteds);
        selectBusinessModels(modelSelecteds);
      },
    });
  }

  handleUpdateHighStatus(buyer) {
    const {
      company,
      statuses,
      match: {
        params: { companyId },
      },
    } = this.props;
    const updateAt = buyer.getIn(['highStatusUpdated']);

    if (updateAt) {
      const statusUpdatedHoursPassed = moment().diff(updateAt, 'hours');

      this.context.openPopup('UpdateHighStatusPopup', {
        statuses,
        highStatus: unwrap(buyer.getIn(['highStatus'])),
        currentStatus: unwrap(buyer.getIn(['currentStatus'])),
        highStatusUpdateAt: moment(updateAt),
        companyId,
        buyerId: buyer.get('id'),
        directorEmail: company.get('recordOwnerEmail'),
        isStatusExpired: statusUpdatedHoursPassed > HOURS_LIMIT,
      });
    }
  }

  handleInstructionClick() {
    this.context.openPopup('DescriptionChangePopup');
  }

  handleDescriptionBlur(event) {
    this.updateDescription(event.target.value);
  }

  /**
   * Get object with email's merged fields.
   *
   * @return {object} Merged fields.
   */
  getEmailMergedFields() {
    const { companyInfo, company, contacts } = this.props;
    const contact = companyInfo.getIn(['entityContacts', 0]);
    const products = this.__details.get('targetProducts');
    const markets = this.__details.get('targetMarkets');
    const activeBuyer = company.get('buyers').find(buyer => unwrap(buyer.get('activeBuyer')));

    return configureEmailMergedFields(contact, contacts, activeBuyer, companyInfo, markets, products, company);
  }

  /**
   * Parse email fields and open mailto link.
   *
   * @param {string} email Recipient email.
   * @param {Map} template Email data.
   */
  handleOpenEmail(email, template) {
    const { user, contacts, openedContact } = this.props;
    const mergedFields = this.getEmailMergedFields();
    const contact = contacts.get(openedContact);
    const mailLink = getContactMailLink(email, template, mergedFields, user, contact);

    window.open(mailLink, '_blank');
  }

  render() {
    const {
      user,
      company,
      statuses,
      companyName,
      emailTemplates,
      openedContact,
      contacts,
      dealNotes,
      dealNoteSize,
      infoSectionHeight,
      emails,
      files,
      findDirectors,
      fetchEmailTemplates,
      lastEventBuyerText,
      match,
      isDuplicateCompany,
    } = this.props;

    if (!company.get('loaded')) return null;

    const { hasPermissions, roleIsResearcher, isUnapproved, isOwnerResearcher } = this.state;
    const { companyId } = match.params;
    const activeBuyer = company.getIn(['buyers', 0]);

    const contactsProps = {
      reorderContacts: this.reorderContacts,
      reorderChannels: this.reorderChannels,
      companyName,
      buyerName: CompanyTargetContainer.getBuyerName(activeBuyer),
      addContact: this.addContact,
      delContact: this.delContact,
      opened: openedContact,
      openContact: this.openContact,
      contacts,
      onCopy: CompanyTargetContainer.copyContact,
      onDel: this.delContactChannel,
      onEdit: this.editContact,
      onOpenEmail: this.handleOpenEmail,
      onEditChannel: this.editContactChannel,
    };

    const buyersProps = {
      ...this.getBuyersSuggests(),
      onClick: this.onBuyerClick,
      onActivate: this.onBuyerActivate,
      onDel: this.onBuyerDel,
      onSubmit: this.onBuyerBlur,
      onBlur: this.onBuyerBlur,
      buyers: company.get('buyers'),
      onChangeApprove: this.onBuyerChangeApprove,
      addBuyer: this.onBuyerAdd,
    };

    const financialsProps = {
      company,
      onChange: this.onFormChange,
      onYearChange: this.onFiscalYearChange,
      onTableSubmit: this.onFiscalBlur,
      onSubmit: this.onSubmit,
      onAdd: this.onFiscalAdd,
      onDel: this.onFiscalDel,
      onEdit: this.onFiscalEdit,
    };

    const subsidiariesProps = {
      subsidiaries: company.get('subsidiaries'),
    };

    const suggestDirector = this.getSuggest('suggestDirector');
    const suggestAnalyst = this.getSuggest('suggestAnalyst');
    const suggestDealMaker = this.getSuggest('suggestDealMaker');
    const suggestResearcher = this.getSuggest('suggestResearcher');
    const detailsCallbacks = this.getDetailsCallback();
    const detailsSuggests = company.get('suggests');
    const industryCategories = company.get(INDUSTRY_CATEGORIES);
    const businessModels = company.get(BUSINESS_MODELS);

    return (
      <CompanyTarget
        businessModels={businessModels}
        buyersProps={buyersProps}
        companyId={companyId}
        companyInfo={company}
        contactsProps={contactsProps}
        currentUser={user}
        dealNotes={dealNotes}
        dealNoteSize={dealNoteSize}
        details={this.getDetails()}
        detailsCallbacks={detailsCallbacks}
        detailsSuggests={detailsSuggests}
        emails={emails}
        emailTemplates={emailTemplates}
        error={{
          industryCategory: this.state.industryCategory,
          businessModel: this.state.businessModel,
        }}
        fetchEmailTemplates={fetchEmailTemplates}
        files={files}
        financialsProps={financialsProps}
        findDirectors={findDirectors}
        hasPermissions={hasPermissions}
        industryCategories={industryCategories}
        infoSectionHeight={infoSectionHeight}
        isClosed={this.state.isClosed}
        isDuplicateCompany={isDuplicateCompany}
        isLoadingLeadMemoReport={this.state.isLoadingLeadMemoReport}
        isOwnerResearcher={isOwnerResearcher}
        isUnapproved={isUnapproved}
        lastEventBuyerText={lastEventBuyerText}
        onChange={this.onFormChange}
        onDeleteFile={this.handleDeleteFile}
        onDescriptionBlur={this.handleDescriptionBlur}
        onEnterDealNotes={this.onDialNotesMouseEnter}
        onEnterEmail={this.onEmailMouseEnter}
        onErrorClose={this.onErrorClose}
        onInstructionClick={this.handleInstructionClick}
        onLeadMemoReport={this.onLeadMemoReport}
        onLeaveDealNotes={this.onDialNotesMouseLeave}
        onLeaveEmail={this.onEmailMouseLeave}
        onSubmit={this.onSubmit}
        onToggle={this.onToggle}
        onUpdateHighStatus={this.handleUpdateHighStatus}
        onUpdateTags={this.handleUpdateTags}
        roleIsResearcher={roleIsResearcher}
        showInstruction={this.state.showInstruction}
        statuses={statuses}
        subsidiariesProps={subsidiariesProps}
        suggestAnalyst={suggestAnalyst}
        suggestDealMaker={suggestDealMaker}
        suggestDirector={suggestDirector}
        suggestResearcher={suggestResearcher}
        tradeShowSuggestCreate={this.getTradeShowsSuggest}
        uploadFile={this.uploadFile}
      />
    );
  }
}

const companyEventsSelector = state => state.targetCompany.events.get('all');
const lastEventBuyerTextSelector = createSelector(
  companyEventsSelector,
  events => events && fromJS(getLastEventBuyerText({ data: { events: events.toJS() } })),
);

function mapStateToProps(state) {
  const company = state.targetCompany.target;
  const companyInfo = state.targetCompany.info.get('info');
  const buyerInfo = state.targetCompany.buyer.info;
  const dealNoteSize = {
    minConstraints: [config.noteBoxSize.get('min'), null],
    width: state.auth.getIn(['user', 'tdnwidth']) || config.noteBoxSize.get('default'),
  };

  return {
    lastEventBuyerText: lastEventBuyerTextSelector(state),
    companyName: CompanyTargetContainer.getCompanyName(companyInfo),
    infoSectionHeight: companyInfo.get('height'),
    isDuplicateCompany: state.targetCompany.mergeInfo.get('isDuplicateCompany'),
    companyInfo,
    company,
    inputErrors: company.get('inputErrors'),
    files: company.get('files', emptyList),
    dealNotes: state.targetCompany.events.get('dealNotes', emptyList),
    dealNoteSize,
    user: state.auth.get('user'),
    emails: state.targetCompany.events.get('emails', emptyList),
    openedContact: company.get('openedContact', 0),
    contacts: company.get('contacts', emptyList),
    buyerType: buyerInfo.get('buyerType', '').toLowerCase(),
    loading: state.targetCompany.info.get('isFetching') || !buyerInfo.get('loaded'),
    statuses: fromJS(state.statuses.get('targetStatuses')),
    emailTemplates: state.emailTemplates.get('data', emptyList),
  };
}

CompanyTargetContainer.propTypes = {
  isDuplicateCompany: PropTypes.bool.isRequired,
};

CompanyTargetContainer.contextTypes = {
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
  openPopup: PropTypes.func,
  closePopup: PropTypes.func,
  onClosePopup: PropTypes.func,
  hasPopup: PropTypes.func,
  addValidateCallBacks: PropTypes.func,
  deleteValidateCallbacks: PropTypes.func,
};

CompanyTargetContainer.childContextTypes = {
  onFiscalErrorClose: PropTypes.func.isRequired,
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
  addNewIndustry: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(companyTargetActions, dispatch),
    ...bindActionCreators(
      {
        leadMemoReportRequest,
        enterCompanyDealNote,
        enterCompanyEmail,
        leaveCompanyDealNote,
        leaveCompanyEmail,
        saveCompanyContact,
        updateDealNoteWidth,
        reloadMainInfo,
        fetchTargetStatuses,
        fetchEmailTemplates,
        updateLastResearcher,
        getTradeShows,
        loadSelectedTradeShow,
        removeTradeShow,
        saveTradeShows,
      },
      dispatch,
    ),
    reorderChannels: orderContactService(dispatch, companyTargetActions.reorderChannels),
    push,
  };
}

export default connect(mapStateToProps, mapDispatchToProps, mergeProps, connectOptions)(CompanyTargetContainer);
