import PropTypes from 'prop-types';

import React, { Component } from 'react';
import { Map, List } from 'immutable';
import outsideClick from 'react-click-outside';
import AgGridTable from '../helpers/AgGridTable';
import getCellHeight from '../../utils/getCellHeight';
import TextAreaEditor from './TextAreaEditor';
import { handleSortModelChanged } from '../../utils/sorting';

class MailingInfoTable extends Component {
  constructor(props) {
    super(props);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.onGridReady = this.onGridReady.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleCellClicked = this.handleCellClicked.bind(this);
    this.handleCellValueChanged = this.handleCellValueChanged.bind(this);
    this.getRowHeight = this.getRowHeight.bind(this);
    this.getColumnDefs = this.getColumnDefs.bind(this);
    this.colWidthRef = {}; // Quick reference column width
  }

  getColumnDefs() {
    if (this.props.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.props.columnDefs;
      this.columnDefs = this.props.columnDefs.map(columnDef => {
        this.colWidthRef[columnDef.get('field')] = columnDef.get('width');

        return columnDef.merge({
          cellEditorFramework: TextAreaEditor,
        });
      });
    }

    return this.columnDefs;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  }

  handleCellClicked(data) {
    this.gridApi.startEditingCell({
      rowIndex: data.node.rowIndex,
      colKey: data.colDef.field,
    });
  }

  handleCellValueChanged(event) {
    if (event.newValue !== event.oldValue) {
      const { updateType, updateField } = event.colDef;

      this.props.onUpdateCell({
        updateType,
        updateField,
        newValue: event.newValue,
        data: event.data,
      });
    }
  }

  onSort(sortModel) {
    handleSortModelChanged(sortModel, this.props.data, this.props.onChange);
  }

  handleClickOutside() {
    this.gridApi.stopEditing();
  }

  componentDidUpdate(oldProps) {
    if (oldProps.data.getIn(['sortBy', 'sortModel']) !== this.props.data.getIn(['sortBy', 'sortModel'])) {
      // this.gridApi.setSortModel(this.props.data.getIn(['sortBy', 'sortModel']).toJS());
    }

    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  getRowHeight(params) {
    const desheight = getCellHeight(params.data.targetDescription, this.colWidthRef.targetDescription);
    const addHeight = getCellHeight(params.data.contactAddress, this.colWidthRef.contactAddress);
    const abbrHeight = getCellHeight(params.data.targetAbbrName, this.colWidthRef.targetAbbrName);
    const legalHeight = getCellHeight(params.data.targetLegalName, this.colWidthRef.targetLegalName);

    return Math.max(desheight, addHeight, abbrHeight, legalHeight, 20);
  }

  getRowNodeId(data) {
    return data.index;
  }

  render() {
    const { data, onGetNextPageData, onDoubleClickRow } = this.props;

    return (
      <div className="MailingCreateTable">
        <AgGridTable
          columnDefs={this.getColumnDefs()}
          getRowHeight={this.getRowHeight}
          getRowNodeId={this.getRowNodeId}
          isFetching={data.get('isFetching')}
          onCellClicked={this.handleCellClicked}
          onCellValueChanged={this.handleCellValueChanged}
          onGetNextPageData={onGetNextPageData}
          onGridReady={this.onGridReady}
          onRowDoubleClicked={onDoubleClickRow}
          onSortModelChanged={this.onSort}
          page={data.get('page')}
          rowData={data.get('queryResults')}
          totalPages={data.get('totalPages')}
          sortable
        />
      </div>
    );
  }
}

MailingInfoTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
  onUpdateCell: PropTypes.func.isRequired,
};

export default outsideClick(MailingInfoTable);
