import { createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react18-router';
import thunkMiddleware from 'redux-thunk';
import createLogger from 'redux-logger';
import apiMiddleware from '../middleware/api';
import createRootReducer from '../reducers';

export const history = createBrowserHistory();

// eslint-disable-next-line no-extend-native
Object.defineProperty(Symbol.prototype, 'indexOf', {
  value: () => 0,
});

const composeEnhancers = (function() {
  if (process.env.NODE_ENV !== 'production') {
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      return window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        serialize: true,
        actionSanitizer(action) {
          const type = String(action.type);

          return { ...action, type };
        },
      });
    }
  }

  return compose;
})();

const devTools = (function() {
  if (process.env.NODE_ENV !== 'production') {
    if (!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
      if (window.devToolsExtension) {
        return window.devToolsExtension({
          actionSanitizer(action) {
            const type = String(action.type);

            return { ...action, type };
          },
        });
      }
    }
  }

  return arg => arg;
})();

const logger = [];

if (process.env.NODE_ENV === 'production') {
  logger.push(
    createLogger({
      level: 'info',
      collapsed: false,
      duration: true,
      logger: console,
      logErrors: process.env.NODE_ENV !== 'develop',
      predicate: () => process.env.NODE_ENV === 'develop',
      actionTransformer: action =>
        Object.assign({}, action, {
          type: String(action.type),
        }),
    }),
  );
}

const createStoreWithMiddleware = function(rootReducer, initialState, history) {
  return createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(thunkMiddleware, apiMiddleware, routerMiddleware(history), ...logger), devTools),
  );
};

export default function configureStore(initialState) {
  const store = createStoreWithMiddleware(createRootReducer(history), initialState, history);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers'); // eslint-disable-line global-require

      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}
