import React from 'react';

import ExtensionFormat from './ExtensionFormat';
import InternationalFormat from './InternationalFormat';
import { CustomFormat } from './CustomFormat';
import OtherFormat from './OtherFormat';

const EditPhoneContactChannel = ({ channel, ...rest }) => {
  let Format = null;

  switch (channel.getIn(['type', 'value'])) {
    case 'Extension':
      Format = ExtensionFormat;
      break;

    case 'International':
      Format = InternationalFormat;
      break;

    case 'Mobile':

    // fallthrough
    case 'Direct':

    // fallthrough
    case 'Home':

    // fallthrough
    case 'Fax':
      Format = OtherFormat;
      break;

    default:
      Format = CustomFormat;
      break;
  }

  return <Format {...rest} channel={channel} />;
};

export default EditPhoneContactChannel;
