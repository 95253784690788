import React, { memo } from 'react';

import Popup from '../../helpers/Popup';
import FormGroup from '../../helpers/FormGroup';
import Button from '../../helpers/Button';
import _Input from '../../helpers/Input';
import ErrorDisplay from '../../decorators/DisplayError';
import PrettyError from '../../decorators/PrettyError';

const Input = PrettyError(ErrorDisplay(_Input));

const CustomFormatComponent = ({ isNew, onChange, channel, onSave, onUndo }) => {
  const header = (
    <div>
      {`${isNew ? 'Create' : 'Change'} contact profile info`} - Custom
      <a href="#" onClick={onUndo} title="undo">
        undo
      </a>
    </div>
  );

  const footer = (
    <Button className="btn-primary btn-xs" onClick={onSave}>
      {isNew ? 'Create' : 'Save'}
    </Button>
  );

  return (
    <Popup footer={footer} header={header} id="extFormatContact">
      <div className="row">
        <FormGroup className="col-sm-5">
          <Input
            className="input-md"
            label="Phone"
            maxLength="50"
            name="unformattedPhone"
            onChange={onChange}
            placeholder="Phone"
            root={channel}
            autoFocus
            deep
            showLabel
          />
        </FormGroup>
        <div className="col-sm-2 text-right">
          <br />
          Custom -
        </div>
        <FormGroup className="col-sm-5">
          <Input
            className="input-md"
            label="Type"
            maxLength="30"
            name="type"
            onChange={onChange}
            placeholder="Type"
            root={channel}
            deep
            showLabel
          />
        </FormGroup>
      </div>
    </Popup>
  );
};

export const CustomFormat = memo(CustomFormatComponent);
