import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import config from '../../../config';
import getPercent from '../../../utils/getPercent';
import { Edit } from './Edit';
import { View } from './View';

const APPROVALS_STATS_SUM_COLUMNS = [
  'numberTargets',
  'numberOfContacted',
  'numberOfLeads',
  'numberOfCc',
  'numberOfVisit',
  'numberOfNextActions',
  'numberOfPriorityA',
  'numberOfPriorityB',
  'numberOfPriorityC',
  'numberOfApprovedX',
];
const TOTAL_APPROVALS_RETURNED = [
  'numberOfContacted',
  'numberOfLeads',
  'numberOfCc',
  'numberOfVisit',
  'numberOfNextActions',
  'numberOfPriorityA',
  'numberOfPriorityB',
  'numberOfPriorityC',
  'numberOfApprovedX',
];

/**
 * Return sum by field.
 *
 * @param {Immutable.List} approvals Immutable List of approvals.
 * @param {string} field Field name.
 */
const getSum = (approvals, field) =>
  approvals.reduce((sum, approval) => {
    const approvalField = approval.get(field) || 0;

    return (sum += approvalField);
  }, 0);

/**
 * Table rows component.
 *
 * @param props {Object}.
 * @param props.approvals {Immutable.Map} Approval list information.
 * @param props.onChange {Function} Function to handle changing row information.
 * @param props.onClick {Function} Function to handle event of clicking on row.
 * @param props.onDoubleClick {Function} Function to handle event of double clicking on row.
 * @returns {React.Component}
 */
const RowsComponent = ({
  approvals,
  onChange,
  onClick,
  onDoubleClick,
  contextId,
  noApprovals,
  columns,
  onNoApprovalDoubleClick,
}) => {
  const type = config.values.getIn(['project', 'types', 'APPROVALS']);

  const sumNoApprovalReturnedTargets = getSum(noApprovals, 'numberTargets');
  const sumNoApproved = getSum(noApprovals, 'numberApproved');

  const cellsNoApproval = columns.map((col, i) => {
    // it needs to prevent render unnecessary td
    if (i === 2) return null;

    if (col.get('field') === 'applistLabel') {
      return (
        <td key={col.get('field')} colSpan="2">
          (No Approval List)
        </td>
      );
    }

    if (col.get('field') === 'numberTargets') {
      return (
        <td key={col.get('field')} className="text-right">
          {sumNoApprovalReturnedTargets}
        </td>
      );
    }

    if (col.get('field') === 'numberApproved') {
      const sum = getSum(noApprovals, col.get('field'));

      return (
        <td key={col.get('field')} className="text-right">
          {sum} ({getPercent(sum, sumNoApprovalReturnedTargets)}%)
        </td>
      );
    }

    if (TOTAL_APPROVALS_RETURNED.includes(col.get('field'))) {
      const sum = getSum(noApprovals, col.get('field'));
      const centered = ['numberOfPriorityA', 'numberOfPriorityB', 'numberOfPriorityC', 'numberOfApprovedX'];
      const cls = centered.includes(col.get('field')) ? 'text-center' : 'text-right';

      return (
        <td key={col.get('field')} className={cls}>
          {sum} ({getPercent(sum, sumNoApproved)}%)
        </td>
      );
    }

    if (APPROVALS_STATS_SUM_COLUMNS.includes(col.get('field'))) {
      return <td key={col.get('field')}>{getSum(noApprovals, col.get('field'))}</td>;
    }

    return <td key={col.get('field')} />;
  });

  const renderCellsNoApprovals = noApprovals.size > 0 && (
    <tr className="no-approval-list__row" onDoubleClick={onNoApprovalDoubleClick}>
      {cellsNoApproval}
    </tr>
  );

  return (
    <tbody>
      {approvals.map((approval, index) => {
        if (approval.get('mode') === config.EDIT_MODE) {
          return <Edit key={index} onChange={onChange} row={approval} />;
        }

        return (
          <View
            key={index}
            contextId={contextId}
            onClick={onClick}
            onCollect={() => ({ type, approval })}
            onDoubleClick={onDoubleClick}
            row={approval}
          />
        );
      })}
      {renderCellsNoApprovals}
    </tbody>
  );
};

RowsComponent.propTypes = {
  approvals: PropTypes.instanceOf(Immutable.List).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
};

export const Rows = memo(RowsComponent);
