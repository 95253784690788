import PropTypes from 'prop-types';
import React, { useState, useCallback, memo } from 'react';
import InputRange from 'react-input-range';
import { Map } from 'immutable';

import { BrowseCheckbox } from './BrowseCheckbox';

const BrowseInputRangeComponent = ({ filter, info, onChange }) => {
  const [maxValue, setMaxValue] = useState(filter.get('maxValue'));

  const handleValuesChange = useCallback((values) => {
    const min = Math.round(values.min * 100) / 100;
    const max = Math.round(values.max * 100) / 100;

    onChange({
      filterName: filter.get('name'),
      filterData: {
        values: {
          min,
          max,
        },
        text: {
          min,
          max,
        },
      },
    });
  }, [filter, onChange]);

  const handleChange = useCallback((event) => {
    const { name, value } = event.target;

    if (isNaN(value)) {
      event.preventDefault();
      return false;
    }

    if (name === 'max') {
      setMaxValue(Number(value) || 0);
    }

    onChange({
      filterName: filter.get('name'),
      filterData: {
        text: {
          [name]: value,
        },
      },
    });
  }, [filter, onChange]);

  const handleBlur = useCallback((event) => {
    const { name, value } = event.target;
    const minValue = filter.get('minValue');
    let updateValue = Math.round(value * 100) / 100;
    const min = info.get('values').get('min');
    const max = info.get('values').get('max');

    if (name === 'min') {
      if (updateValue >= max) {
        updateValue = max;
      } else if (updateValue < minValue) {
        updateValue = minValue;
      }
    } else if (name === 'max') {
      if (updateValue <= min) {
        updateValue = min;
      } else if (updateValue > maxValue) {
        updateValue = maxValue;
      }
    }

    onChange({
      filterName: filter.get('name'),
      filterData: {
        values: {
          [name]: updateValue,
        },
        text: {
          [name]: updateValue,
        },
      },
    });
  }, [filter, info, maxValue, onChange]);

  const minValue = filter.get('minValue');
  const step = filter.get('step');
  const values = {
    min: info.get('values').get('min'),
    max: info.get('values').get('max'),
  };
  const text = {
    min: info.get('text').get('min'),
    max: info.get('text').get('max'),
  };

  return (
    <BrowseCheckbox {...{ filter, info, onChange }}>
      <div className="BrowseControl-content BrowseControl-content--slider">
        <InputRange
          maxValue={maxValue}
          minValue={minValue}
          onChange={handleValuesChange}
          step={step}
          value={values}
        />
        <div className="form-inline">
          <div className="form-group">
            <input
              className="form-control"
              name="min"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={text.min}
            />
          </div>
          <div className="form-group">&nbsp;-&nbsp;</div>
          <div className="form-group">
            <input
              className="form-control"
              name="max"
              onBlur={handleBlur}
              onChange={handleChange}
              type="text"
              value={text.max}
            />
          </div>
        </div>
      </div>
    </BrowseCheckbox>
  );
};

BrowseInputRangeComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

/** Browse input range component. */
export const BrowseInputRange = memo(BrowseInputRangeComponent);
