import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { arrayMoveImmutable } from 'array-move';

import { SortableList } from './SortableList';

class CompanyContactsLabels extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getInitialState(props);
  }

  getInitialState(props) {
    return {
      draggingIndex: null,
      data: {
        items: props.contacts.toJS(),
      },
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      data: {
        items: nextProps.contacts.toJS(),
      },
    });
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(
      ({ data: { items } }) => ({
        data: {
          items: arrayMoveImmutable(items, oldIndex, newIndex),
        },
      }),
      () => {
        if (this.state.draggingIndex === null) {
          const conts = this.props.contacts.map((c, i, contacts) =>
            contacts.find(contact => this.state.data.items[i].id === contact.get('id')),
          );

          return this.props.reorderContacts(conts);
        }
      },
    );
  };

  render() {
    const { entityId } = this.context;
    const { canEditData } = this.props;
    const { items: contacts } = this.state.data;

    return (
      <SortableList
        {...this.props}
        disabled={!canEditData}
        distance={1}
        entityId={entityId}
        items={contacts}
        lockAxis="y"
        onSortEnd={this.onSortEnd}
      />
    );
  }
}

CompanyContactsLabels.propTypes = {
  canEditData: PropTypes.bool.isRequired,
};

CompanyContactsLabels.contextTypes = {
  entityId: PropTypes.any,
};

export default CompanyContactsLabels;
