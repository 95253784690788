import getPercent from "./getPercent";

/**
 * Maps approvals row data.
 * @param approvals {Immutable.List} Approval list.
 */
export const mapApprovalsRowData = approvals => {
  return approvals.map(approval => {
    const percentApproved = `${approval.get('percentApproved')}%`;
    const numberTargets = approval.get('numberTargets');
    const numberApproved = approval.get('numberApproved');
    const numberOfContacted = approval.get('numberOfContacted');
    const numberOfLeads = approval.get('numberOfLeads');
    const numberOfCc = approval.get('numberOfCc');
    const numberOfVisit = approval.get('numberOfVisit');
    const numberOfNextActions = approval.get('numberOfNextActions');
    const numberOfPriorityA = approval.get('numberOfPriorityA');
    const numberOfPriorityB = approval.get('numberOfPriorityB');
    const numberOfPriorityC = approval.get('numberOfPriorityC');
    const numberOfApprovedX = approval.get('numberOfApprovedX');

    return approval
      .set('numberApproved', `${numberApproved} (${percentApproved})`)
      .set('numberOfContacted', `${numberOfContacted} (${getPercent(numberOfContacted, numberApproved)}%)`)
      .set('numberOfLeads', `${numberOfLeads} (${getPercent(numberOfLeads, numberApproved)}%)`)
      .set('numberOfCc', `${numberOfCc} (${getPercent(numberOfCc, numberApproved)}%)`)
      .set('numberOfVisit', `${numberOfVisit} (${getPercent(numberOfVisit, numberApproved)}%)`)
      .set('numberOfNextActions', `${numberOfNextActions} (${getPercent(numberOfNextActions, numberTargets)}%)`)
      .set('numberOfPriorityA', `${numberOfPriorityA} (${getPercent(numberOfPriorityA, numberTargets)}%)`)
      .set('numberOfPriorityB', `${numberOfPriorityB} (${getPercent(numberOfPriorityB, numberTargets)}%)`)
      .set('numberOfPriorityC', `${numberOfPriorityC} (${getPercent(numberOfPriorityC, numberTargets)}%)`)
      .set('numberOfApprovedX', `${numberOfApprovedX} (${getPercent(numberOfApprovedX, numberTargets)}%)`);
  });
}
