import { push } from 'connected-react18-router';

import { CALL_API } from '../../middleware/api';
import { reloadEvents } from './contactEvents';

export const CLEAR_LP_USERS = Symbol('CLEAR_LP_USERS');
export const LOAD_CONTACT_LP = Symbol('LOAD_CONTACT_LP');
export const ERROR_CONTACT_LP = Symbol('ERROR_CONTACT_LP');
export const LOADED_CONTACT_LP = Symbol('LOADED_CONTACT_LP');

export const LOADED_LP_USERS = Symbol('LOADED_CONTACT_LP');

export const MAKE_CONTACT_LP = Symbol('MAKE_CONTACT_LP');
export const MAKE_ERROR_CONTACT_LP = Symbol('MAKE_ERROR_CONTACT_LP');
export const SAVED_CONTACT_LP = Symbol('SAVED_CONTACT_LP');
export const ERRORED_SAVE_CONTACT_LP = Symbol('ERRORED_SAVE_CONTACT_LP');

export const CHANGE_CONTACT_LP_FIELD = Symbol('CHANGE_CONTACT_LP_FIELD');

/**
 * Add lp role to contact.
 *
 * @param id {Number|String}.
 * @param body {Object}.
 * @param afterSuccess {Function}.
 * @returns {{}}
 */
export function makeContactLP({ id, ...body }, afterSuccess, afterError) {
  let method = 'put';
  let path = `/api/v1/people/${id}`;

  if (id === 'new') {
    method = 'post';
    path = '/api/v1/people';
  }

  return {
    body,
    [CALL_API]: {
      method,
      path,
      body,
      skipGlobalErrorHandler: true,
      successType: SAVED_CONTACT_LP,
      afterSuccess: ({ dispatch, response, ...other }) => {
        if (id === 'new') {
          dispatch(push(`/contact/${response.id}/lp`));
        }
        afterSuccess({ response, ...other });
      },
      afterError,
      errorType: MAKE_ERROR_CONTACT_LP,
    },
  };
}

/**
 * Load contact-lp data.
 *
 * @param id {Number} Contact id.
 * @returns {MiddlewareApi.CallApi}
 */
export function loadContactLP({ id }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/people/${id}`,
      startType: LOAD_CONTACT_LP,
      successType: LOADED_CONTACT_LP,
      errorType: ERROR_CONTACT_LP,
    },
  };
}

/**
 * Save contact lp data.
 *
 * @param contactId {Number}.
 * @param body {Object}.
 * @returns {{fields: Array}}
 */
export function saveContactLp({ contactId, ...body }) {
  return {
    body,
    fields: Object.keys(body),
    [CALL_API]: {
      method: 'put',
      unifier: `put /api/v1/people/${contactId}/lp {${JSON.stringify(body)}}`,
      path: `/api/v1/people/${contactId}`,
      body,
      successType: SAVED_CONTACT_LP,
      errorType: ERRORED_SAVE_CONTACT_LP,
      afterSuccess: ({ dispatch }) => dispatch(reloadEvents({ entityId: contactId })),
    },
  };
}

/**
 * Change field of contact lp.
 *
 * @param name {String} Field name.
 * @param value {String|Object} New value.
 * @returns {{type: symbol, name: string, value: string|object}}
 */
export function changeField({ name, value }) {
  return {
    type: CHANGE_CONTACT_LP_FIELD,
    name,
    value,
  };
}

/**
 * Get users list by filter.
 *
 * @param filter {String}.
 * @returns {MiddlewareApi.CallApi}
 */
export function findUsers({ filter }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/users',
      unifier: 'get /api/v1/browse/users',
      maxCount: 2,
      query: {
        like: filter,
      },
      successType: LOADED_LP_USERS,
    },
  };
}

/**
 * Clear suggests of users.
 *
 * @returns {{type: symbol}}
 */
export function clearSuggest() {
  return {
    type: CLEAR_LP_USERS,
  };
}
