import React, { memo } from 'react';
import classNames from 'classnames';
import { unwrap as _unwrap } from 'src/utils/ChangeSpy';

import { ContactContextMenuWrapper } from './ContactContextMenuWrapper';

const blockStyle = { display: 'block' };

const unwrap = value => {
  value = _unwrap(value);
  if (value && value.get) value = value.get('name', '');

  return value;
};

const ContactLabelComponent = ({
  childProps: { contact, isOpen, onDel, onClick, entityId, className: cls },
  ...rest
}) => {

  const calcCls = classNames({
    active: isOpen,
    'text-italic text-muted': unwrap(contact.getIn(['pivot', 'endYear'], '')),
  });

  const firstName = unwrap(contact.get('firstName', ''));
  const lastName = unwrap(contact.get('lastName', ''));
  const title = unwrap(contact.getIn(['pivot', 'title'], ' '));
  const shouldContext = unwrap(contact.getIn(['pivot', 'entityId'], ' ')) === entityId;
  const entityName = unwrap(contact.getIn(['entityName'], ' '));

  if (shouldContext) {
    return (
      <li className={classNames(cls, calcCls, 'project-contact-item--sortable')} onClick={onClick} {...rest}>
        <a className={classNames(blockStyle, 'clickable')}>
          <ContactContextMenuWrapper data={contact} onDel={onDel}>
            {firstName} {lastName}
            <br />
            <small>{title}</small>
          </ContactContextMenuWrapper>
        </a>
      </li>)
  }
  return (
    <li className={classNames(cls, calcCls)} onClick={onClick} {...rest}>
      <a className="clickable" style={blockStyle}>
        <div>
          {firstName} {lastName}
          <br />
          <small>{title}</small> <br />
          <small>{entityName}</small>
        </div>
      </a>
    </li>
  )
}

/** Contact label component. */
export const ContactLabel = memo(ContactLabelComponent);
