import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';

const { toString } = Object.prototype;

const typeOf = o =>
  toString
    .call(o)
    .slice(8, -1)
    .toLowerCase();

function createLocationDescriptor({ to, query, hash, state }) {
  if (typeOf(to) === 'string') {
    return { pathname: to, query, hash, state };
  }

  return { query, hash, state, ...to };
}

/**
 * Component wrapping the <Link /> of react-router in selected component.
 * This is self implementation of old "react-router-active-component" npm package.
 *
 * @returns {React.Component} Active router link.
 */
const ActiveRouterLink = props => {
  const {
    link = true,
    linkClassName = undefined,
    wrapper: Wrapper,
    linkProps,
    to,
    query,
    hash,
    state,
    children,
    classes,
    exact,
    onClickCapture,
    name,
  } = props;

  const location = createLocationDescriptor({ to, query, hash, state });

  if (!link) {
    return <Wrapper className={classes}>{children}</Wrapper>;
  }

  return (
    <Wrapper className={classes} onClickCapture={onClickCapture}>
      {name === 'TRADE GROUPS' ? (
        <button className={linkClassName}>{children}</button>
      ) : (
        <NavLink activeClassName="active" className={linkClassName} {...linkProps} exact={exact} to={location}>
          {children}
        </NavLink>
      )}
    </Wrapper>
  );
};

ActiveRouterLink.propTypes = {
  classes: PropTypes.string,
  hash: PropTypes.string,
  link: PropTypes.bool,
  linkClassName: PropTypes.string,
  linkProps: PropTypes.object,
  name: PropTypes.string,
  query: PropTypes.object,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  wrapper: PropTypes.string.isRequired,
};

const ActiveRouterLinkWithRouter = withRouter(ActiveRouterLink);

export default ActiveRouterLinkWithRouter;
