import React, { memo } from 'react';
import classNames from 'classnames';
import { ContextMenuTrigger } from 'react-contextmenu';
import { isDeepChanged } from 'src/utils/ChangeSpy';

const ChangeSpyCellRender = ({ value, colDef, data, rowIndex }) => {
  const collect = {
    rowIndex,
  rowData: data,
  }

  const divClassName = classNames('ag-cell ag-cell-not-inline-editing', {
    'cell cell--marked': isDeepChanged(data),
  });

  return (
    <ContextMenuTrigger collect={collect} id={colDef.contextMenuId}>
      <div className={divClassName}>{value}</div>
    </ContextMenuTrigger>
  );
}

export default memo(ChangeSpyCellRender);
