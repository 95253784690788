import getPercent from "./getPercent";

/**
 * Return sum by field.
 *
 * @param {List} approvals Immutable List of approvals.
 * @param {string} field Field name.
 */
const getSum = (approvals, field) => approvals.reduce((sum, approval) => (sum += approval.get(field)), 0);

/**
 * Return total value.
 *
 * @param {List} approvals List of approved.
 * @param {string} numberOf Field name.
 * @param {number} percentOf Sum of approved.
 * @returns {string}
 */
const getTotalValue = (approvals, numberOf, percentOf) => {
  const sum = getSum(approvals, numberOf);
  const percent = getPercent(sum, percentOf);

  return `${sum} (${percent}%)`;
};

/**
 * Return total count of approved and percents of  ex.:1 (100%).
 *
 * @param {number} approved Count of approved.
 * @param {number} targets Count of targets.
 * @returns {string}
 */
const getTotalApprovedValue = (approved, targets) => {
  const percent = getPercent(approved, targets);

  return `${approved} (${percent}%)`;
};

/**
 * Return an array with an object which contains total stats data.
 *
 * @param {List} approvals Immutable List of approvals.
 */
export const getTotalStats = approvals => {
  const approvalsReturned = approvals.filter(approval => approval.getIn(['dateReceived', 'value'], 0) !== null);
  const sumReturnedTargets = getSum(approvalsReturned, 'numberTargets');
  const sumApproved = getSum(approvalsReturned, 'numberApproved');
  const sumTotal = getSum(approvals, 'numberApproved');
  const sumTargets = getSum(approvals, 'numberTargets');
  const totalNumberApproved = getSum(approvals, 'numberApproved');

  return [
    {
      applistLabel: { value: 'Total (Returned Approval Lists):' },
      dateSent: '',
      dateReceived: '',
      numberTargets: getSum(approvalsReturned, 'numberTargets'),
      numberApproved: getTotalValue(approvalsReturned, 'numberApproved', sumReturnedTargets),
      numberOfContacted: getTotalValue(approvalsReturned, 'numberOfContacted', sumApproved),
      numberOfLeads: getTotalValue(approvalsReturned, 'numberOfLeads', sumApproved),
      numberOfCc: getTotalValue(approvalsReturned, 'numberOfCc', sumApproved),
      numberOfVisit: getTotalValue(approvalsReturned, 'numberOfVisit', sumApproved),
      numberOfNextActions: getTotalValue(approvalsReturned, 'numberOfNextActions', sumReturnedTargets),
      numberOfPriorityA: getTotalValue(approvalsReturned, 'numberOfPriorityA', sumReturnedTargets),
      numberOfPriorityB: getTotalValue(approvalsReturned, 'numberOfPriorityB', sumReturnedTargets),
      numberOfPriorityC: getTotalValue(approvalsReturned, 'numberOfPriorityC', sumReturnedTargets),
      numberOfApprovedX: getTotalValue(approvalsReturned, 'numberOfApprovedX', sumReturnedTargets),
    },
    {
      applistLabel: { value: 'Total (All):' },
      dateSent: '',
      dateReceived: '',
      numberTargets: sumTargets,
      numberApproved: getTotalApprovedValue(totalNumberApproved, sumTargets),
      numberOfContacted: getTotalValue(approvals, 'numberOfContacted', sumTotal),
      numberOfLeads: getTotalValue(approvals, 'numberOfLeads', sumTotal),
      numberOfCc: getTotalValue(approvals, 'numberOfCc', sumTotal),
      numberOfVisit: getTotalValue(approvals, 'numberOfVisit', sumTotal),
      numberOfNextActions: getTotalValue(approvals, 'numberOfNextActions', sumTargets),
      numberOfPriorityA: getTotalValue(approvals, 'numberOfPriorityA', sumTargets),
      numberOfPriorityB: getTotalValue(approvals, 'numberOfPriorityB', sumTargets),
      numberOfPriorityC: getTotalValue(approvals, 'numberOfPriorityC', sumTargets),
      numberOfApprovedX: getTotalValue(approvals, 'numberOfApprovedX', sumTargets),
    },
  ];
};
