import React, { useState, memo } from 'react';

import GenerateOnlineApprovalListPopup from '../../components/Popup/GenerateOnlineApprovalListPopup';

const GenerateOnlineApprovalListPopupContainer = ({ popup }) => {
  const [checkedBoxes, setCheckedBoxes] = useState([]);

  const handleGenerateOnlineApprovalList = () => {
    const generateOnlineApprovalList = popup.getIn(['props', 'callback']);
    generateOnlineApprovalList(checkedBoxes);
  };

  const handleChange = event => {
    const { name, checked } = event.target;

    if (checked) {
      setCheckedBoxes(prevState => [...prevState, { [name]: checked }]);
    } else {
      setCheckedBoxes(prevState => prevState.filter(checkbox => Object.keys(checkbox)[0] !== name));
    }
  };

  return (
    <GenerateOnlineApprovalListPopup
      disabled={checkedBoxes.length < 1}
      onChange={handleChange}
      onGenerateOnlineApprovalList={handleGenerateOnlineApprovalList}
    />
  );
};

export default memo(GenerateOnlineApprovalListPopupContainer);
