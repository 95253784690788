import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

const { string, bool, func } = PropTypes;

const ERROR_401 = '401 Unauthorized';

const LoginFormComponent = ({ caption, errorContent, loadingContent, onSubmit }) => (
  <form className="form-signin" onSubmit={onSubmit}>
    <span className="reauth-email" id="reauth-email" />
    <input className="form-control" id="inputUsername" name="username" placeholder="Username" type="text" required />
    <input
      className="form-control"
      id="inputPassword"
      name="password"
      placeholder="Password"
      type="password"
      required
    />
    {errorContent}
    <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">
      Sign in
    </button>
    {loadingContent}
    {caption}
  </form>
);

const SecretCodeFormComponent = ({ loadingContent, errorContent, google2FaSecret, onSubmit }) => (
  <form className="form-qr" onSubmit={onSubmit}>
    {google2FaSecret && (
      <>
        <p className="text-white">For authentication need to install Google Authenticator App on your smartphone.</p>
        <div className="qr-scan-apps">
          <a
            href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en&gl=US"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img alt="Google Play" src="/img/google-play-button.png" />
          </a>
          <a
            href="https://apps.apple.com/us/app/google-authenticator/id388497605"
            rel="noopener noreferrer"
            target="_blank"
          >
            <img alt="App Store" src="/img/app-store-button.png" />
          </a>
        </div>
        <figure className="qr-image">
          <img alt="QR Code" src={google2FaSecret.get('qrCodeImage')} />
          <figcaption className="text-white pt5">Scan QA and enter a code below</figcaption>
        </figure>
      </>
    )}
    <input
      autoComplete="off"
      className="form-control"
      id="confirmationCode"
      inputMode="number"
      maxLength="6"
      name="confirmationCode"
      placeholder="Enter 2FA Code"
      type="tel"
      autoFocus
      required
    />
    {errorContent}
    <button className="btn btn-lg btn-primary btn-block btn-signin" type="submit">
      Sign in
    </button>
    {loadingContent}
  </form>
);

const isSecretCodeForm = (google2FaStatus, google2FaSecret, error) => {
  if (error === ERROR_401) {
    return false;
  }

  if (google2FaStatus && !google2FaStatus.get('isGoogle2FAPassed')) {
    return true;
  }

  if (google2FaStatus && !google2FaStatus.get('hasGoogle2FASecret')) {
    return true;
  }

  if (google2FaSecret) {
    return true;
  }

  return false;
};

/**
 * Stateless component for login form.
 *
 * @param props {Object}.
 * @param props.onSubmit {Function} Handle submit form event.
 * @param props.onGoogle2FAAuth {Function} Handle onGoogle2FAAuth form event.
 * @param props.error {String} Validate & API error.
 * @param props.caption {React.ReactElement} Additional caption.
 * @param props.isFetching {boolean} Is in fetching API state or not.
 * @param props.google2FaStatus {Map} Google 2FA status.
 * @param props.google2FaSecret {Map} Google 2FA secret.
 * @returns {JSX.Element} Form component.
 * @class
 */
const LoginForm = ({ caption, error, isFetching, google2FaStatus, google2FaSecret, onSubmit, onGoogle2FAAuth }) => {
  let errorContent = error ? <p className="error text-danger">{error}</p> : null;

  if (error === ERROR_401) {
    errorContent = (
      <p className="error text-danger">Your authorization token has expired. Enter username and password again.</p>
    );
  }

  const loadingContent = isFetching ? (
    <div>
      <div className="loading" />
      <i className="fa fa-spinner fa-pulse fa-3x fa-fw login-spinner" />
    </div>
  ) : null;

  return (
    <div className="card card-container">
      <div className="profile-logo" />
      <p className="profile-name-card" id="profile-name" />
      {isSecretCodeForm(google2FaStatus, google2FaSecret, error) ? (
        <SecretCodeFormComponent
          errorContent={errorContent}
          google2FaSecret={google2FaSecret}
          loadingContent={loadingContent}
          onSubmit={onGoogle2FAAuth}
        />
      ) : (
        <LoginFormComponent
          caption={caption}
          errorContent={errorContent}
          loadingContent={loadingContent}
          onSubmit={onSubmit}
        />
      )}
    </div>
  );
};

LoginForm.propTypes = {
  error: string.isRequired,
  google2FaSecret: PropTypes.instanceOf(Map),
  google2FaStatus: PropTypes.instanceOf(Map),
  isFetching: bool.isRequired,
  onGoogle2FAAuth: func.isRequired,
  onSubmit: func.isRequired,
};
export default LoginForm;
