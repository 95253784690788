import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Button from '../helpers/Button';

/**
 * User's button component at footer.
 *
 * @param props {Object}.
 * @param props.formMode {string} Form mode (view|edit).
 * @param props.saveStatus {string} Saving button status (enabled|error|saving).
 * @param props.onSaveClick {function} Handle saving form.
 * @param props.onEditClick {function} Handle editing form.
 * @param props.onAddClick {function} Clear selection and form fields.
 * @returns {React.Component}
 * @class
 */
const UserFooterComponent = ({ formMode, saveStatus, onSaveClick, onEditClick, onAddClick }) => {
  let saveButton = null;

  if (formMode === 'edit') {
    saveButton = (
      <Button className="ml5 btn-xs" disabled={saveStatus === 'error' || saveStatus === 'saving'} onClick={onSaveClick}>
        <i aria-hidden="true" className={saveStatus === 'saving' ? 'fa fa-spinner fa-pulse fa-fw' : 'fa fa-floppy-o'} />
        Save
      </Button>
    );
  }

  return (
    <footer className="navbar-fixed-bottom">
      <div className="container">
        <div className="pull-right row">
          {saveButton}
          <Button className="ml5 btn-xs" onClick={onAddClick}>
            <i aria-hidden="true" className="fa fa-plus" /> Add New
          </Button>
          <Button className="ml5 btn-xs" onClick={() => onEditClick(formMode === 'edit' ? 'view' : 'edit')}>
            <i aria-hidden="true" className={formMode === 'edit' ? 'fa fa-unlock' : 'fa fa-lock'} />
            Edit
          </Button>
        </div>
      </div>
    </footer>
  );
};

UserFooterComponent.propTypes = {
  formMode: PropTypes.string.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onSaveClick: PropTypes.func.isRequired,
  saveStatus: PropTypes.string.isRequired,
};

export const UserFooter = memo(UserFooterComponent);
