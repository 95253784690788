import React, { PureComponent } from 'react';
import { fromJS, List, Map } from 'immutable';

import Table from '../helpers/AgGridTable';
import config from '../../config';
import RecipientsHeader from './RecipientsHeader';
import { InlineInput } from '../helpers/Input';
import TextEditor from './TextEditor';
import GeneralFormat from './GeneralFormat';
import { ACTION_DELETE } from '../helpers/DeleteItem';

class FootSection extends PureComponent {
  state = {
    recipients: [],
    expiresAt: null,
  };

  constructor(props) {
    super(props);

    this.wrapperRef = React.createRef();
    this.handleChangeDate = this.handleChangeDate.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleGridReady = params => {
    this.api = params.api;
    this.api.sizeColumnsToFit();
  };

  handleInsert = () => {
    if (!this.api) return;

    this.api.stopEditing();

    let rowIndex = -1;

    this.api.forEachNode(rowNode => {
      if (rowNode.data.id === 0) {
        rowIndex = rowNode.rowIndex;

        return false;
      }
    });

    if (rowIndex === -1) {
      const transaction = this.api.updateRowData({
        add: [
          {
            id: 0,
            email: '',
          },
        ],
      });

      rowIndex = transaction.add[0].rowIndex;
    }

    this.api.startEditingCell({ rowIndex, colKey: 'email' });
  };

  columns = config.tables.getIn(['approval', 'recipients']).map(col =>
    col.merge({
      editable: true,
      headerComponentFramework: RecipientsHeader,
      cellRendererFramework: GeneralFormat,
      cellEditorFramework: TextEditor,
      onClick: this.handleInsert,
    }),
  );

  getRowNodeId = data => data.id;

  handleCellClicked = cell => {
    const {
      column: { colId },
      node: { rowIndex },
    } = cell;

    this.api.startEditingCell({ rowIndex, colKey: colId });
  };

  handleRowEditingStopped = ({ data }) => {
    if (data.id > 0) {
      this.setState(
        prevState => ({
          recipients: prevState.recipients.map(recipient => {
            if (data.id === recipient.id) {
              return data;
            }

            return recipient;
          }),
        }),
        () => this.props.generateEmailLink(this.state.recipients.map(recipient => recipient.email)),
      );
    }

    if (data.id === 0 && data.email) {
      this.setState(
        prevState => ({
          recipients: [...prevState.recipients, { ...data, id: prevState.recipients.length + 1 }],
        }),
        () => this.props.generateEmailLink(this.state.recipients.map(recipient => recipient.email)),
      );
    }
  };

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      if (this.api) {
        if (!/react-datepicker/.test(event.target.className) && event.target.className !== '') {
          // Don't stop editing when users click on datepicker.
          this.api.stopEditing();
        }
      }
    }
  };

  handleContextMenuClick = (event, data) => {
    const { id } = data.rowData;

    this.setState(
      prevState => ({
        recipients: prevState.recipients.filter(recipient => id !== recipient.id),
      }),
      () => this.props.generateEmailLink(this.state.recipients.map(recipient => recipient.email)),
    );
  };

  handleGenerateLink = () => {
    this.api.stopEditing();

    setTimeout(() => {
      const linkData = {
        expiresAt: this.state.expiresAt.toLocaleString(),
      };

      this.props.onGenerateLink(linkData);
    }, 100);
  };

  handleGenerateEmail = () => {
    this.api.stopEditing();

    this.props.onGenerateEmail(this.state.recipients.map(recipient => recipient.email));
  };

  handleChangeDate(event) {
    this.setState({ expiresAt: event.target.value });
  }

  render() {
    const { sending } = this.props;
    const { recipients, expiresAt } = this.state;
    const data = List(fromJS(recipients));
    const isDisabledLink = recipients.length === 0 || sending;

    return (
      <div className="row mt20 pb20">
        <div ref={this.wrapperRef} className="col-sm-5 table-approval-recipients">
          <Table
            columnDefs={this.columns}
            contextActionList={ACTION_DELETE}
            editType="fullRow"
            getRowNodeId={this.getRowNodeId}
            onCellClicked={this.handleCellClicked}
            onContextMenuClick={this.handleContextMenuClick}
            onGridReady={this.handleGridReady}
            onRowEditingStopped={this.handleRowEditingStopped}
            rowData={data}
            sortable={false}
          />
          <InlineInput
            className="readonly-datepicker"
            label="Pass Exp Date:"
            name="expiresAt"
            onChange={this.handleChangeDate}
            placeholder="Date"
            type="date"
            value={this.state.expiresAt}
            readOnly
            showLabel
          />
        </div>
        <div className="col-sm-7 text-right">
          <button
            className="btn btn-primary btn-xs mr15"
            disabled={expiresAt === null || sending}
            onClick={this.handleGenerateLink}
          >
            Generate Link
          </button>
          <a
            className="btn btn-primary btn-xs generate-email-button"
            disabled={isDisabledLink}
            href={isDisabledLink ? null : this.props.emailLink}
            rel="noopener noreferrer"
            target="_blank"
          >
            Generate Email
          </a>
        </div>
      </div>
    );
  }
}

export default FootSection;
