import React, { memo } from "react";
import { Map, List } from 'immutable';

const DeleteItemComponent = (() => (
  <div style={{ padding: '0 5px' }}>
    <span className="fa fa-trash mr5"> Delete</span>
  </div>
))();

/** Action delete. */
export const ACTION_DELETE = List([
  Map({
    key: 'delete',
    name: DeleteItemComponent,
  }),
]);

