import React, { memo, PureComponent } from 'react';

import { CriteriaRange } from './CriteriaRange';

const CriteriaRanges = ({ buyer: root, onChange, disabled }) =>
  <form onChange={onChange}>
    <CriteriaRange buyer={root} disabled={disabled} label="Revenue" name="revenue" onChange={onChange} />
    <CriteriaRange buyer={root} disabled={disabled} label="EBITDA" name="ebitda" onChange={onChange} />
    <CriteriaRange buyer={root} disabled={disabled} label="EV" name="ev" onChange={onChange} />
  </form>

export default memo(CriteriaRanges);
