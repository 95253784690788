import * as React from 'react';

import { ExtensionFormat } from './ExtensionFormat';
import { InternationalFormat } from './InternationalFormat';
import { CustomFormat } from './CustomFormat';
import { OtherFormat } from './OtherFormat';

export default class PhoneEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    const type = this.props.contact.getIn(['type', 'value']);

    this.saveCoponentToInstance(type);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const currentType = this.props.contact.getIn(['type', 'value']);
    const nextType = nextProps.contact.getIn(['type', 'value']);

    if (nextType !== currentType) {
      this.saveCoponentToInstance(nextType);
    }
  }

  saveCoponentToInstance(type) {
    switch (type) {
      case 'Extension':
        this.Component = ExtensionFormat;
        break;

      case 'International':
        this.Component = InternationalFormat;
        break;

      case 'Mobile':

      // fallthrough
      case 'Direct':

      // fallthrough
      case 'Home':

      // fallthrough
      case 'Fax':
        this.Component = OtherFormat;
        break;

      default:
        this.Component = CustomFormat;
    }
  }

  render() {
    const { Component } = this;

    return <Component {...this.props} />;
  }
}
