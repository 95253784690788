import { push } from 'connected-react18-router';

import { CALL_API, addGlobalAfterError } from '../middleware/api';
import { clearCode, clearIsTwoFa, getCode, setCode, setIsTwoFa } from '../middleware/activate';

export const START_AUTH_USER = 'START_AUTH_USER';
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const FETCHING_USER = 'FETCHING_USER';
export const FETCHING_USER_FAILURE = 'FETCHING_USER_FAILURE';
export const FETCHING_USER_SUCCESS = 'FETCHING_USER_SUCCESS';
export const FETCHING_USER_BY_ACTIVITY_DETECTOR = 'FETCHING_USER_BY_ACTIVITY_DETECTOR';
export const FETCHING_USER_BY_ACTIVITY_DETECTOR_SUCCESS = 'FETCHING_USER_BY_ACTIVITY_DETECTOR_SUCCESS';
export const VALIDATE_FAILURE = 'VALIDATE_FAILURE';
export const UPDATE_DEAL_NOTE_WIDTH = 'UPDATE_DEAL_NOTE_WIDTH';
export const FETCHING_GOOGLE_2FA_STATUS = 'FETCHING_GOOGLE_2FA_STATUS';
export const FETCHING_GOOGLE_2FA_STATUS_FAILURE = 'FETCHING_GOOGLE_2FA_STATUS_FAILURE';
export const FETCHING_GOOGLE_2FA_STATUS_SUCCESS = 'FETCHING_GOOGLE_2FA_STATUS_SUCCESS';
export const FETCHING_GOOGLE_2FA_SECRET = 'FETCHING_GOOGLE_2FA_SECRET';
export const FETCHING_GOOGLE_2FA_SECRET_FAILURE = 'FETCHING_GOOGLE_2FA_SECRET_FAILURE';
export const FETCHING_GOOGLE_2FA_SECRET_SUCCESS = 'FETCHING_GOOGLE_2FA_SECRET_SUCCESS';
export const SAVE_GOOGLE_2FA_SECRET = 'SAVE_GOOGLE_2FA_SECRET';
export const SAVE_GOOGLE_2FA_SECRET_FAILURE = 'SAVE_GOOGLE_2FA_SECRET_FAILURE';
export const SAVE_GOOGLE_2FA_SECRET_SUCCESS = 'SAVE_GOOGLE_2FA_SECRET_SUCCESS';
export const SEND_GOOGLE_2FA_AUTH = 'SEND_GOOGLE_2FA_AUTH';
export const SEND_GOOGLE_2FA_AUTH_FAILURE = 'SEND_GOOGLE_2FA_AUTH_FAILURE';
export const SEND_GOOGLE_2FA_AUTH_SUCCESS = 'SEND_GOOGLE_2FA_AUTH_SUCCESS';
export const CHECK_GOOGLE_2FA_SECRET = 'CHECK_GOOGLE_2FA_SECRET';
export const CHECK_GOOGLE_2FA_SECRET_FAILURE = 'CHECK_GOOGLE_2FA_SECRET_FAILURE';
export const CHECK_GOOGLE_2FA_SECRET_SUCCESS = 'CHECK_GOOGLE_2FA_SECRET_SUCCESS';
export const RESET_GOOGLE_2FA_STATUS = 'RESET_GOOGLE_2FA_STATUS';

function validateFailure(error) {
  return {
    type: VALIDATE_FAILURE,
    validateError: error,
  };
}

export function resetGoogle2FAStatus() {
  return {
    type: RESET_GOOGLE_2FA_STATUS,
  };
}

export function handleInputValidation(error) {
  return dispatch => {
    dispatch(validateFailure(error));
  };
}

export function handleLogin({ skipOnResetLogin = false, inputUsername, inputPassword, backUrl, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/auth/login',
      body: {
        userName: inputUsername,
        password: inputPassword,
      },
      skipGlobalErrorHandler: true,
      startType: skipOnResetLogin ? FETCHING_USER_BY_ACTIVITY_DETECTOR : FETCHING_USER,
      errorType: FETCHING_USER_FAILURE,
      successType: skipOnResetLogin ? FETCHING_USER_BY_ACTIVITY_DETECTOR_SUCCESS : FETCHING_USER_SUCCESS,
      afterSuccess: ({ dispatch, response }) => {
        setCode(response.token);
        setIsTwoFa(response.isTwoFa);

        if (afterSuccess) {
          afterSuccess();

          // Need for 2FA auth.
          if (response.isTwoFa) {
            dispatch(getStatusByActivityDetector({ afterSuccess: () => {} }));
          }
        }

        if (backUrl) {
          dispatch(getStatusGoogle2FA(backUrl));
        }
      },
      afterError: () => {
        clearCode();
        clearIsTwoFa();
      },
    },
  };
}

export function getStatusByActivityDetector({ afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/google2fa/status',
      skipGlobalErrorHandler: true,
      startType: FETCHING_GOOGLE_2FA_STATUS,
      errorType: FETCHING_GOOGLE_2FA_STATUS_FAILURE,
      successType: FETCHING_GOOGLE_2FA_STATUS_SUCCESS,
      afterSuccess: () => {
        if (afterSuccess) {
          afterSuccess();
        }
      },
    },
  };
}

export function getStatusGoogle2FA(backUrl) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/google2fa/status',
      skipGlobalErrorHandler: true,
      startType: FETCHING_GOOGLE_2FA_STATUS,
      errorType: FETCHING_GOOGLE_2FA_STATUS_FAILURE,
      successType: FETCHING_GOOGLE_2FA_STATUS_SUCCESS,
      afterSuccess: ({ dispatch, getState }) => {
        const { auth } = getState();

        if (
          !auth.getIn(['google2FaStatus', 'isGoogle2FAPassed']) &&
          !auth.getIn(['google2FaStatus', 'hasGoogle2FASecret'])
        ) {
          dispatch(getGoogle2FASecret());
        }

        if (
          auth.getIn(['google2FaStatus', 'isGoogle2FAPassed']) &&
          auth.getIn(['google2FaStatus', 'hasGoogle2FASecret'])
        ) {
          if (backUrl) {
            dispatch(push(backUrl));
          } else {
            dispatch(push('/main'));
          }
        }
      },
    },
  };
}

export function getGoogle2FASecret() {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/google2fa/secret',
      skipGlobalErrorHandler: true,
      startType: FETCHING_GOOGLE_2FA_SECRET,
      errorType: FETCHING_GOOGLE_2FA_SECRET_FAILURE,
      successType: FETCHING_GOOGLE_2FA_SECRET_SUCCESS,
    },
  };
}

export function saveGoogle2FASecret(secret, confirmationCode, backUrl) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/google2fa/secret',
      body: {
        secret,
      },
      skipGlobalErrorHandler: true,
      startType: SAVE_GOOGLE_2FA_SECRET,
      errorType: SAVE_GOOGLE_2FA_SECRET_FAILURE,
      successType: SAVE_GOOGLE_2FA_SECRET_SUCCESS,
      afterSuccess: ({ dispatch }) => {
        dispatch(sendGoogle2FAQRCode({ confirmationCode, backUrl }));
      },
    },
  };
}

export function sendGoogle2FAQRCode({ confirmationCode, backUrl, afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/google2fa/auth',
      body: {
        confirmationCode,
      },
      skipGlobalErrorHandler: true,
      startType: SEND_GOOGLE_2FA_AUTH,
      errorType: SEND_GOOGLE_2FA_AUTH_FAILURE,
      successType: SEND_GOOGLE_2FA_AUTH_SUCCESS,
      afterSuccess: ({ dispatch, response }) => {
        setCode(response.token);

        if (afterSuccess) {
          afterSuccess();
        }

        if (backUrl) {
          dispatch(getStatusGoogle2FA(backUrl));
        }
      },
    },
  };
}

export function authGoogle2FA(confirmationCode, backUrl, google2FaStatus, google2FaSecret) {
  if (google2FaStatus.get('hasGoogle2FASecret')) {
    return sendGoogle2FAQRCode({ confirmationCode, backUrl });
  }

  const secret = google2FaSecret.get('secret');

  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/google2fa/secret/check',
      body: {
        confirmationCode,
        secret,
      },
      skipGlobalErrorHandler: true,
      startType: CHECK_GOOGLE_2FA_SECRET,
      errorType: CHECK_GOOGLE_2FA_SECRET_FAILURE,
      successType: CHECK_GOOGLE_2FA_SECRET_SUCCESS,
      afterSuccess: ({ dispatch, response }) => {
        if (response.data.accepted) {
          dispatch(saveGoogle2FASecret(secret, confirmationCode, backUrl));
        }
      },
    },
  };
}

export function handleLogout() {
  return {
    [CALL_API]: {
      method: 'post',
      path: '/api/v1/auth/logout',
      successType: UNAUTH_USER,
      afterSuccess: ({ dispatch }) => {
        clearCode();
        clearIsTwoFa();
        dispatch(push('/login'));
      },
    },
  };
}

function checkIfUnauthed({ dispatch, response, getState }) {
  if (!response) return;

  if (response.statusCode === 403 && response.body.message.includes('two-factor')) {
    const { pathname, search = '' } = getState().router.location;
    const currentUrl = `${pathname}${search}`;

    if (!/^\/login/.test(currentUrl)) {
      dispatch({
        type: UNAUTH_USER,
        currentUrl,
      });

      clearCode();
      clearIsTwoFa();

      return dispatch(push(`/login?backUrl=${encodeURIComponent(currentUrl)}`));
    }
  }

  if (response.statusCode === 401 || response.statusCode === 403) {
    dispatch(refreshToken());
  }
}

export function refreshToken() {
  return {
    [CALL_API]: {
      method: 'put',
      body: {
        token: getCode(),
      },
      unifier: '/api/v1/auth/refresh',
      path: '/api/v1/auth/refresh',
      skipGlobalErrorHandler: true,
      afterSuccess: ({ response }) => {
        setCode(response.token);
      },
      afterError: ({ dispatch, getState }) => {
        const { pathname, search = '' } = getState().router.location;
        const currentUrl = `${pathname}${search}`;

        if (!/^\/login/.test(currentUrl)) {
          dispatch({
            type: UNAUTH_USER,
            currentUrl,
          });

          clearCode();
          clearIsTwoFa();

          return dispatch(push(`/login?backUrl=${encodeURIComponent(currentUrl)}`));
        }
      },
    },
  };
}

export function checkAuth({ afterSuccess }) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/users/profile',
      skipGlobalErrorHandler: true,
      beforeType: START_AUTH_USER,
      successType: AUTH_USER,
      afterSuccess: () => {
        addGlobalAfterError(params => {
          checkIfUnauthed(params);
        });

        if (afterSuccess) {
          afterSuccess();
        }
      },
      afterError: checkIfUnauthed,
    },
  };
}

export function updateDealNoteWidth({ width, userId }) {
  return {
    width,
    [CALL_API]: {
      method: 'put',
      body: {
        tdnwidth: width,
      },
      unifier: `PUT ${width} /api/v1/users/${userId}`,
      path: `/api/v1/users/${userId}`,
      successType: UPDATE_DEAL_NOTE_WIDTH,
      skipGlobalErrorHandler: true,
    },
  };
}
