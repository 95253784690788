import PropTypes from 'prop-types';

import React, { memo, useEffect } from 'react';
import { Map } from 'immutable';
import AutoComplete from '../helpers/AutoComplete';
import isFunction from '../../utils/isFunction';

const BrowseAutoCompleteComponent = ({
  isHidden,
  filter,
  info,
  onGetSuggestion,
  onGetNextSuggestion,
  onChange,
  children,
}) => {
  useEffect(() => {
    onGetSuggestion({ value: '' });
  }, []);

  const handleUpdateSuggestions = update => {
    onChange({
      filterName: filter.get('name'),
      filterData: update,
    });
  }

  const handleGetSuggestionValue = suggestion => {
    return suggestion[filter.get('valueField')];
  }

  const handleSuggestionSelected = (event, { suggestion }) => {
    const keepText = filter.get('keepText', false);
    const render = filter.get('renderField');

    onChange({
      filterName: filter.get('name'),
      filterData: {
        text: keepText
          ? render && isFunction(render)
            ? render(suggestion)
            : suggestion[filter.get('renderField')]
          : '',
        suggestions: [],
        selected: suggestion,
      },
    });
  }

  const handleRenderSuggestion = suggestion => {
    const render = filter.get('renderField');

    if (render && isFunction(render)) {
      return <div>{render(suggestion)}</div>;
    }

    return <div>{suggestion[render]}</div>;
  }

  const suggestions = info.get('suggestions');
  const text = info.get('text');
  const loading = info.get('loading', null);
  const suggestionProps = {
    inputProps: {
      className: filter.get('className'),
      id: filter.get('name'),
      name: filter.get('name'),
      label: filter.get('inputLabel'),
      disabled: false,
      placeholder: filter.get('placeholder'),
    },
    formGroupClass: filter.get('formGroupClass'),
    suggestions,
    loading,
    onUpdateSuggestions: handleUpdateSuggestions,
    getSuggestion: onGetSuggestion,
    getNextSuggestion: onGetNextSuggestion,
    getSuggestionValue: handleGetSuggestionValue,
    renderSuggestion: handleRenderSuggestion,
    onSuggestionSelected: handleSuggestionSelected,
    suggestIfEmpty: true,
    highlightFirstSuggestion: false,
    text,
  };

  return isHidden ? null : <AutoComplete {...suggestionProps}>{children}</AutoComplete>;
}
BrowseAutoCompleteComponent.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetNextSuggestion: PropTypes.func,
  onGetSuggestion: PropTypes.func.isRequired,
};

/** Browse auto complete component. */
export const BrowseAutoComplete = memo(BrowseAutoCompleteComponent);
