import PropTypes from 'prop-types';
import React, { memo } from 'react';
import classNames from 'classnames';

const BuyerFilterTagComponent = ({ index, value = '', exclude = false, onRemoveTag, onChangeStatus }) => {
  const tagClassName = classNames('MultiselectItem', { exclude });

  return (
    <div className={tagClassName} onClick={() => onChangeStatus(index)}>
      {value}
      <button
        aria-hidden="true"
        aria-label="close"
        className="MultiselectItem-close close btn-xs"
        name={`buyerFilterTag${index}`}
        onClickCapture={event => onRemoveTag(event)}
        type="button"
      >
        &times;
      </button>
    </div>
  );
};

BuyerFilterTagComponent.propTypes = {
  exclude: PropTypes.bool,
  index: PropTypes.number.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onRemoveTag: PropTypes.func.isRequired,
  value: PropTypes.any,
};

/** Buyer filter tag component. */
export const BuyerFilterTag = memo(BuyerFilterTagComponent);
