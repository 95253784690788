import PropTypes from 'prop-types';
import { push } from 'connected-react18-router';

import React, { PureComponent } from 'react';
import $ from 'jquery';
import Immutable, { Map } from 'immutable';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import moment from 'moment';
import connectOptions, { mergeProps } from '../utils/connectOptions';

import config from '../config';
import { Backlog } from '../components/Backlog';
import { loadUsers, loadBacklogs, changeUser, sort, fetchNextBacklogs } from '../actions/backlog';
import { openInternalLink } from '../utils/url';
import { isResearcher } from '../utils/checkPermissions';
import { showError } from '../utils/MessagePopup';

/**
 * Backlog container component.
 *
 * @param props {Object}.
 * @returns {React.Component}
 * @class
 */
class BacklogContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleChangeUser = this.handleChangeUser.bind(this);
    this.handleRowDoubleClick = this.handleRowDoubleClick.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleMailingClick = this.handleMailingClick.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    this.getHeaderRef = this.getHeaderRef.bind(this);
    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleGridReady = this.handleGridReady.bind(this);
    this.handleReloadClick = this.handleReloadClick.bind(this);
    this.handleOneOffApproachClick = this.handleOneOffApproachClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);

    this.bottomGap = 40;
    this.state = {
      dateQuery: moment(),
      tableHeight: 600,
    };
  }

  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.push('/');
        this.context.closePopup();
      });

      return;
    }

    this.props.loadUsers();
    $(window).on('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    $(window).off('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    this.setState({
      tableHeight: $(window).height() - $(this.header).height() - this.bottomGap,
    });
  }

  componentDidUpdate(prevProps) {
    const { users: prevUsers, columnConfigs: prevColumnConfigs } = prevProps;
    const { users, loadBacklogs, columnConfigs } = this.props;
    const { dateQuery } = this.state;
    const ids = this._getSelectedUserIds(users).toJS();
    const sort = this._getSorting(columnConfigs);

    if (!Immutable.is(prevUsers, users) || !Immutable.is(prevColumnConfigs, columnConfigs)) {
      if (this.gridApi.ensureIndexVisible) this.gridApi.ensureIndexVisible(0); // scroll table to top
      loadBacklogs(ids, sort, dateQuery.format('YYYY-MM-DD'));
    }
  }

  _getSorting(columns) {
    const sortingCol = columns.find(column => column.get('sort', '').length > 0);

    if (sortingCol) {
      return `${sortingCol.get('sort') === 'asc' ? '' : '-'}${sortingCol.get('field')}`;
    }

    return null;
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  handleChangeUser(event) {
    const { value, checked } = event.target;

    this.props.changeUser(parseInt(value, 10), checked);
  }

  handleMailingClick() {
    openInternalLink('/mailing-history'); // need to update the link when mailing page is available
  }

  handleRowDoubleClick({ data: { recordOwnerId, recordSubOwnerId, buyerId, projectId, activity, isEmail, isLetter } }) {
    const beforeDate = moment(this.state.dateQuery).format('YYYY-MM-DD');

    openInternalLink(
      `/mailing-create?recordOwnerId=${recordOwnerId}&recordSubOwnerId=${recordSubOwnerId}&buyerId=${buyerId}&projectId=${projectId}&activity=${activity}&isEmail=${isEmail}&isLetter=${isLetter}&beforeDate=${beforeDate}`,
    ); // need to update the link when mail creating page is available
  }

  handleSort(event) {
    if (event.length > 0) {
      this.props.sort(event[0].colId, event[0].sort);
    }
  }

  getHeaderRef(input) {
    this.header = input;
  }

  _getSelectedUserIds(users) {
    return users.filter(user => user.get('selected')).map(user => user.get('id'));
  }

  handleGetNextPageData(page) {
    const { users, fetchNextBacklogs, columnConfigs } = this.props;
    const ids = this._getSelectedUserIds(users).toJS();
    const sort = this._getSorting(columnConfigs);
    const date = this.state.dateQuery.format('YYYY-MM-DD');

    if (ids.length) {
      fetchNextBacklogs(ids, page, sort, date);
    }
  }

  handleGridReady(params) {
    this.gridApi = params.api;
  }

  handleReloadClick() {
    const { users, loadBacklogs, columnConfigs } = this.props;
    const { dateQuery } = this.state;
    const ids = this._getSelectedUserIds(users).toJS();
    const sort = this._getSorting(columnConfigs);

    this.gridApi.ensureIndexVisible(0); // scroll table to top
    loadBacklogs(ids, sort, dateQuery.format('YYYY-MM-DD'));
  }

  handleOneOffApproachClick() {
    const { dateQuery } = this.state;

    this.context.openPopup('OneOffApproachPopup', { dateQuery, callback: this.handleReloadClick });
  }

  handleDateChange(event) {
    const { users, loadBacklogs, columnConfigs } = this.props;
    const ids = this._getSelectedUserIds(users).toJS();
    const sort = this._getSorting(columnConfigs);

    this.setState({ dateQuery: event.target.value });
    this.gridApi.ensureIndexVisible(0); // scroll table to top
    loadBacklogs(ids, sort, event.target.value.format('YYYY-MM-DD'));
  }

  render() {
    const content = this.hasAccess() ? (
      <Backlog
        {...this.props}
        dateQuery={this.state.dateQuery}
        getHeaderRef={this.getHeaderRef}
        onChangeUser={this.handleChangeUser}
        onClick={this.handleMailingClick}
        onDateChange={this.handleDateChange}
        onDoubleClick={this.handleRowDoubleClick}
        onGetNextPageData={this.handleGetNextPageData}
        onGridReady={this.handleGridReady}
        onOneOffApproachClick={this.handleOneOffApproachClick}
        onReloadClick={this.handleReloadClick}
        onSort={this.handleSort}
        tableHeight={this.state.tableHeight}
      />
    ) : null;

    return (
      <React.Fragment>
        <Helmet title="Backlog Page" />
        {content}
      </React.Fragment>
    );
  }
}

BacklogContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
  closePopup: PropTypes.func.isRequired,
};

BacklogContainer.propTypes = {
  backlogLoading: PropTypes.bool.isRequired,
  backlogs: PropTypes.instanceOf(Immutable.List).isRequired,
  changeUser: PropTypes.func,
  columnConfigs: PropTypes.instanceOf(Immutable.List).isRequired,
  fetchNextBacklogs: PropTypes.func,
  loadBacklogs: PropTypes.func,
  loadUsers: PropTypes.func,
  loggedUser: PropTypes.instanceOf(Map).isRequired,
  sort: PropTypes.func,
  userLoading: PropTypes.bool.isRequired,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

const mapStateToProps = state => ({
  loggedUser: state.auth.get('user'),
  users: state.backlog.get('users'),
  backlogs: state.backlog.get('backlogs'),
  userLoading: state.backlog.get('userLoading'),
  backlogLoading: state.backlog.get('backlogLoading'),
  columnConfigs: state.backlog.get('columns'),
  totalPages: state.backlog.get('totalPages'),
  currentPage: state.backlog.get('currentPage'),
});

export default connect(
  mapStateToProps,
  {
    loadBacklogs,
    loadUsers,
    changeUser,
    sort,
    fetchNextBacklogs,
    push,
  },
  mergeProps,
  connectOptions,
)(BacklogContainer);
