import React, { memo } from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import uniqueId from 'src/utils/uniqueId';

const ContactContextMenuWrapperComponent = ({ id = uniqueId(), onDel, children, data }) => (
  <div>
    <ContextMenuTrigger collect={() => data.toJS()} holdToDisplay={-1} id={id}>
      {children}
    </ContextMenuTrigger>

    <ContextMenu id={id}>
      <MenuItem onClick={onDel}>
        <i className="fa fa-remove" /> Delete
      </MenuItem>
    </ContextMenu>
  </div>
);

/** Contact context menu wrapper component. */
export const ContactContextMenuWrapper = memo(ContactContextMenuWrapperComponent);
