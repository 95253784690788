import PropTypes from 'prop-types';
import { push } from 'connected-react18-router';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import { isDeepChanged, getChanged, getIn, toJS } from '../../utils/ChangeSpy';
import { showError } from '../../utils/MessagePopup';
import ContactExecutive from '../../components/Contact/ContactExecutive';
import AutoCompleteContainer from '../AutoComplete';
import confirm from '../decorators/confirm';
import stopProp from '../../decorators/stopPropagation';
import { dateToString } from '../../utils/dateFormat';
import {
  loadContactExecutive,
  changeField,
  findUsers,
  clearSuggest,
  triggerAccordion,
  saveContactFund,
  saveContactPosition,
  activateContactFund,
  delContactFund,
  delContactPosition,
  saveContactExecutive,
  saveContactIndustries,
  addContactIndustry,
  delContactIndustry,
  saveContactTags,
  addContactTag,
  delContactTag,
  setFieldOnOne,
  findTitles,
  findIndustries,
  findProjects,
  closeValidationError,
  updateIndustryTags,
} from '../../actions/contact/contactExecutive';
import {
  enterContactDealNote,
  leaveContactDealNote,
  enterContactEmail,
  leaveContactEmail,
} from '../../actions/contact/contactEvents';
import { fetchExecutivesStatuses } from '../../actions/statuses';

import config from '../../config';

const qualities = config.values.getIn(['contactExecutive', 'quality']);
const interests = config.values.getIn(['contactExecutive', 'interest']);
const relations = config.values.getIn(['contactExecutive', 'relation']);
const qualitiesOfExecutive = config.values.getIn(['contactExecutive', 'qualitiesOfExecutive']);

class ContactExecutiveContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.onSave = this.onSave.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onErrorClose = this.onErrorClose.bind(this);

    this.onBlur = this.onBlur.bind(this);
    this.onBlurDetails = this.onBlurDetails.bind(this);
    this.saveIndustries = this.saveIndustries.bind(this);

    this.bindIndustry(context);
    this.bindTag(context);
    this.bindFund(context);
    this.bindPosition(context);
    this.bindMouseEvents(context);
    this.bindSuggestions(context);
  }

  onErrorClose(event, field) {
    this.props.closeValidationError({ field });
  }

  getChildContext() {
    return {
      onErrorClose: this.onErrorClose,
      inputErrors: this.props.inputErrors,
      addNewIndustry: this.addNewIndustry,
    };
  }

  componentWillUnmount() {
    this.context.removeOnSaveCallback(this.onBlur);
    this.context.removeOnSaveCallback(this.saveIndustries);
  }

  bindIndustry(context) {
    this.addNewIndustry = this.addNewIndustry.bind(this);
    this.addIndustry = this.addIndustry.bind(this);
    this.onClickIndustries = this.onClickIndustries.bind(this);
    this.delIndustry = stopProp(confirm('Delete this industry?', this.delIndustry.bind(this), context));
  }

  bindTag(context) {
    this.onClickTags = this.onClickTags.bind(this);
    this.addTag = this.addTag.bind(this);
    this.delTag = stopProp(confirm('Delete this tag?', this.delTag.bind(this), context));
  }

  bindFund(context) {
    this.addFund = this.addFund.bind(this);
    this.delFund = stopProp(confirm('Delete this fund?', this.delFund.bind(this), context));
    this.activateFund = this.activateFund.bind(this);
    this.triggerFund = this.triggerFund.bind(this);
    this.onBlurFund = this.onBlurFund.bind(this);
  }

  bindPosition(context) {
    this.addPosition = this.addPosition.bind(this);
    this.delPosition = stopProp(confirm('Delete this position?', this.delPosition.bind(this), context));
    this.onBlurPosition = this.onBlurPosition.bind(this);
    this.triggerPosition = this.triggerPosition.bind(this);
  }

  bindMouseEvents() {
    this.onDialNotesMouseEnter = this.onDialNotesMouseEnter.bind(this);
    this.onDialNotesMouseLeave = this.onDialNotesMouseLeave.bind(this);
    this.onEmailMouseEnter = this.onEmailMouseEnter.bind(this);
    this.onEmailMouseLeave = this.onEmailMouseLeave.bind(this);
  }

  bindSuggestions() {
    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onFetchIndustries = this.onFetchIndustries.bind(this);
    this.onFetchTitles = this.onFetchTitles.bind(this);
    this.onFetchProjects = this.onFetchProjects.bind(this);

    this.onUpdateSuggestions = this.onUpdateSuggestions.bind(this);

    this.onUpdateDirector = this.onUpdateSuggestions.bind(this, 'suggestDirector');
    this.onUpdateAnalyst = this.onUpdateSuggestions.bind(this, 'suggestAnalyst');
    this.onUpdateDealmaker = this.onUpdateSuggestions.bind(this, 'suggestDealmaker');
    this.onUpdateResearcher = this.onUpdateSuggestions.bind(this, 'suggestResearcher');
    this.onSelectDirector = this.onSelectUser.bind(this, 'suggestDirector');
    this.onSelectAnalyst = this.onSelectUser.bind(this, 'suggestAnalyst');
    this.onSelectDealmaker = this.onSelectUser.bind(this, 'suggestDealmaker');
    this.onSelectResearcher = this.onSelectUser.bind(this, 'suggestResearcher');
    this.onSelectIndustry = this.onSelectIndustry.bind(this);
    this.onSelectTitle = this.onSelectTitle.bind(this);
    this.onSelectProject = this.onSelectProject.bind(this);
  }

  checkIfExec() {
    if (this.context.hasPopup()) return;
    if (this.props.loading) return;

    const { contactId } = this.props.match.params;
    let name;

    if (!this.props.isExecutive) {
      if (!isNaN(contactId)) name = 'ExecutiveRolePopup';
      else name = 'AddExecutiveContactPopup';
      this.context.openPopup(name, { contactId });
      this.context.onClosePopup(() => {
        const { contactId } = this.props.match.params;

        if (!this.props.isExecutive) {
          return this.props.push(`/contact/${contactId}`);
        }

        return this.props.loadContactExecutive({ id: contactId });
      });
    } else {
      this.props.loadContactExecutive({ id: contactId });
    }
  }

  componentDidMount() {
    document.body.style.height = '100vh !important';
    document.getElementById('root').style.height = '100vh !important';

    this.checkIfExec();
    this.context.addOnSaveCallback(this.onBlur);
    this.context.addOnSaveCallback(this.saveIndustries);
    this.props.fetchExecutivesStatuses();
  }

  componentDidUpdate(lastProps) {
    if (lastProps.loading) this.checkIfExec();
  }

  onSave() {
    const { contact: contactIm } = this.props;
    const contact = contactIm.toJS();

    if (contact.lastResearchedDate) {
      contact.lastResearchedDate = contact.lastResearchedDate.format('YYYY-MM-DD');
    }
  }

  onBlur() {
    this.onBlurDetails();
    this.onBlurFund();
    this.onBlurPosition();
  }

  onBlurDetails() {
    const { contactId } = this.props.match.params;

    if (this.props.contact.get('isValid')) {
      const body = getChanged(this.props.contact);

      if (Object.keys(body).length !== 0) {
        body.contactId = contactId;
        Object.keys(body).forEach(key => {
          body[key] = dateToString(body[key]);
        });
        this.props.saveContactExecutive(body);
      }
    }
    this.saveTags({ contactId });
  }

  static onSubmitDetails(event) {
    event.preventDefault();
  }

  static onSubmit(event) {
    event.preventDefault();
  }

  onClickIndustries(event, index) {
    const field = 'contactIndustries';
    const name = 'isEditing';
    const value = true;
    const other = false;

    this.props.setFieldOnOne({
      field,
      name,
      value,
      other,
      index,
    });
  }

  saveIndustries() {
    const { contactId } = this.props.match.params;
    const bic = getIn(this.props.contact, 'selectedBICs');
    const bm = getIn(this.props.contact, 'selectedBMs');

    if (!bm.length || !bic.length) {
      showError(this.context.openPopup, ['Industry tags or Business models are missing']);
    } else {
      this.props.saveContactIndustries(contactId, bic, bm);
    }
  }

  addNewIndustry() {
    this.context.openPopup('NewIndustryPopup', {
      afterClose: value => {
        const index = this.props.contact.getIn(['contactIndustries']).findIndex(industry => industry.get('isEditing'));

        this.props.changeField({
          name: `contactIndustries.${index}.industryLabel`,
          value: value.label,
        });
        this.props.changeField({
          name: `contactIndustries.${index}.pivot.industryId`,
          value: value.id,
        });
      },
    });
  }

  addIndustry() {
    const tags = getIn(this.props.contact, 'selectedBICs');
    const models = getIn(this.props.contact, 'selectedBMs');

    this.context.openPopup('TagsManagementPopup', {
      addNew: false,
      clientView: true,
      selectedTags: tags.reduce((acc, t) => {
        acc[t] = true;

        return acc;
      }, {}),
      selectedModels: models.reduce((acc, m) => {
        acc[m] = true;

        return acc;
      }, {}),
      callback: (tagSelecteds, modelSelecteds) => this.props.updateIndustryTags(tagSelecteds, modelSelecteds),
    });
  }

  delIndustry(event, data) {
    const { contactId } = this.props.match.params;
    const industryId = data.id;
    const industries = this.props.contact.get('contactIndustries');
    const body = toJS(industries)
      .filter(industry => industry.id !== industryId)
      .map((industry, i) => {
        industry.pivot.priority = i;

        return industry.pivot;
      });

    this.props.delContactIndustry({ contactId, industryId, body }, this.context.closePopup);
  }

  saveTags({ contactId }) {
    if (!isDeepChanged(this.props.contact.get('harvcoTags'))) return;

    const harvcoTags = this.props.contact.get('harvcoTags').map(harvcoTag => {
      const id = harvcoTag.get('id');
      const tag = getIn(harvcoTag, 'tag');

      return { id, tag };
    });

    this.props.saveContactTags({ contactId, body: harvcoTags.toJS() });
  }

  onClickTags(event, index) {
    const field = 'harvcoTags';
    const name = 'isEditing';
    const value = true;
    const other = false;

    this.props.setFieldOnOne({
      field,
      name,
      value,
      other,
      index,
    });
  }

  addTag() {
    this.props.addContactTag();
  }

  delTag(event, data) {
    const { contactId } = this.props.match.params;
    const tagId = data.id;

    this.props.delContactTag({ contactId, tagId }, this.context.closePopup);
  }

  /** Deal Notes methods */
  onDialNotesMouseEnter(event, eventId) {
    this.props.enterContactDealNote({ eventId });
  }

  onDialNotesMouseLeave(event, eventId) {
    this.props.leaveContactDealNote({ eventId });
  }

  /** Emails methods */
  onEmailMouseEnter(event, eventId) {
    this.props.enterContactDealNote({ eventId });
  }

  onEmailMouseLeave(event, eventId) {
    this.props.leaveContactDealNote({ eventId });
  }

  /** Main onChange callback. */
  onChange(event) {
    const { name, value: val, checked, type } = event.target;
    const value = type === 'checkbox' ? checked : val;

    return this.props.changeField({ name, value });
  }

  /** Funds methods. */
  addFund() {
    const name = 'AddFundPopup';
    const { contactId } = this.props.match.params;
    const { statuses } = this.props;

    this.context.openPopup(name, { contactId, statuses });
  }

  /**
   * Delete fund.
   *
   * @param e Event.
   * @param data Fund.
   */
  delFund(event, data) {
    const { contactId } = this.props.match.params;
    const { id } = data;
    const {
      context: { closePopup },
    } = this;

    this.props.delContactFund({ contactId, id }, closePopup);
  }

  triggerFund(event, index) {
    this.props.triggerAccordion({ field: 'funds', index });
  }

  activateFund(event, index) {
    this.props.activateContactFund({ index });
  }

  static submitFund(event) {
    event.preventDefault();
  }

  onBlurFund() {
    if (this.props.contact.get('isValid')) {
      const fundsToSave = this.props.contact.get('funds').filter(fund => isDeepChanged(fund));

      fundsToSave.map(fund => {
        const body = getChanged(fund);

        body.id = fund.get('id');
        body.contactId = this.props.match.params.contactId;
        this.props.saveContactFund(body);
      });
    }
  }

  /** Position methods */
  addPosition() {
    const name = 'AddPositionPopup';
    const { contactId } = this.props.match.params;

    this.context.openPopup(name, {
      contactId,
      cb: this.addPosition.bind(this),
    });
  }

  triggerPosition(event, index) {
    this.props.triggerAccordion({ field: 'positions', index });
  }

  delPosition(event, data) {
    const { contactId } = this.props.match.params;
    const { id } = data;
    const {
      context: { closePopup },
    } = this;

    this.props.delContactPosition({ contactId, id }, closePopup);
  }

  static submitPosition(event) {
    event.preventDefault();
  }

  onBlurPosition() {
    if (this.props.contact.get('isValid')) {
      const positionsToSave = this.props.contact.get('positions').filter(position => isDeepChanged(position));

      positionsToSave.map(position => {
        const body = getChanged(position);

        body.id = position.get('id');
        body.contactId = this.props.match.params.contactId;
        if ('startYear' in body) {
          body.startYear = parseInt(body.startYear, 10) || null;
        }
        this.props.saveContactPosition(body);
      });
    }
  }

  /** Suggestions methods */
  onFetchUsers({ value }) {
    this.props.findUsers({ filter: value });
  }

  onUpdateSuggestions(suggestionName, param) {
    if ('text' in param) {
      const name = suggestionName;
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  onSelectUser(name, e, { suggestion }) {
    this.props.changeField({ name, value: suggestion.text });
  }

  getSuggestIdName(name) {
    switch (name) {
      case 'suggestDirector':
        return 'recordOwnerId';

      case 'suggestAnalyst':
        return 'recordSubOwnerId';

      case 'suggestDealmaker':
        return 'dealmakerId';

      case 'suggestResearcher':
        return 'lastResearcherId';
    }
  }

  getSuggest(name) {
    this.___suggestsStore___ = this.___suggestsStore___ || {};
    this.___suggestsStore___[name] = this.___suggestsStore___[name] || (
      <AutoCompleteContainer
        change={this.props.changeField}
        find={params => {
          switch (name) {
            case 'suggestDirector':
              this.props.findUsers({ ...params, role: config.DIRECTOR });
              break;

            case 'suggestAnalyst':
              this.props.findUsers({
                ...params,
                role: config.ANALYST_ASSOCIATE,
              });
              break;

            default:
              this.props.findUsers(params);
              break;
          }
        }}
        idName={this.getSuggestIdName(name)}
        rootPath={['contact', 'executive']}
        suggestsName="suggests"
        valueName={name}
      />
    );

    return this.___suggestsStore___[name];
  }

  onFetchIndustries({ value }) {
    this.props.findIndustries({ filter: value });
  }

  onSelectIndustry(name, e, { suggestion }) {
    this.props.changeField({ name, value: suggestion.text });

    const idName = name.replace(/industryLabel$/, 'pivot.industryId');

    this.props.changeField({ name: idName, value: suggestion.id });
  }

  onFetchTitles(data) {
    const { id, value } = data;

    this.props.findTitles({ filter: value, positionId: id });
  }

  onSelectTitle(name, e, { suggestion }) {
    this.props.changeField({ name, value: suggestion.text });
  }

  onFetchProjects({ fundId, value }) {
    this.props.findProjects({ fundId, filter: value });
  }

  onSelectProject(name, e, { suggestion } = {}) {
    if (!suggestion) return;
    this.props.changeField({ name, value: suggestion.text });

    const idName = name.split('.');

    idName.pop();
    idName.push('eprojectId');
    this.props.changeField({ name: idName, value: suggestion.id });
  }

  getIndustryProp() {
    this.___IndustryProps___ = this.___IndustryProps___ || {
      onFetch: this.onFetchIndustries,
      onUpdate: this.onUpdateSuggestions,
      getValue: ContactExecutiveContainer.onGetValue,
      onSelect: this.onSelectIndustry,
    };

    return this.___IndustryProps___;
  }

  getTitlesProp() {
    this.___titlesProps___ = this.___titlesProps___ || {
      onFetch: this.onFetchTitles,
      onUpdate: this.onUpdateSuggestions,
      getValue: ContactExecutiveContainer.onGetValue,
      onSelect: this.onSelectTitle,
    };

    return this.___titlesProps___;
  }

  getProjectProp() {
    this.___projectSelector___ =
      this.___projectSelector___ ||
      createSelector(
        () => this.props.contact.get('suggests'),
        suggests => ({
          suggests,
          onFetch: this.onFetchProjects,
          onUpdate: this.onUpdateSuggestions,
          getValue: ContactExecutiveContainer.onGetValue,
          onSelect: this.onSelectProject,
        }),
      );

    return this.___projectSelector___(this.props);
  }

  highestFundIndex(contact) {
    let index = 0;
    let max = 0;

    contact.get('funds').forEach((f, i) => {
      const val = getIn(f, 'highStatus');

      if (max < val) {
        max = val;
        index = i;
      }
    });

    return index;
  }

  render() {
    if (this.props.loading || !this.props.isExecutive) {
      return null;
    }

    const {
      emails,
      dealNotes,
      isExecutive,
      contact,
      statuses,
      qualities,
      interests,
      relations,
      qualitiesOfExecutive,
    } = this.props;
    const suggestDirector = this.getSuggest('suggestDirector');
    const suggestAnalyst = this.getSuggest('suggestAnalyst');
    const suggestDealmaker = this.getSuggest('suggestDealmaker');
    const suggestResearcher = this.getSuggest('suggestResearcher');

    const suggestIndustry = this.getIndustryProp();
    const suggestTitles = this.getTitlesProp();
    const suggestProject = this.getProjectProp();

    const loading = this.props.contact.get('loading');
    const highest = this.highestFundIndex(contact);

    return (
      <ContactExecutive
        activateFund={this.activateFund}
        addFund={this.addFund}
        addIndustry={this.addIndustry}
        addPosition={this.addPosition}
        addTag={this.addTag}
        contact={contact}
        dealNotes={dealNotes}
        delFund={this.delFund}
        delIndustry={this.delIndustry}
        delPosition={this.delPosition}
        delTag={this.delTag}
        emails={emails}
        highestFundIndex={highest}
        interests={interests}
        isExecutive={isExecutive}
        loading={loading}
        onBlur={this.onBlur}
        onBlurDetails={this.onBlurDetails}
        onBlurFund={this.onBlurFund}
        onBlurPosition={this.onBlurPosition}
        onChange={this.onChange}
        onClickIndustries={this.onClickIndustries}
        onClickTags={this.onClickTags}
        onDialNotesMouseEnter={this.onDialNotesMouseEnter}
        onDialNotesMouseLeave={this.onDialNotesMouseLeave}
        onEmailMouseEnter={this.onEmailMouseEnter}
        onEmailMouseLeave={this.onEmailMouseLeave}
        onSubmit={ContactExecutiveContainer.onSubmit}
        onSubmitDetails={ContactExecutiveContainer.onSubmitDetails}
        qualities={qualities}
        qualitiesOfExecutive={qualitiesOfExecutive}
        relations={relations}
        statuses={statuses}
        submitFund={ContactExecutiveContainer.submitFund}
        submitPosition={ContactExecutiveContainer.submitPosition}
        suggestAnalyst={suggestAnalyst}
        suggestDealmaker={suggestDealmaker}
        suggestDirector={suggestDirector}
        suggestIndustry={suggestIndustry}
        suggestProject={suggestProject}
        suggestResearcher={suggestResearcher}
        suggestTitles={suggestTitles}
        triggerFund={this.triggerFund}
        triggerPosition={this.triggerPosition}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    inputErrors: state.contact.executive.get('inputErrors'),
    emails: state.contact.events.get('emails'),
    dealNotes: state.contact.events.get('dealNotes'),
    loading: state.contact.info.get('loading'),
    isExecutive: state.contact.info.get('isExecutive'),
    contact: state.contact.executive,
    loadingTitle: state.contact.executive.getIn(['newPosition', 'titleLoading'], false),
    statuses: state.statuses.get('executiveStatuses'),
    qualities,
    interests,
    relations,
    qualitiesOfExecutive,
  };
}

ContactExecutiveContainer.propTypes = {
  changeField: PropTypes.func.isRequired,
  contact: PropTypes.instanceOf(Map).isRequired,
  dealNotes: PropTypes.instanceOf(List).isRequired,
  emails: PropTypes.instanceOf(List).isRequired,
  fetchExecutivesStatuses: PropTypes.func.isRequired,
  interests: PropTypes.instanceOf(List).isRequired,
  loadContactExecutive: PropTypes.func.isRequired,
  qualities: PropTypes.instanceOf(List).isRequired,
  qualitiesOfExecutive: PropTypes.instanceOf(List).isRequired,
  relations: PropTypes.instanceOf(List).isRequired,
  statuses: PropTypes.instanceOf(Object).isRequired,
};

ContactExecutiveContainer.contextTypes = {
  hasPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
};

ContactExecutiveContainer.childContextTypes = {
  onErrorClose: PropTypes.func.isRequired,
  inputErrors: PropTypes.instanceOf(Map),
  addNewIndustry: PropTypes.func.isRequired,
};

export { ContactExecutiveContainer };
export default connect(
  mapStateToProps,
  {
    loadContactExecutive,
    changeField,
    findUsers,
    clearSuggest,
    enterContactDealNote,
    leaveContactDealNote,
    enterContactEmail,
    leaveContactEmail,
    triggerAccordion,
    delContactFund,
    activateContactFund,
    saveContactFund,
    saveContactPosition,
    saveContactExecutive,
    saveContactIndustries,
    addContactIndustry,
    delContactIndustry,
    saveContactTags,
    addContactTag,
    delContactTag,
    delContactPosition,
    setFieldOnOne,
    findTitles,
    findIndustries,
    findProjects,
    closeValidationError,
    updateIndustryTags,
    push,
    fetchExecutivesStatuses,
  },
  mergeProps,
  connectOptions,
)(ContactExecutiveContainer);
