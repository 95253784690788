import React from 'react';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';

import uniqueId from '../../utils/uniqueId';

const ContextMenuWrapper = (onDeleteChild, onDeleteParent, onEdit, isSuper = false) => {
  const ContextMenuWrapped = (value, data) => {
    const id = uniqueId();
    const tmp = data.toJS();
    const delEl = tmp.isParent ? (
      <MenuItem data={tmp} disable={!isSuper} onClick={onDeleteParent}>
        <i className="fa fa-remove" /> Delete
      </MenuItem>
    ) : (
      <MenuItem data={tmp} disable={!isSuper} onClick={onDeleteChild}>
        <i className="fa fa-remove" /> Delete
      </MenuItem>
    );

    return (
      <div>
        <ContextMenuTrigger disable={!isSuper} id={id}>
          <div>{value}</div>
        </ContextMenuTrigger>
        <ContextMenu id={id}>
          <MenuItem data={tmp} disable={!isSuper} onClick={onEdit}>
            <i className="fa fa-pencil" /> Edit
          </MenuItem>
          {delEl}
        </ContextMenu>
      </div>
    );
  };

  return ContextMenuWrapped;
};

export default ContextMenuWrapper;
