import React, { memo } from 'react';
import { uniqueId } from 'underscore';
import { Input } from 'src/components/helpers/Input';

const CriteriaRangeComponent = ({ buyer: root, label, name, onChange, disabled }) => {
  const id = uniqueId();
  const [highName, lowName] = ['High', 'Low'].map(post => `${name}${post}`);

  return (
    <div className="form-group">
      <label htmlFor={id}>{label}</label>
      <div className="row">
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div className="i-dash">
            <Input
              className="input-sm"
              disabled={disabled}
              name={lowName}
              onChange={onChange}
              placeholder=""
              root={root}
              deep
            />
          </div>
        </div>
        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <Input
            className="input-sm"
            disabled={disabled}
            name={highName}
            onChange={onChange}
            placeholder=""
            root={root}
            deep
          />
        </div>
      </div>
    </div>
  );
}

/** Criteria range component. */
export const CriteriaRange = memo(CriteriaRangeComponent);
