import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import Immutable from 'immutable';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

import config from '../config';
import { showError } from '../utils/MessagePopup';
import { isResearcher } from '../utils/checkPermissions';
import { TagManagement } from '../components/TagManagement';
import { fetchTags, update, deleteTag, lookup, saveTag, sortTag } from '../actions/tag';
import confirm from './decorators/confirm';
import downloadFile from '../utils/downloadFile';

class TagManagementContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.handleDelete = confirm('Delete this category?', this.deleteEvent.bind(this), context);
    this.handleDeleteParent = confirm(
      'WARNING: You are about to delete a Broad Industry Category. Doing so will also delete all Sub' +
        'Categories, End-Markets, Themes, and Business Functions categorized under this Broad Industry Category.' +
        'Are you sure?',
      this.deleteEvent.bind(this),
      context,
    );
    this.handleEdit = this.handleEdit.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.resetState = this.resetState.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.fetch = this.fetch.bind(this);
    this.isParentAvailable = this.isParentAvailable.bind(this);
    this.state = {
      mode: 'view',
      id: '',
      category: '',
      parentId: '',
      tagTypeId: '',
      error: [],
      showParent: false,
    };
    this.excludeIds = [5, 6]; // exclude Business Model, Broad Industry Category
  }

  componentDidMount() {
    if (!this.hasAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });
    } else {
      this.fetch();
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  fetch() {
    this.props.fetchTags().then(result => this.props.lookup(result[1].tagTypes, result[2].data));
  }

  deleteEvent(event, data) {
    this.props.deleteTag(data.id);
    this.context.closePopup();
  }

  handleEdit(event, data) {
    this.setState({
      mode: 'edit',
      id: data.id,
      category: data.category,
      parentId: data.parentId,
      tagTypeId: data.tagTypeId,
      error: [],
      showParent: this.isParentAvailable(data.tagTypeId),
    });
  }

  handleAdd() {
    this.setState({
      mode: 'add',
      id: '',
      category: '',
      parentId: '',
      tagTypeId: '',
      error: [],
      showParent: false,
    });
  }

  handleExport() {
    const url = `${config.API_BASE_URL}/api/v1/industry_categories/export/`;

    downloadFile({ url });
  }

  handleCancel() {
    this.resetState();
  }

  handleSave() {
    const { category, parentId, tagTypeId, mode, id } = this.state;
    const error = [];

    if (!category) {
      error.push('Category name can not be empty');
    }

    if (!tagTypeId) {
      error.push('Tag type can not be empty');
    } else if (this.isParentAvailable(tagTypeId) && !parentId) {
      error.push('Parent category is required');
    }

    if (error.length > 0) {
      this.setState({ error });

      return;
    }

    const body = {
      category,
      parentId: parentId || undefined,
      tagTypeId,
      afterSuccess: () => {
        this.resetState();
        this.fetch();
      },
      afterError: body => {
        try {
          this.setState({
            error: Object.entries(body.resBody.errors).map(([val]) => val),
          });
        } catch (e) {
          this.setState({ error: ['Unknown error'] });
        }
      },
    };

    if (mode === 'edit') {
      body.id = id;
    }
    this.props.saveTag(body);
  }

  handleChange({ target }) {
    const { name, value } = target;

    this.setState({
      [name]: value,
      error: [],
    });
    if (name === 'tagTypeId') {
      this.setState({
        showParent: this.isParentAvailable(value),
      });
    }
  }

  handleClick(name) {
    this.props.sortTag(name);
  }

  isParentAvailable(tagTypeId) {
    if (!tagTypeId || isNaN(tagTypeId)) {
      return false;
    }

    return this.excludeIds.indexOf(parseInt(tagTypeId, 10)) < 0;
  }

  resetState() {
    this.setState({
      mode: 'view',
      id: '',
      category: '',
      parentId: '',
      tagTypeId: '',
      error: [],
      showParent: false,
    });
  }

  render() {
    if (!this.hasAccess()) return null;

    const slug = this.context.currentUser.getIn(['roles', 0, 'slug'], null);

    return (
      <div className="full-height">
        <Helmet title="Tag Management Page" />
        <TagManagement
          {...this.props}
          {...this.state}
          isSuper={slug === config.ADMINISTRATOR}
          onAdd={this.handleAdd}
          onCancel={this.handleCancel}
          onChange={this.handleChange}
          onClick={this.handleClick}
          onDelete={this.handleDelete}
          onDeleteParent={this.handleDeleteParent}
          onEdit={this.handleEdit}
          onExport={this.handleExport}
          onSave={this.handleSave}
        />
      </div>
    );
  }
}

TagManagementContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Immutable.Map).isRequired,
};

const mapStateToProps = state => ({
  loading: state.tags.get('loading') || state.tags.get('parentTagsLoading') || state.tags.get('typesLoading'),
  types: state.tags.get('types'),
  tags: state.tags.get('tags'),
  sort: state.tags.get('sort'),
  parents: state.tags.get('parents'),
});

export default connect(mapStateToProps, {
  fetchTags,
  update,
  deleteTag,
  lookup,
  saveTag,
  sortTag,
})(TagManagementContainer);
