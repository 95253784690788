import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState, memo } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { Map } from 'immutable';
import classNames from 'classnames';

import uniqueId from '../utils/uniqueId';
import { unwrap, isChanged } from '../utils/ChangeSpy';
import { fetch, fullLoad } from '../actions/country';

const CountryDropDown = ({
  countries,
  fetch,
  fullLoad,
  name,
  onChange,
  onSelect,
  className = '',
  value = '',
  disabled = false,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const id = uniqueId();

  useEffect(() => {
    fetch();
  }, [fetch]);

  useEffect(() => {
    const totalPages = countries.getIn(['pagination', 'totalPages']);
    const currentPage = countries.getIn(['pagination', 'currentPage'], 1);
    const loaded = countries.get('loaded');

    if (!loaded) {
      if (currentPage >= totalPages) {
        fullLoad();
      } else {
        fetch(currentPage + 1);
      }
    }
  }, [countries, fetch, fullLoad]);

  const handleSuggestionSelected = useCallback((event, { suggestion }) => {
    if (onSelect) {
      onSelect(suggestion);
    }
  }, [onSelect]);

  const renderSuggestion = useCallback(suggestion => <div>{suggestion.text}</div>, []);

  const handleSuggestionsFetchRequested = useCallback(({ value }) => {
    if (value) {
      const upperValue = value.toUpperCase();
      setSuggestions(
        countries
          .get('data')
          .filter(country => country.get('text').toUpperCase().includes(upperValue))
          .toJS()
      );
    }
  }, [countries]);

  const handleSuggestionsClearRequested = useCallback(() => {
    setSuggestions([]);
  }, []);

  const getSuggestionValue = useCallback(suggestion => suggestion.id, []);

  const handleChange = useCallback((event, { method }) => {
    if (onChange && method === 'type') {
      onChange(event);
    }
  }, [onChange]);

  const handleBlur = useCallback((event, { highlightedSuggestion }) => {
    if (highlightedSuggestion) {
      setValue(highlightedSuggestion.id, highlightedSuggestion.text);
    } else {
      const value = (event.target.value || '').toUpperCase();
      const index = countries
        .get('data')
        .findIndex(c => c.get('text').toUpperCase() === value);

      if (index >= 0) {
        setValue(countries.getIn(['data', index, 'id']), countries.getIn(['data', index, 'text']));
      } else {
        setValue();
      }
    }
  }, [countries]);

  const setValue = useCallback((id, text) => {
    if (onSelect) {
      onSelect(id > 0 ? { id, text } : { id: null, text: '' });
    }
  }, [onSelect]);

  let realValue = value;
  let changed = null;

  if (Map.isMap(value)) {
    realValue = unwrap(value);
    changed = isChanged(value);
  }

  const inputProps = {
    className: classNames(className, 'form-control', { changed }),
    id,
    name,
    disabled,
    placeholder: 'Country',
    value: realValue,
    onChange: handleChange,
    onBlur: handleBlur,
  };

  return (
    <Autosuggest
      getSuggestionValue={getSuggestionValue}
      inputProps={inputProps}
      onSuggestionsClearRequested={handleSuggestionsClearRequested}
      onSuggestionSelected={handleSuggestionSelected}
      onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
      renderSuggestion={renderSuggestion}
      suggestions={suggestions}
      highlightFirstSuggestion
    />
  );
};

CountryDropDown.propTypes = {
  countries: PropTypes.instanceOf(Map).isRequired,
  fetch: PropTypes.func.isRequired,
  fullLoad: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Map)]),
  disabled: PropTypes.bool,
};

const mapStateToProps = (state, props) => ({
  ...props,
  countries: state.countries,
});

export default memo(connect(mapStateToProps, {
  fetch,
  fullLoad,
})(CountryDropDown));
