import React, { memo } from 'react';

const DateRender = ({ value }) => {
  const format = date => {
    return (date && date.isValid() && date.format('MM/DD/YYYY')) || '';
  }

  const dateDisplay = format(value);

  return <span>{dateDisplay}</span>;
}

export default memo(DateRender);
