import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import Input, { InlineInput } from '../../helpers/Input';
import config from '../../../config';
import { unwrap } from '../../../utils/ChangeSpy';
import getPercent from '../../../utils/getPercent';

/**
 * Row in edit mode component.
 *
 * @param props {Object}.
 * @param props.row {Immutable.Map} Row information.
 * @param props.onChange {Function} Function to handle changing row information.
 * @returns {React.Component}
 */
const EditComponent = ({ row, onChange }) => {
  const type = config.values.getIn(['project', 'types', 'APPROVALS']);
  const numberOfContacted = row.get('numberOfContacted');
  const numberOfLeads = row.get('numberOfLeads');
  const numberOfCc = row.get('numberOfCc');
  const numberOfVisit = row.get('numberOfVisit');
  const numberOfNextActions = row.get('numberOfNextActions');
  const numberTargets = row.get('numberTargets');
  const numberOfPriorityA = row.get('numberOfPriorityA');
  const numberOfPriorityB = row.get('numberOfPriorityB');
  const numberOfPriorityC = row.get('numberOfPriorityC');
  const numberOfApprovedX = row.get('numberOfApprovedX');

  return (
    <tr
      onClick={event => {
        event.stopPropagation();
      }}
    >
      <td className="cell">
        <div>
          <Input
            label="Approval List"
            name="applistLabel"
            onChange={event => onChange({ event, data: { row, type } })}
            placeholder="approval list"
            value={unwrap(row.get('applistLabel'))}
            autoFocus
          />
        </div>
      </td>
      <td className="cell">
        <div>
          <InlineInput
            name="dateSent"
            onChange={event => onChange({ event, data: { row, type } })}
            placeholder="date"
            type="date"
            value={unwrap(row.get('dateSent'))}
          />
        </div>
      </td>
      <td className="cell">
        <div>
          <InlineInput
            name="dateReceived"
            onChange={event => onChange({ event, data: { row, type } })}
            placeholder="date"
            type="date"
            value={unwrap(row.get('dateReceived'))}
          />
        </div>
      </td>
      <td className="cell">
        <div>{numberTargets}</div>
      </td>
      <td className="cell">
        <div>
          {row.get('numberApproved')} ({row.get('percentApproved')}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfContacted} ({getPercent(numberOfContacted, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfLeads} ({getPercent(numberOfLeads, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfCc} ({getPercent(numberOfCc, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfVisit} ({getPercent(numberOfVisit, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfNextActions} ({getPercent(numberOfNextActions, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfPriorityA} ({getPercent(numberOfPriorityA, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfPriorityB} ({getPercent(numberOfPriorityB, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfPriorityC} ({getPercent(numberOfPriorityC, numberTargets)}%)
        </div>
      </td>
      <td className="cell">
        <div>
          {numberOfApprovedX} ({getPercent(numberOfApprovedX, numberTargets)}%)
        </div>
      </td>
    </tr>
  );
};

EditComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export const Edit = memo(EditComponent);
