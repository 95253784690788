import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import { TopPanel } from './TopPanel';
import { MidPanel } from './MidPanel';
import { TargetTable } from './TargetTable';
import { Footer } from './Footer';

/**
 * Project component.
 *
 * @param props {Object}.
 * @param props.projectData {Immutable.Map} Project data.
 * @param props.users {Immutable.List} User list.
 * @param props.industries {Immutable.List} Industry list.
 * @param props.approvals {Immutable.List} Approval list.
 * @param props.templates {Immutable.List} Template list.
 * @param props.targets {Immutable.List} Target list.
 * @param props.common {Immutable.Map} Common object.
 * @param props.targetColumns {Immutable.List} Target column configurations.
 * @param props.approvalColumns {Immutable.List} Approval column configurations.
 * @param props.savePending {Boolean} Allow to click saving button if true.
 * @param props.onRowClick {Function} Handle event of clicking on a row.
 * @param props.onRowDoubleClick {Function} Handle event of double clicking on a row.
 * @param props.onRowInsert {Function} Handle event of adding a new row.
 * @param props.onDeleteRow {Function} Handle event of deleting a row.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @param props.onSuggestionClose {Function} Handle event of closing suggestion.
 * @param props.onSuggestionSelect {Function} Handle event of selecting a suggestion.
 * @param props.onReset {Function} Handle event of reset all tables to view mode.
 * @param props.onProjectInputChange {Function} Handle event of changing project input's text.
 * @param props.onSuggestionInputClick {Function} Handle event of clicking on project input.
 * @param props.onSort {Function} Handle event of sorting table.
 * @param props.onReload {Function} Handle event of reloading page.
 * @param props.onSave {Function} Handle event of saving page.
 * @param props.onExport {Function} Handle event of exporting project information.
 * @param props.onDownload {Function} Handle event of downloading template file.
 * @param props.onTemplateUpdate {Function} Handle event of updating file name.
 * @param props.onKeyPress {Function} Handle event of key pressing.
 * @returns {React.Component}
 * @constructor
 */
const ProjectComponent = ({
  approvalColumns,
  projectData,
  users,
  industries,
  approvals,
  noApprovals,
  templates,
  targets,
  common,
  targetColumns,
  savePending,
  onRowClick,
  onRowDoubleClick,
  onRowInsert,
  onDeleteRow,
  onDeleteApprovalRow,
  onTextChange,
  onSuggestionClose,
  onSuggestionSelect,
  onReset,
  onProjectInputChange,
  onSuggestionInputClick,
  onSort,
  onReload,
  onSave,
  onExport,
  onDownload,
  onTemplateUpdate,
  onKeyPress,
  onChange,
  onSubmit,
  onFocus,
  onTableRef,
  onNoApprovalDoubleClick,
}) => {
  return (
    <div className="project__container" onClick={onReset}>
      <div className="container">
        <div className="row full-height">
          <div className="container-fluid">
            <TopPanel
              common={common}
              onDeleteApprovalRow={onDeleteApprovalRow}
              onDeleteRow={onDeleteRow}
              onDownload={onDownload}
              onFocus={onFocus}
              onKeyPress={onKeyPress}
              onProjectInputChange={onProjectInputChange}
              onRowClick={onRowClick}
              onRowDoubleClick={onRowDoubleClick}
              onRowInsert={onRowInsert}
              onSuggestionClose={onSuggestionClose}
              onSuggestionInputClick={onSuggestionInputClick}
              onSuggestionSelect={onSuggestionSelect}
              onTemplateUpdate={onTemplateUpdate}
              onTextChange={onTextChange}
              projectData={projectData}
              templates={templates}
              users={users}
            />
            <MidPanel
              approvalColumns={approvalColumns}
              approvals={approvals}
              common={common}
              industries={industries}
              noApprovals={noApprovals}
              onDeleteApprovalRow={onDeleteApprovalRow}
              onDeleteRow={onDeleteRow}
              onDownload={onDownload}
              onKeyPress={onKeyPress}
              onNoApprovalDoubleClick={onNoApprovalDoubleClick}
              onRowChange={onTextChange}
              onRowClick={onRowClick}
              onRowDoubleClick={onRowDoubleClick}
              onRowInsert={onRowInsert}
              onSort={onSort}
              projectData={projectData}
            />
            <TargetTable
              common={common}
              onChange={onChange}
              onDoubleClick={onRowDoubleClick}
              onSort={onSort}
              onSubmit={onSubmit}
              onTableRef={onTableRef}
              projectData={projectData}
              targetColumns={targetColumns}
              targets={targets}
            />
          </div>
        </div>
      </div>
      <Footer onExport={onExport} onReload={onReload} onSave={onSave} savePending={savePending} />
    </div>
  );
};

ProjectComponent.propTypes = {
  approvalColumns: PropTypes.instanceOf(Immutable.List).isRequired,
  approvals: PropTypes.instanceOf(Immutable.List).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  industries: PropTypes.instanceOf(Immutable.List).isRequired,
  noApprovals: PropTypes.instanceOf(Immutable.List).isRequired,
  onDeleteRow: PropTypes.func.isRequired,
  onDownload: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  onProjectInputChange: PropTypes.func.isRequired,
  onReload: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  onRowClick: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onRowInsert: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
  onSuggestionClose: PropTypes.func,
  onSuggestionInputClick: PropTypes.func.isRequired,
  onSuggestionSelect: PropTypes.func,
  onTemplateUpdate: PropTypes.func.isRequired,
  onTextChange: PropTypes.func,
  projectData: PropTypes.instanceOf(Immutable.Map).isRequired,
  savePending: PropTypes.bool.isRequired,
  targetColumns: PropTypes.instanceOf(Immutable.List).isRequired,
  targets: PropTypes.instanceOf(Immutable.List).isRequired,
  templates: PropTypes.instanceOf(Immutable.List).isRequired,
  users: PropTypes.instanceOf(Immutable.List).isRequired,
};

export const Project = memo(ProjectComponent);
