import PropTypes from 'prop-types';
import React, { memo } from 'react';

import LoadAnimation from '../../decorators/LoadAnimation';

const Content = LoadAnimation(
  props => {
    const { children } = props;

    return <div className="list-group search-items">{children}</div>;
  },
  undefined,
  'absolute-loading',
);

/**
 * Stateless component for result grid.
 *
 * @param props {Object}.
 * @param props.id {function} Id of grid container.
 * @param props.loading {function} Indicate grid is in loading state.
 * @param props.isActive {function} Indicate that the grid is currently active.
 * @param props.children {any} Children elements.
 * @returns {React.Component}
 * @class
 */
const ResultGridComponent = ({ id, isActive, children, loading }) => {
  const classNames = `tab-pane ${isActive ? 'active' : ''}`;

  return (
    <div className={classNames} id={id} role="tabpanel">
      <Content loading={loading}>{children}</Content>
    </div>
  );
};

ResultGridComponent.propTypes = {
  children: PropTypes.any,
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

export const ResultGrid = memo(ResultGridComponent);
