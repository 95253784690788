import PropTypes from 'prop-types';

import React, { memo } from 'react';
import Button from '../../helpers/Button';

/**
 * Top section component.
 *
 * @param props {Object}.
 * @param props.disableSetNA {Boolean} If set to true, will disable set next action button.
 * @param props.disableChangeStatus {Boolean} If set to true, will disable change status popup.
 * @param {boolean} props.isGenerateButtonDisabled Whether generate online approval list is disabled or not.
 * @param props.runWindwardReport {Function} Open Windward modal window.
 * @param props.generateOnlineApprovalList {Function} Generate Online Approval List modal window.
 * @param props.onSetAction {Function} To open set action popup.
 * @param props.onChangeStatus {Function} To open change status popup.
 * @param props.onExport {Function} To explore associate list.
 * @param {boolean} props.isNoApproval No approval list selected state.
 * @returns {React.Component}
 * @class
 */
const ButtonsComponent = ({
  disableSetNA = false,
  disableChangeStatus = false,
  isGenerateButtonDisabled,
  runWindwardReport,
  generateOnlineApprovalList,
  onSetAction,
  onChangeStatus,
  onExport,
  isNoApproval,
}) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="pull-right">
          {isNoApproval === false && (
            <Button
              className="btn-default btn-xs mr5"
              disabled={isGenerateButtonDisabled}
              onClick={generateOnlineApprovalList}
            >
              Generate Online Approval List
            </Button>
          )}
          <Button className="btn-default btn-xs mr5" onClick={runWindwardReport}>
            Run Windward Report
          </Button>
          <Button className="btn-default btn-xs mr5" disabled={disableSetNA} onClick={onSetAction}>
            Set Next Actions
          </Button>
          <Button className="btn-default btn-xs mr5" disabled={disableChangeStatus} onClick={onChangeStatus}>
            Change Statuses
          </Button>
          <Button className="btn-default btn-xs mr5" onClick={onExport}>
            <i aria-hidden="true" className="fa fa-reply" /> Export
          </Button>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
};

ButtonsComponent.propTypes = {
  disableChangeStatus: PropTypes.bool,
  disableSetNA: PropTypes.bool,
  generateOnlineApprovalList: PropTypes.func.isRequired,
  isGenerateButtonDisabled: PropTypes.bool,
  isNoApproval: PropTypes.bool.isRequired,
  onChangeStatus: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
  onSetAction: PropTypes.func.isRequired,
  runWindwardReport: PropTypes.func.isRequired,
};

/** Buttons component. */
export const Buttons = memo(ButtonsComponent);
