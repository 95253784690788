import React, { memo } from 'react';

const ErrorContentComponent = ({ children, onClose }) => (
  <div className="alert alert-danger alert-dismissible input-custom-alert" role="alert">
    <button aria-label="Close" className="close" onClick={onClose} type="button">
      <span aria-hidden="true">&times;</span>
    </button>
    {children}
  </div>
);

export const ErrorContent = memo(ErrorContentComponent);
