import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Map } from 'immutable';

import { BrowseCheckbox } from './BrowseCheckbox';

const BrowseEmployeeInputRangeComponent = ({info, filter, onChange}) => {
  const text = {
    min: info.getIn(['text', 'min']),
    max: info.getIn(['text', 'max']),
  };

  const handleChange = event => {
    const { name, value } = event.target;

    if (isNaN(value)) {
      event.preventDefault();

      return false;
    }
    onChange({
      filterName: filter.get('name'),
      filterData: {
        text: {
          [name]: value,
        },
      },
    });
  }

  const handleBlur = event => {
    const { name, value } = event.target;
    let updateValue = Math.round(value * 100) / 100;
    const min = parseInt(info.getIn(['text', 'min']), 10);
    const max = parseInt(info.getIn(['text', 'max']), 10);

    if (name === 'min') {
      if (updateValue > max) {
        updateValue = max;
      } else if (updateValue < 0) {
        updateValue = 0;
      }
    } else if (name === 'max') {
      if (updateValue < min) {
        updateValue = min;
      }
    }
    onChange({
      filterName: filter.get('name'),
      filterData: {
        text: {
          [name]: updateValue,
        },
      },
    });
  }

    return (
      <BrowseCheckbox filter={filter} info={info} onChange={onChange}>
        <div className="BrowseControl-content BrowseControl-content--slider">
          <div className="form-inline">
            <div className="form-group">
              <input
                className="form-control"
                name="min"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={text.min}
              />
            </div>
            <div className="form-group">&nbsp;-&nbsp;</div>
            <div className="form-group">
              <input
                className="form-control"
                name="max"
                onBlur={handleBlur}
                onChange={handleChange}
                type="text"
                value={text.max}
              />
            </div>
          </div>
        </div>
      </BrowseCheckbox>
    );
  }

  BrowseEmployeeInputRangeComponent.propTypes = {
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
};

/** Browse employee input range component. */
export const BrowseEmployeeInputRange = memo(BrowseEmployeeInputRangeComponent);
