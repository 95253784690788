import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { List } from 'immutable';

import { ContextMenu, ContextMenuTrigger, MenuItem } from 'react-contextmenu';
import uniqueId from '../../utils/uniqueId';

class ContextTriggerCellRenderer extends PureComponent {
  constructor(props) {
    super(props);
    this.collect = this.collect.bind(this);
  }

  collect() {
    return {
      rowIndex: this.props.rowIndex,
      rowData: this.props.data,
    };
  }

  render() {
    return (
      <ContextMenuTrigger collect={this.collect} id={this.props.colDef.contextMenuId}>
        {this.props.value.value}
      </ContextMenuTrigger>
    );
  }
}

const setRowsStyles = params => {
  if (params.node.rowPinned) {
    return {
      'font-weight': 'bold',
      'border-top': '2px solid #000',
    };
  }
};

class AgGrid extends PureComponent {
  constructor(props) {
    super(props);

    this.api = null;
    this.contextMenu = null;
    this.handleGridReady = this.handleGridReady.bind(this);
    this.handleBodyScroll = this.handleBodyScroll.bind(this);
    this.handleCellContextMenu = this.handleCellContextMenu.bind(this);
    this.showLoading = this.showLoading.bind(this);
    this.hideLoading = this.hideLoading.bind(this);
    this.handleSortChanged = this.handleSortChanged.bind(this);
    this.updateColumnDefs = this.updateColumnDefs.bind(this);
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.contextMenuId = uniqueId();
    this.tableRef = React.createRef();
  }

  componentDidUpdate() {
    if (this.props.isFetching) {
      this.showLoading();
    } else {
      this.hideLoading();
    }
  }

  handleBodyScroll(event) {
    const agTable = this.tableRef.current.querySelector('.ag-full-width-container');
    const top = Math.abs(parseInt(event.top, 10));
    const height = parseInt(agTable.style.height, 10);

    if (top < height - 2048) return;
    if (this.props.totalPages <= this.props.page) return;
    if (this.props.isFetching) return;
    if (this.props.onGetNextPageData) {
      this.props.onGetNextPageData(this.props.page + 1);
    }
  }

  showLoading() {
    if (this.api) {
      this.api.showLoadingOverlay();
    }
  }

  hideLoading() {
    if (this.api) {
      this.api.hideOverlay();
      if (!this.props.rowData || this.props.rowData.size === 0) {
        this.api.showNoRowsOverlay();
      }
    }
  }

  handleGridReady(params) {
    this.api = params.api;

    if (this.props.isFetching) {
      this.showLoading();
    }

    if (this.props.onGridReady) {
      this.props.onGridReady(params);
    }
  }

  handleSortChanged({ columnApi }) {
    if (this.props.onSortModelChanged) {
      const value = columnApi.getColumnState().filter(column => column.sort != null);

      this.props.onSortModelChanged(value);
    }
  }

  handleCellContextMenu(cell) {
    if (this.props.onContextMenuClick) {
      cell.event.preventDefault();
    }
  }

  updateColumnDefs() {
    const { onContextMenuClick, contextActionList, sortable, resizable, sort } = this.props;
    let columnDefs = this.props.columnDefs.toJS();

    columnDefs.forEach(colDef => {
      if (!colDef.comparator) {
        colDef.comparator = () => {};
      }

      if (colDef.headerName !== '# ') {
        colDef.sortable = sortable;
      }

      colDef.resizable = resizable;
      colDef.sort = sort || null;
    });

    if (onContextMenuClick) {
      this.contextMenu = onContextMenuClick ? (
        <ContextMenu id={this.contextMenuId}>
          {contextActionList.map((a, i) => (
            <MenuItem key={i} data={{ key: a.get('key') }} onClick={onContextMenuClick}>
              {a.get('name')}
            </MenuItem>
          ))}
        </ContextMenu>
      ) : null;

      if (onContextMenuClick) {
        columnDefs = columnDefs.map(columnDef => {
          columnDef.cellRendererFramework = columnDef.cellRendererFramework || ContextTriggerCellRenderer;
          columnDef.contextMenuId = this.contextMenuId;

          return columnDef;
        });
      }
    }

    return columnDefs;
  }

  getRowNodeId(data) {
    if (this.props.getRowNodeId) {
      return this.props.getRowNodeId(data);
    }

    return data.id || data.order || uniqueId();
  }

  getGridProps(props) {
    const keys = [
      'getRowClass',
      'onCellClicked',
      'onRowClicked',
      'onRowDoubleClicked',
      'onCellDoubleClicked',
      'onCellEditingStopped',
      'onColumnResized',
      'onCellValueChanged',
      'onFirstDataRendered',
      'onViewportChanged',
      'rowClassRules',
      'suppressCellSelection',
      'suppressNoRowsOverlay',
      'onRowEditingStopped',
      'onRowValueChanged',
      'onSelectionChanged',
      'editType',
      'headerHeight',
      'rowSelection',
      'getRowHeight',
      'rowHeight',
    ];

    return keys.reduce((prev, key) => {
      if (props[key]) {
        prev[key] = props[key];
      }

      return prev;
    }, {});
  }

  render() {
    const {
      rowData,
      columnDefs,
      page,
      totalPages,
      style,
      isFetching,
      resizable,
      sortable,
      enableClientSorting,
      suppressMovableColumns = true,
      ...rest
    } = this.props;

    const defs = this.updateColumnDefs();
    const data = rowData.toJS();
    const props = this.getGridProps(rest);

    return (
      <div ref={this.tableRef} className="ag-theme-fresh" style={style}>
        <AgGridReact
          {...props}
          columnDefs={defs}
          getRowNodeId={this.getRowNodeId}
          getRowStyle={params => setRowsStyles(params)}
          onBodyScroll={this.handleBodyScroll}
          onCellContextMenu={this.handleCellContextMenu}
          onGridReady={this.handleGridReady}
          onSortChanged={this.handleSortChanged}
          overlayLoadingTemplate='<span class="loading-row"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></span>'
          rowData={data}
          suppressMovableColumns={suppressMovableColumns}
          reactNext
          suppressModelUpdateAfterUpdateTransaction
        />
        {this.contextMenu}
      </div>
    );
  }
}

AgGrid.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  getRowClass: PropTypes.func,
  isFetching: PropTypes.bool,
  onContextMenuClick: PropTypes.func,
  onGetNextPageData: PropTypes.func,
  onGridReady: PropTypes.func,
  page: PropTypes.number,
  resizable: PropTypes.bool,
  rowClassRules: PropTypes.instanceOf(Object),
  rowData: PropTypes.instanceOf(List).isRequired,
  sortable: PropTypes.bool,

  style: PropTypes.object,
  totalPages: PropTypes.number,
};

AgGrid.defaultProps = {
  rowData: List(),
  columnDefs: List(),
  page: 1,
  totalPages: 0,
  style: {},
  isFetching: false,
  resizable: true,
  sortable: false,
  enableClientSorting: false,
};

export default AgGrid;
