import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import Spinner from '../../helpers/Spinner';
import { StatsRow } from './StatsRow';
import { Rows } from './Rows';
import { Columns } from './Columns';

const ApprovalsComponent = ({
  approvals,
  noApprovals,
  common,
  contextId,
  approvalColumns,
  onInsert,
  onChange,
  onClick,
  onDoubleClick,
  onSort,
  onNoApprovalDoubleClick,
}) => {
  const spinner = common.get('approvalLoading') ? <Spinner /> : null;

  const noData = () => {
    if (approvals.size === 0 && noApprovals.size === 0) {
      return <div className="table-message">No items to display</div>;
    }

    return null;
  };

  return (
    <div className="col-md-12">
      <div className="table-edit table-responsive">
        <table className="table table-striped table-condensed sort with-stats">
          <Columns columns={approvalColumns} common={common} onClick={onSort} onInsert={onInsert} />
          <Rows
            approvals={approvals}
            columns={approvalColumns}
            contextId={contextId}
            noApprovals={noApprovals}
            onChange={onChange}
            onClick={onClick}
            onDoubleClick={onDoubleClick}
            onNoApprovalDoubleClick={onNoApprovalDoubleClick}
          />
          {approvals.size > 0 && <StatsRow approvals={approvals} columns={approvalColumns} noApprovals={noApprovals} />}
        </table>
        {spinner}
        {noData()}
      </div>
    </div>
  );
};

ApprovalsComponent.propTypes = {
  approvalColumns: PropTypes.instanceOf(Immutable.List).isRequired,
  approvals: PropTypes.instanceOf(Immutable.List).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  noApprovals: PropTypes.instanceOf(Immutable.List).isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
  onSort: PropTypes.func.isRequired,
};

export const Approvals = memo(ApprovalsComponent);
