import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map, List } from 'immutable';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';

import connectOptions, { mergeProps } from '../utils/connectOptions';
import { Project } from '../components/Project';
import config from '../config';
import {
  loadPage,
  savePage,
  reset,
  updateProjectInfo,
  selectProjectSuggestion,
  clearError,
} from '../actions/project/main';
import {
  insertUserRow,
  switchUserRowToEdit,
  updateUserText,
  selectUserSuggestion,
  deleteUserRow,
} from '../actions/project/user';
import {
  insertIndustryRow,
  switchIndustryRowToEdit,
  updateIndustryText,
  selectIndustrySuggestion,
  deleteIndustryRow,
  updateIndustryTags,
  saveIndustries,
} from '../actions/project/industry';
import { changeLabel } from '../actions/project/industryPopup';
import { sortTarget, saveTarget, changeTarget, loadTargets, loadSortedTargets } from '../actions/project/target';
import { clearTemplatePopupError } from '../actions/project/templatePopup';
import {
  updateApprovals,
  insertApprovalRow,
  switchApprovalRowToEdit,
  sortApproval,
  deleteApproval,
} from '../actions/project/approval';
import {
  changeToEditMode,
  updateTemplateName,
  switchTemplateRowToEdit,
  saveTemplateName,
  deleteTemplateRow,
} from '../actions/project/template';
import detectDirty from '../components/Project/detectDirty';
import { openInternalLink } from '../utils/url';
import { showError } from '../utils/MessagePopup';
import { isChanged, getIn, isDeepChanged, getChanged } from '../utils/ChangeSpy';
import deletionCheck from '../utils/deletingPermission';
import { isResearcher } from '../utils/checkPermissions';

import If from '../components/helpers/If';
import deleteApprovalConfirmation from './services/deleteApprovalConfirmation';
import downloadFile from '../utils/downloadFile';

const types = config.values.getIn(['project', 'types']);

/**
 * State container component for project page.
 *
 * @param props {Object}.
 * @param loggedUser {Immutable.Map} Current logged user.
 * @param projectData {Immutable.Map} Project information.
 * @param industries {Immutable.List} Industry list.
 * @param users {Immutable.List} User list.
 * @param approvals {Immutable.List} Approval list.
 * @param templates {Immutable.List} Template list.
 * @param targets {Immutable.List} Target list.
 * @param common {Immutable.Map} Common object.
 * @param savePending {Boolean} Saving status.
 * @returns {React.Component}
 * @class
 */
class ProjectContainer extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.state = { lazyLoadingIsEnabled: true };

    this.apiBaseUrl = config.API_BASE_URL;
    this.targetColumns = config.tables.getIn(['project', 'target', 'columns']);
    this.approvalColumns = config.tables.getIn(['project', 'approval', 'columns']);

    this.handleTableRef = this.handleTableRef.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.handleRowClick = this.handleRowClick.bind(this);
    this.handleRowInsert = this.handleRowInsert.bind(this);
    this.handleRowDelete = deletionCheck(this.props.loggedUser, this.handleRowDelete.bind(this), context);
    this.handleSuggestionClose = this.handleSuggestionClose.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleSuggestionSelect = this.handleSuggestionSelect.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleProjectInputChange = this.handleProjectInputChange.bind(this);
    this.handleSuggestionInputClick = this.handleSuggestionInputClick.bind(this);
    this.handleSort = this.handleSort.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.handleExport = this.handleExport.bind(this);
    this.handleRowDoubleClick = this.handleRowDoubleClick.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
    this.handleTemplateUpdate = this.handleTemplateUpdate.bind(this);
    this.saveTemplateChange = this.saveTemplateChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleSomeChange = this.handleSomeChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleApprovalRowDelete = this.handleApprovalRowDelete.bind(this);
    this.handleNoApprovalDoubleClick = this.handleNoApprovalDoubleClick.bind(this);
    this.deleteApproval = deleteApprovalConfirmation({
      deleteApproval: this.deleteApprovalWrapper.bind(this),
      loggedUser: this.props.loggedUser,
      openPopup: this.context.openPopup,
      closePopup: this.context.closePopup,
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.tableContainer) {
      this.tableContainer.addEventListener('scroll', this.handleScroll);
    }
    document.querySelector('html').className = 'page--project';

    const {
      match: {
        params: { projectId },
      },
    } = this.props;

    if (this.hasAccess()) {
      this.props.loadPage(projectId);
    } else {
      showError(this.context.openPopup, config.permisionError, () => {
        this.props.history.push('/');
        this.context.closePopup();
      });
    }
  }

  componentDidUpdate() {
    this.showErrorsPopup();
  }

  componentWillUnmount() {
    document.querySelector('html').className = '';
    window.removeEventListener('scroll', this.handleScroll);
    if (this.tableContainer) {
      this.tableContainer.removeEventListener('scroll', this.handleScroll);
    }
  }

  hasAccess() {
    if (!this.context.currentUser) return false;

    return !isResearcher(this.context.currentUser.getIn(['roles', 0, 'slug'], null));
  }

  handleTableRef(tableContainer) {
    this.tableContainer = tableContainer;
  }

  handleScroll() {
    const { lazyLoadingIsEnabled } = this.state;

    const trs = this.tableContainer.getElementsByTagName('tr');
    const lastTr = trs[trs.length - 1];

    const tableHeight = this.tableContainer.offsetParent.offsetHeight;
    const lastTrTop = lastTr.getBoundingClientRect().y;
    const lastTrHeight = lastTr.offsetHeight;

    if (tableHeight - lastTrTop - lastTrHeight > 0 && lazyLoadingIsEnabled) {
      const { loadTargets, targetsPagination, common, match } = this.props;
      const currentPage = targetsPagination.get('currentPage');
      const { targetSortField, targetSortDirection } = common.toJS();
      const transformedField = targetSortField.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);
      const sort = targetSortDirection === 'down' ? `-${transformedField}` : transformedField;

      this.setState({ lazyLoadingIsEnabled: false });
      if (currentPage < targetsPagination.get('totalPages')) {
        loadTargets(
          match.params.projectId,
          {
            sort: targetSortField ? sort : null,
            page: currentPage + 1,
          },
          {
            afterSuccess: () => {
              this.setState({ lazyLoadingIsEnabled: true });
            },
          },
        );
      }
    }
  }

  showErrorsPopup() {
    const { common, templatePopup, clearTemplatePopupError, clearError } = this.props;
    const { closePopup, openPopup } = this.context;
    const errors = common.get('errors');

    if (errors && errors.size > 0) {
      const callback = () => {
        clearError();
        closePopup();
      };

      showError(openPopup, errors, callback);
    } else if (templatePopup) {
      const errors = templatePopup.get('errors');

      if (errors && errors.size > 0) {
        const callback = () => {
          clearTemplatePopupError();
          closePopup();
        };

        showError(openPopup, errors, callback);
      }
    }
  }

  handleRowClick({ row, type }) {
    const {
      switchUserRowToEdit,
      switchIndustryRowToEdit,
      switchApprovalRowToEdit,
      switchTemplateRowToEdit,
    } = this.props;
    const id = row.get('id');

    if (type === types.get('USERS')) {
      switchUserRowToEdit(id);
    } else if (type === types.get('INDUSTRIES')) {
      switchIndustryRowToEdit(id);
    } else if (type === types.get('APPROVALS')) {
      switchApprovalRowToEdit(row.get('index'));
    } else if (type === types.get('TEMPLATES')) {
      switchTemplateRowToEdit(id);
    }
  }

  handleRowDoubleClick({ row, type }) {
    const id = row.get('id');
    const projectId = row.get('projectId');

    if (type === types.get('APPROVALS')) {
      openInternalLink(`/project/${projectId}/approval/${id}`, false);
    } else if (type === types.get('USERS')) {
      openInternalLink(`/user/${id}`);
    } else if (type === types.get('TARGETS')) {
      openInternalLink(`/company/${id}`);
    }
  }

  handleNoApprovalDoubleClick() {
    const projectId = this.props.projectData.get('id');

    openInternalLink(`/project/${projectId}/approval/no_approval_lists`, false);
  }

  handleRowInsert(type) {
    if (type === types.get('USERS')) {
      this.props.insertUserRow();
    } else if (type === types.get('INDUSTRIES')) {
      const tags = getIn(this.props.common, 'selectedBICs');
      const models = getIn(this.props.common, 'selectedBMs');

      this.context.openPopup('TagsManagementPopup', {
        addNew: false,
        clientView: true,
        selectedTags: tags.reduce((acc, t) => {
          acc[t] = true;

          return acc;
        }, {}),
        selectedModels: models.reduce((acc, m) => {
          acc[m] = true;

          return acc;
        }, {}),
        callback: (tagSelecteds, modelSelecteds) => this.props.updateIndustryTags(tagSelecteds, modelSelecteds),
      });
    } else if (type === types.get('TEMPLATES')) {
      this.context.openPopup('TemplatePopup', {
        projectId: this.props.projectData.get('id'),
      });
    } else if (type === types.get('APPROVALS')) {
      this.props.insertApprovalRow();
    }
  }

  deleteApprovalWrapper(projectId, approvalId) {
    const { common } = this.props;

    this.props.deleteApproval({
      projectId,
      approvalId,
      field: common.get('approvalSortField'),
      direction: common.get('approvalSortDirection'),
    });
  }

  handleRowDelete(event, { id, type, approval }) {
    if (type === types.get('USERS')) {
      this.props.deleteUserRow(id);
    } else if (type === types.get('INDUSTRIES')) {
      this.props.deleteIndustryRow(id);
    } else if (type === types.get('TEMPLATES')) {
      this.props.deleteTemplateRow(this.props.projectData.get('id'), id);
    } else if (type === types.get('APPROVALS')) {
      this.handleApprovalRowDelete(null, { approval });
    }
  }

  handleApprovalRowDelete(e, { approval }) {
    this.deleteApproval(this.props.projectData.get('id'), approval.get('id'), approval.get('numberTargets', 0));
  }

  handleTextChange({ event, data: { type, row } }) {
    const {
      target: { name, value, checked },
    } = event;

    if (type === types.get('USERS')) {
      this.props.updateUserText(row.get('id'), value);
    } else if (type === types.get('INDUSTRIES')) {
      this.props.updateIndustryText(row.get('id'), value);
    } else if (type === types.get('PROJECT')) {
      this.props.updateProjectInfo(name, event.target.type.toUpperCase() === 'CHECKBOX' ? checked : value);
    } else if (type === types.get('APPROVALS')) {
      this.props.updateApprovals(row.get('index'), name, value);
    } else if (type === types.get('TEMPLATES')) {
      this.props.updateTemplateName(row.get('id'), value);
    }
  }

  handleSuggestionClose({ data }) {
    const { type, row } = data;

    if (type === 'users') {
      this.props.switchUserRowToEdit(row.get('id'));
    }
  }

  handleSuggestionSelect({ data, suggestion }) {
    const { type, row, name } = data;

    if (type === types.get('INDUSTRIES')) {
      if (suggestion.get('id') === -1) {
        this.props.reset();
        this.context.openPopup('NewIndustryPopup', { id: row.get('id') });
        this.props.changeLabel(row.get('name'));
      } else {
        this.props.selectIndustrySuggestion(row.get('id'), suggestion.get('id'), suggestion.get('name'));
      }
    } else if (type === types.get('USERS')) {
      this.props.selectUserSuggestion(row.get('id'), suggestion.get('id'), suggestion.get('name'));
    } else if (type === types.get('PROJECT')) {
      this.props.selectProjectSuggestion(name, suggestion.get('id'), suggestion.get('name'));
    }
  }

  handleSort(_, { type, direction, field }) {
    if (type === types.get('TARGETS')) {
      const {
        loadSortedTargets,
        sortTarget,
        targetsPagination,
        match: {
          params: { projectId },
        },
      } = this.props;
      const { currentPage, perPage } = targetsPagination.toJS();
      const transformedField = field.replace(/([A-Z])/g, $1 => `_${$1.toLowerCase()}`);
      const sort = direction === 'down' ? `-${transformedField}` : transformedField;
      const limit = currentPage * perPage;

      loadSortedTargets(projectId, { sort, limit });
      sortTarget(field, direction);
    } else if (type === types.get('APPROVALS')) {
      const { sortApproval } = this.props;

      sortApproval(field, direction);
    }
  }

  handleReset() {
    this.props.reset();
  }

  handleProjectInputChange({ event }) {
    const { name, value, checked } = event.target;

    this.props.updateProjectInfo(name, name === 'active' ? checked : value);
  }

  handleSuggestionInputClick({ event }) {
    event.stopPropagation();
    this.props.reset();
  }

  handleReload() {
    const {
      loadPage,
      match: {
        params: { projectId },
      },
    } = this.props;

    loadPage(
      projectId,
      {
        afterSuccess: () => {
          this.setState({ lazyLoadingIsEnabled: true });
        },
      },
      true,
    );
  }

  handleSave() {
    const {
      common,
      savePage,
      saveIndustries,
      match: {
        params: { projectId },
      },
    } = this.props;

    savePage({
      approval: {
        field: common.get('approvalSortField', ''),
        direction: common.get('approvalSortDirection', ''),
      },
    });

    const tags = getIn(common, 'selectedBICs');
    const models = getIn(common, 'selectedBMs');

    if (!tags.size || !models.size) {
      showError(this.context.openPopup, ['Industry tags or business models are missing']);
    } else {
      saveIndustries(projectId);
    }

    this.handleReload();
  }

  handleExport() {
    const host = this.apiBaseUrl;
    const projectId = this.props.projectData.get('id');

    downloadFile({
      url: `${host}/api/v1/projects/${projectId}/export_universe`,
    });
  }

  handleDownload(event, { id }) {
    const { templates } = this.props;
    const template = templates.find(t => t.get('id') === id);

    if (template) {
      downloadFile({
        url: template.get('url'),
      });
    }
  }

  saveTemplateChange(row) {
    const templateId = row.get('id');
    const projectId = this.props.projectData.get('id');

    if (isChanged(row.get('name'))) {
      this.props.saveTemplateName(projectId, templateId, getIn(row, 'name'));
    }
  }

  handleTemplateUpdate({ row }) {
    this.saveTemplateChange(row);
  }

  handleKeyPress({ event, row }) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.saveTemplateChange(row);
      this.props.reset();
    }
  }

  handleSomeChange(event, immutableTarget, field) {
    const { value } = event.target;
    const target = immutableTarget.toJS();

    target[field] = value;
    this.props.changeTarget({ field, value, id: target.id });
  }

  handleSubmit(event, approvedInfo) {
    event.preventDefault();
    if (approvedInfo && approvedInfo.field === 'approved') {
      this.props.saveTarget({
        tbId: approvedInfo.tbId,
        id: approvedInfo.id,
        approved: approvedInfo.approved,
      });

      return;
    }

    this.props.targets.forEach(target => {
      if (!isDeepChanged(target)) return;

      const tbId = target.get('tbId');
      const id = target.get('id');
      const changed = getChanged(target);

      this.props.saveTarget({ tbId, id, ...changed });
    });
  }

  handleFocus(name, value = '') {
    this.props.updateProjectInfo(name, value);
  }

  render() {
    return (
      <div className="full-height">
        <Helmet title={`${this.props.projectData.getIn(['category', 'name'], '')} [Project Page]`.trim()} />
        <If value={this.hasAccess()}>
          <Project
            {...this.props}
            approvalColumns={this.approvalColumns}
            onChange={this.handleSomeChange}
            onDeleteApprovalRow={this.handleApprovalRowDelete}
            onDeleteRow={this.handleRowDelete}
            onDownload={this.handleDownload}
            onExport={this.handleExport}
            onFocus={this.handleFocus}
            onKeyPress={this.handleKeyPress}
            onNoApprovalDoubleClick={this.handleNoApprovalDoubleClick}
            onProjectInputChange={this.handleProjectInputChange}
            onReload={this.handleReload}
            onReset={this.handleReset}
            onRowClick={this.handleRowClick}
            onRowDoubleClick={this.handleRowDoubleClick}
            onRowInsert={this.handleRowInsert}
            onSave={this.handleSave}
            onSort={this.handleSort}
            onSubmit={this.handleSubmit}
            onSuggestionClose={this.handleSuggestionClose}
            onSuggestionInputClick={this.handleSuggestionInputClick}
            onSuggestionSelect={this.handleSuggestionSelect}
            onTableRef={this.handleTableRef}
            onTemplateUpdate={this.handleTemplateUpdate}
            onTextChange={this.handleTextChange}
            targetColumns={this.targetColumns}
          />
        </If>
      </div>
    );
  }
}

ProjectContainer.propTypes = {
  approvals: PropTypes.instanceOf(List).isRequired,
  changeLabel: PropTypes.func.isRequired,
  changeTarget: PropTypes.func.isRequired,
  common: PropTypes.instanceOf(Map).isRequired,
  deleteIndustryRow: PropTypes.func.isRequired,
  deleteTemplateRow: PropTypes.func.isRequired,
  deleteUserRow: PropTypes.func.isRequired,
  industries: PropTypes.instanceOf(List).isRequired,
  loadPage: PropTypes.func.isRequired,
  loggedUser: PropTypes.instanceOf(Map),
  noApprovals: PropTypes.instanceOf(List).isRequired,
  projectData: PropTypes.instanceOf(Map).isRequired,
  reset: PropTypes.func.isRequired,
  saveIndustries: PropTypes.func.isRequired,
  savePending: PropTypes.bool.isRequired,
  saveTarget: PropTypes.func.isRequired,
  saveTemplateName: PropTypes.func.isRequired,
  selectIndustrySuggestion: PropTypes.func.isRequired,
  selectProjectSuggestion: PropTypes.func.isRequired,
  selectUserSuggestion: PropTypes.func.isRequired,
  switchUserRowToEdit: PropTypes.func.isRequired,
  targets: PropTypes.instanceOf(List).isRequired,
  templates: PropTypes.instanceOf(List).isRequired,
  updateApprovals: PropTypes.func.isRequired,
  updateIndustryText: PropTypes.func.isRequired,
  updateProjectInfo: PropTypes.func.isRequired,
  updateTemplateName: PropTypes.func.isRequired,
  updateUserText: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(List).isRequired,
};

ProjectContainer.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
  hasPopup: PropTypes.func.isRequired,
  currentUser: PropTypes.instanceOf(Map).isRequired,
};

const mapStateToProps = state => {
  const { targets, templatePopup, ...rest } = state.project;

  return {
    ...rest,
    targets: targets.get ? targets.get('data') : targets.data,
    targetsPagination: targets.get ? targets.get('pagination') : targets.pagination,
    loggedUser: state.auth.get('user'),
    savePending: detectDirty(state.project),
    templatePopup,
  };
};

export default connect(
  mapStateToProps,
  {
    loadTargets,
    loadSortedTargets,
    loadPage,
    savePage,
    reset,
    insertUserRow,
    insertIndustryRow,
    switchIndustryRowToEdit,
    switchUserRowToEdit,
    updateIndustryText,
    updateUserText,
    selectIndustrySuggestion,
    selectUserSuggestion,
    sortTarget,
    sortApproval,
    updateProjectInfo,
    selectProjectSuggestion,
    deleteUserRow,
    deleteIndustryRow,
    clearError,
    updateApprovals,
    insertApprovalRow,
    switchApprovalRowToEdit,
    changeToEditMode,
    updateTemplateName,
    switchTemplateRowToEdit,
    saveTemplateName,
    deleteTemplateRow,
    clearTemplatePopupError,
    saveTarget,
    changeTarget,
    changeLabel,
    deleteApproval,
    updateIndustryTags,
    saveIndustries,
  },
  mergeProps,
  connectOptions,
)(ProjectContainer);
