import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import { handleSortModelChanged } from 'src/utils/sorting';
import config from 'src/config';
import AgGridTable from 'src/components/helpers/AgGridTable';

const CompaniesTable = props => {
  const { data, events, onYearFilterChange, onCompaniesSortingChange, onGetNextPageData, onRowDoubleClicked } = props;

  const onGridReady = useCallback(params => {
    params.api.sizeColumnsToFit();
  }, []);

  const companies = data.get('companies');
  const isFetching = data.get('loadingCompanies');
  const columnDefs = config.tables.getIn(['tradeShowCompaniesTable', 'columnDefs']);

  const createYearFilterList = () => {
    const years = events.map(event => event.year);

    return years.filter((year, index, array) => array.indexOf(year) === index).sort();
  };

  const onSort = sortModel => {
    handleSortModelChanged(sortModel, data, onCompaniesSortingChange);
  };

  const getRowNodeId = data => data.id;

  return (
    <>
      <div className="trade-show-year-select">
        <label className="col-md-8">Year</label>
        <select className="year-filter" defaultValue="All" onChange={event => onYearFilterChange(event.target.value)}>
          <option value="All">All</option>
          {createYearFilterList().map(year => (
            <option key={year.toString()} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className="trade-show-companies-table">
        <AgGridTable
          columnDefs={columnDefs}
          getRowNodeId={getRowNodeId}
          isFetching={isFetching}
          onGetNextPageData={onGetNextPageData}
          onGridReady={onGridReady}
          onRowDoubleClicked={onRowDoubleClicked}
          onSortModelChanged={onSort}
          page={data.get('companiesCurrentPage')}
          rowData={companies}
          totalPages={data.get('companiesTotalPages')}
          sortable
        />
      </div>
    </>
  );
};

CompaniesTable.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
  events: PropTypes.instanceOf(Array).isRequired,
  onCompaniesSortingChange: PropTypes.func,
  onGetNextPageData: PropTypes.func,
  onRowDoubleClicked: PropTypes.func,
  onYearFilterChange: PropTypes.func,
};

export default CompaniesTable;
