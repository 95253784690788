import PropTypes from 'prop-types';

import React from 'react';
import { List } from 'immutable';

import WysiwygView from '../helpers/WysiwygView';

import { CompanyEmailLogLine } from './CompanyEmailLogLine';

const CompanyEmailPanel = ({ emails, onMouseEnter, onMouseLeave }) => {
  const content =
    emails.size === 0 ? (
      <p className="text-center"> No rows to display </p>
    ) : (
      emails.map((email, i) => (
        <CompanyEmailLogLine
          key={i}
          email={email}
          onMouseEnter={event => onMouseEnter(event, email.get('eventId'))}
          onMouseLeave={event => onMouseLeave(event, email.get('eventId'))}
        />
      ))
    );

  return (
    <div className="panel panel-default">
      <div className="panel-heading" data-parent="#accordion" data-target="#acc-slide-6" data-toggle="collapse">
        <h4 className="panel-title"> Email Log / Buyer Notes </h4>
      </div>
      <div className="panel-collapse collapse" id="acc-slide-6">
        <div className="panel-body company__email-panel-body">{content}</div>
      </div>
    </div>
  );
};

CompanyEmailPanel.propTypes = {
  emails: PropTypes.instanceOf(List).isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default CompanyEmailPanel;
