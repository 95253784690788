import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { debounce, isEmpty } from 'underscore';
import classNames from 'classnames';

import connectOptions, { mergeProps } from '../../utils/connectOptions';
import * as browseActionCreators from '../../actions/browse';
import BrowseTable from '../../components/Browse/BrowseTable';
import { BrowseAutoCompleteTag } from '../../components/Browse/BrowseAutoCompleteTag';
import { BrowseDropDown } from '../../components/Browse/BrowseDropDown';
import BrowseInputTag from '../../components/Browse/BrowseInputTag';
import config from '../../config';
import { buildTagParam, buildSortByParam, buildDropdownParam } from '../../helpers/paramBuilder';
import { showError } from '../../utils/MessagePopup';
import { checkBrowsePageAccess } from '../../utils/checkPermissions';

const projectsFilter = config.browse.getIn(['filter', 'projects']);
const columnDefs = config.browse.getIn(['table', 'columnDefs', 'projects']);

class BrowseProjects extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleGetNextPageData = this.handleGetNextPageData.bind(this);
    this.handleGetSuggestionForBuyer = debounce(this.handleGetSuggestionForBuyer.bind(this), 200);
    this.handleGetSuggestionForHarvcoLead = debounce(this.handleGetSuggestionForHarvcoLead.bind(this), 200);
    this.handleGetSuggestionForUsers = debounce(this.handleGetSuggestionForUsers.bind(this), 200);
    this.handleGetSuggestionForCategory = debounce(this.handleGetSuggestionForCategory.bind(this), 200);
    this.handleGetNextSuggestionForBuyer = debounce(this.handleGetNextSuggestionForBuyer.bind(this), 100);
    this.handleGetNextSuggestionForHarvcoLead = debounce(this.handleGetNextSuggestionForHarvcoLead.bind(this), 100);
    this.handleGetNextSuggestionForUsers = debounce(this.handleGetNextSuggestionForUsers.bind(this), 100);
    this.handleGetNextSuggestionForCategory = debounce(this.handleGetNextSuggestionForCategory.bind(this), 100);
    this.onToggle = this.onToggle.bind(this);
    this.handleClearFilter = this.handleClearFilter.bind(this);
    this.handleAbortRequest = this.handleAbortRequest.bind(this);
  }

  componentDidMount() {
    if (!this.checkPageAccess()) {
      showError(this.context.openPopup, config.permisionError, () => {
        this.context.closePopup();
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const sortModel = this.props.projects.getIn(['sortBy', 'sortModel']);
    const newSortModel = nextProps.projects.getIn(['sortBy', 'sortModel']);

    if (newSortModel !== sortModel) {
      const requestParam = this.buildRequestParam(nextProps.projects);

      if (isEmpty(requestParam)) {
        return;
      }

      const sortBy = buildSortByParam(nextProps.projects);

      this.props.handleBrowse({
        name: 'projects',
        params: requestParam,
        page: 1,
        sortBy,
      });
      this.oldRequestParam = requestParam;
    }
  }

  handleChange(data) {
    this.props.handleUpdateFilter({
      name: 'projects',
      filterName: data.filterName,
      filterData: data.filterData,
    });
  }

  handleGetNextPageData(page) {
    const requestParam = this.buildRequestParam(this.props.projects);
    const sortBy = buildSortByParam(this.props.projects);

    this.props.handleBrowse({
      name: 'projects',
      params: requestParam,
      page,
      sortBy,
    });
  }

  buildRequestParam(props) {
    const requestParams = {
      ...buildTagParam(props, 'buyers', 'buyer', 'id'),
      ...buildTagParam(props, 'harvco_leads', 'harvcoContact', 'id'),
      ...buildTagParam(props, 'users', 'users', 'id'),
      ...buildDropdownParam(props, 'active', 'active'),
      ...buildTagParam(props, 'names', 'category'),
      ...buildTagParam(props, 'industries', 'industry', 'value'),
    };

    return requestParams;
  }

  handleGetSuggestionForBuyer({ value }) {
    this.props.handleGetBuyerSuggestion('projects', value);
  }

  handleGetNextSuggestionForBuyer({ value }) {
    const info = this.props.projects.getIn(['buyer', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetBuyerSuggestion('projects', value, currentPage + 1);
    }
  }

  handleGetSuggestionForHarvcoLead({ value }) {
    this.props.handleGetHarvCoLeadsSuggestion('projects', value);
  }

  handleGetNextSuggestionForHarvcoLead({ value }) {
    const info = this.props.projects.getIn(['harvcoContact', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetHarvCoLeadsSuggestion('projects', value, currentPage + 1);
    }
  }

  handleGetSuggestionForUsers({ value }) {
    this.props.handleGetUsersSuggestion('projects', 'users', value);
  }

  handleGetNextSuggestionForUsers({ value }) {
    const info = this.props.projects.getIn(['users', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetUsersSuggestion('projects', 'users', value, currentPage + 1);
    }
  }

  handleGetSuggestionForCategory({ value }) {
    this.props.handleGetCategorySuggestion('projects', value);
  }

  handleGetNextSuggestionForCategory({ value }) {
    const info = this.props.projects.getIn(['category', 'pagination']);
    const currentPage = info.get('currentPage', null);

    if (currentPage !== null && currentPage < info.get('totalPages')) {
      this.props.handleGetCategorySuggestion('projects', value, currentPage + 1);
    }
  }

  handleDoubleClickRow(row) {
    window.open(`/project/${row.data.id}`, '_blank');
  }

  onToggle() {
    this.props.updateTabVisibility({
      field: 'projects.showTabControls',
      value: !this.props.projects.get('showTabControls'),
    });
  }

  handleClearFilter(name) {
    this.props.handleClearFilter({ name });
  }

  handleAbortRequest(name) {
    this.props.handleAbortRequest(name);
  }

  //* Check whether current user has access to current tab or not. */
  checkPageAccess() {
    return checkBrowsePageAccess(this.props.currentUser, 'projects');
  }

  render() {
    const { projects, ...rest } = this.props;
    const controlClassName = classNames('BrowseControls', {
      hidden: !projects.get('showTabControls'),
    });
    const hasParams = !isEmpty(this.buildRequestParam(projects));

    const hasPageAccess = this.checkPageAccess();

    return (
      <div className="tab-pane active" id="browse-projects" role="tabpanel">
        {hasPageAccess && (
          <div className="BrowseTabs-inner">
            <BrowseTable
              columnDefs={columnDefs}
              data={projects}
              hasParams={hasParams}
              name="projects"
              onAbortRequest={this.handleAbortRequest}
              onChange={this.handleChange}
              onClearFilter={this.handleClearFilter}
              onDoubleClickRow={this.handleDoubleClickRow}
              onGetNextPageData={this.handleGetNextPageData}
              onGetPageData={this.handleGetNextPageData}
              onToggle={this.onToggle}
              hideContextDropDown
              {...rest}
            />
            <div className={controlClassName}>
              <BrowseAutoCompleteTag
                filter={projectsFilter.get('buyer')}
                info={projects.get('buyer')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForBuyer}
                onGetSuggestion={this.handleGetSuggestionForBuyer}
              />
              <BrowseAutoCompleteTag
                filter={projectsFilter.get('harvcoContact')}
                info={projects.get('harvcoContact')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForHarvcoLead}
                onGetSuggestion={this.handleGetSuggestionForHarvcoLead}
              />
              <BrowseAutoCompleteTag
                filter={projectsFilter.get('users')}
                info={projects.get('users')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForUsers}
                onGetSuggestion={this.handleGetSuggestionForUsers}
              />
              <BrowseDropDown
                filter={projectsFilter.get('active')}
                info={projects.get('active')}
                onChange={this.handleChange}
              />
              <BrowseAutoCompleteTag
                filter={projectsFilter.get('category')}
                info={projects.get('category')}
                onChange={this.handleChange}
                onGetNextSuggestion={this.handleGetNextSuggestionForCategory}
                onGetSuggestion={this.handleGetSuggestionForCategory}
              />
              <BrowseInputTag
                filter={projectsFilter.get('industry')}
                info={projects.get('industry')}
                onChange={this.handleChange}
                isBIC
                submitOnEnter
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

BrowseProjects.propTypes = {};

BrowseProjects.contextTypes = {
  openPopup: PropTypes.func.isRequired,
  closePopup: PropTypes.func.isRequired,
};

export { BrowseProjects };
export default connect(
  state => ({
    projects: state.browse.get('projects'),
    currentUser: state.auth.get('user'),
  }),
  dispatch => bindActionCreators(browseActionCreators, dispatch),
  mergeProps,
  connectOptions,
)(BrowseProjects);
