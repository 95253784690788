import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

/**
 * Project component.
 *
 * @param props {Object}.
 * @param props.label {String} Label.
 * @param props.value {String} Value.
 * @param props.showLabel {Boolean} Set to false will hide the label.
 * @returns {React.Component}
 */
const ProjectInfoComponent = ({ showLabel = true, label, value, isCompanyName = false, buyerId }) => {
  const labelContent = showLabel ? `${label} ` : null;
  const content = isCompanyName ? (
    <Link className="company-name-link" target="_blank" to={`/company/${buyerId}/buyer`}>
      <span>{value}</span>
    </Link>
  ) : (
    <strong>{value}</strong>
  );

  return (
    <div className="project-header-info">
      {labelContent}
      {content}
    </div>
  );
};

ProjectInfoComponent.propTypes = {
  buyerId: PropTypes.number,
  isCompanyName: PropTypes.bool,
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  value: PropTypes.string,
};

export const ProjectInfo = memo(ProjectInfoComponent);
