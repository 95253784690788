import React, { memo } from 'react';
import classNames from 'classnames';
import downloadFile from 'src/utils/downloadFile';

import DropDown, { DropDownElement } from '../helpers/DropDown';

const ContactFooterComponent = (props) => {
  const { values, showSave, onSave, vCardUrl } = props;
  const handleDownloadVCard = event => {
    event.preventDefault();

    downloadFile({ url: vCardUrl });
  }

  const footerDropDown = values.get('footerDropDown').map((elem, i) => {
    const attributes = elem.get('attributes').toJS();

    return (
      <DropDownElement {...attributes} key={i} onClick={props[elem.get('onClickField')]}>
        {elem.get('name')}
      </DropDownElement>
    );
  });

  const saveButtonClassName = classNames('btn btn-default btn-xs', {
    hidden: !showSave,
  });

  return (
    <footer className="navbar-fixed-bottom">
      <div className="container">
        <div className="row">
          <div className="container-fluid text-right">
            <div className="btns">
              <button
                className="btn btn-xs"
                onClick={event => handleDownloadVCard(event)}
                title="Download a vCard"
                type="button"
              >
                <i aria-hidden="true" className="fa fa-user-plus" />
              </button>

              <DropDown
                align="right"
                btnContent="New"
                className="btn-default btn-xs"
                id="dropdownMenu7"
                wrapperClassName="ib"
              >
                {footerDropDown}
              </DropDown>

              <button className={saveButtonClassName} onClick={onSave} type="button">
                <i className="icon-user icon-white" />
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

/** Contact footer component. */
export const ContactFooter = memo(ContactFooterComponent);
