import React, { memo } from 'react';

import FormGroup from '../../helpers/FormGroup';
import _Input from '../../helpers/Input';
import ErrorDisplay from '../../decorators/DisplayError';
import PrettyError from '../../decorators/PrettyError';
import { PhoneInput } from './PhoneInput';

const Input = PrettyError(ErrorDisplay(_Input));

const ExtensionFormatComponent = ({ onChange, onBlur, contact, phoneError, onErrorClose, onUndo }) => {
  return (
    <div className="row">
      <div className="col-sm-5">
        <PhoneInput
          contact={contact}
          error={phoneError}
          onBlur={onBlur}
          onChange={onChange}
          onErrorClose={onErrorClose}
          onUndo={onUndo}
        />
      </div>
      <FormGroup className="col-sm-3">
        <Input
          className="input-md"
          label="Extension"
          maxLength="20"
          name="extension"
          onChange={onChange}
          placeholder="Extension"
          root={contact}
          canDisplayError
          deep
          showLabel
        />
      </FormGroup>
      <FormGroup className="col-sm-4">
        <_Input className="input-md" label="Type" name="type" placeholder="Type" value="Extension" showLabel />
      </FormGroup>
    </div>
  );
};

export const ExtensionFormat = memo(ExtensionFormatComponent);
