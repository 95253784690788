import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List } from 'immutable';

import { UserCheckBox } from './UserCheckBox';

const UserCheckBoxGroupComponent = props => {
  const { pairUser, onChange } = props;
  const firstUser = pairUser.get(0);
  const secondUser = pairUser.get(1);

  const firstCheckbox = firstUser ? (
    <UserCheckBox
      checked={firstUser.get('selected', false)}
      onChange={onChange}
      userId={firstUser.get('id')}
      userName={firstUser.get('name')}
    />
  ) : null;

  const secondCheckbox = secondUser ? (
    <UserCheckBox
      checked={secondUser.get('selected', false)}
      onChange={onChange}
      userId={secondUser.get('id')}
      userName={secondUser.get('name')}
    />
  ) : null;

  if (!firstUser && !secondUser) {
    return null;
  }

  return (
    <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2">
      {firstCheckbox}
      {secondCheckbox}
    </div>
  );
};

UserCheckBoxGroupComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
  pairUser: PropTypes.instanceOf(List).isRequired,
};

/** User check box group component. */
export const UserCheckBoxGroup = memo(UserCheckBoxGroupComponent);
