import React, { useEffect, useRef, memo } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';

import getZIndex from '../../../utils/getZIndex';
import uniqueId from '../../../utils/uniqueId';
import { isChanged, unwrap } from '../../../utils/ChangeSpy';
import { ErrorContent } from "./ErrorContent";

const PhoneInputComponent = ({
  onUndo,
  contact,
  onChange,
  className,
  onBlur,
  autoFocus,
  error,
  onErrorClose,
}) => {
  const id = useRef(uniqueId());
  const componentRef = useRef(null);
  const portalRef = useRef(null);

  useEffect(() => {
    if (autoFocus) {
      const timeout = setTimeout(() => {
        try {
          document.getElementById(id.current).focus();
        } catch (e) {
          console.warn(e);
        }
      }, 0);

      return () => clearTimeout(timeout);
    }
  }, [autoFocus]);

  useEffect(() => {
    return () => {
      if (portalRef.current?.node && portalRef.current.node.parentNode) {
        portalRef.current.node.parentNode.removeChild(portalRef.current.node);
      }
    };
  }, []);

  const renderError = () => {
    if (!error || !componentRef.current) return null;

    const bodyRect = document.documentElement.getBoundingClientRect();
    const targetRect = componentRef.current.getBoundingClientRect();

    if (targetRect.bottom === 0 && targetRect.top === 0) return null;

    const zIndex = getZIndex(componentRef.current) + 1;
    const top = targetRect.bottom - bodyRect.top;
    const left = targetRect.left - bodyRect.left;
    const { width } = targetRect;

    const style = {
      position: 'absolute',
      minWidth: '150px',
      maxHeight: '200px',
      overflowY: 'auto',
      top,
      left,
      width,
      zIndex,
    };

    const container = (
      <div ref={portalRef} style={style}>
        <ErrorContent onClose={onErrorClose}>{error}</ErrorContent>
      </div>
    );

    return createPortal(container, document.body);
  };

  const handleFocus = () => {
    if (onErrorClose) {
      onErrorClose();
    }
  };

  const divClass = classNames('clearfix form-group', className);
  const value = unwrap(contact.get('unformattedPhone'));
  const changed = isChanged(contact.get('unformattedPhone'));
  const inputClass = classNames('form-control input-md', { changed });

  const label = onUndo ? (
    <label htmlFor={id.current}>
      Phone -
      <a href="#" onClick={onUndo} title="Undo">
        undo
      </a>
    </label>
  ) : (
    <label htmlFor={id.current}>Phone</label>
  );

  return (
    <div ref={componentRef} className={divClass}>
      {label}
      <span className="control-wrap">
        <input
          className={inputClass}
          id={id.current}
          maxLength={30}
          name="unformattedPhone"
          onBlur={onBlur}
          onChange={onChange}
          onFocus={handleFocus}
          value={value}
        />
      </span>
      {renderError()}
    </div>
  );
};

export const PhoneInput = memo(PhoneInputComponent);
