import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import { ResponsiveBar } from '@nivo/bar';
import sortBy from 'lodash/sortBy';

import { toJS } from 'src/utils/ChangeSpy';
import { getTargetsApproachedByYears } from 'src/actions/statistics';
import Spinner from 'src/components/helpers/Spinner';
import { countAddonKey, countPlatformKey, countBothKey, yearKey, defaultBarConfig } from './ChartConfig';

const EXTENDED_STATS_YEARS_COUNTS = 10;
const LEFT_MARGIN = 11;

const TargetsApproachedByYearsChart = ({ data, getTargetsApproachedByYears }) => {
  const yearsCounts = toJS(data.getIn(['targetsApproachedByYears', 'yearsCounts']));
  const { isFetching } = toJS(data.get('targetsApproachedByYears'));

  const getExtendedData = () => {
    getTargetsApproachedByYears(true);
  };

  const isVisibleMoreButton = !isFetching && yearsCounts.length && yearsCounts.length < EXTENDED_STATS_YEARS_COUNTS;

  const chartData = yearsCounts.map(data => ({
    [yearKey]: data.year,
    [countAddonKey]: data.countAddon,
    [countPlatformKey]: data.countPlatform,
    [countBothKey]: data.countBoth,
  }));

  const sortedChartData = sortBy(chartData, [yearKey]);

  const chartMaxValue = sortedChartData.reduce((value, data) => {
    const maxValue = data[countAddonKey] + data[countPlatformKey] + data[countBothKey];

    return value > maxValue ? value : maxValue;
  }, 0);

  const axisLeftMargin = String(chartMaxValue).length * LEFT_MARGIN;

  const spinner = isFetching ? <Spinner className="bg-white" /> : null;

  return (
    <div className="chart-container mb20">
      <div className="bg-black text-white flex-grid">
        <p className="h5 text-bold pl10">Targets Approached Per Year</p>
        {isVisibleMoreButton && (
          <p className="h5 text-bold pr10">
            <button aria-label="more" className="bg-black text-white no-border" onClick={getExtendedData} type="button">
              More...
            </button>
          </p>
        )}
      </div>
      <div className="bg-white p20">
        <div className="chart-wrapper">
          {isFetching === false && (
            <ResponsiveBar
              {...defaultBarConfig}
              data={sortedChartData}
              margin={{ top: 10, right: 0, bottom: 40, left: axisLeftMargin }}
              maxValue={chartMaxValue}
            />
          )}
          {spinner}
        </div>
      </div>
    </div>
  );
};

TargetsApproachedByYearsChart.propTypes = {
  data: PropTypes.instanceOf(Map).isRequired,
};

const mapActionToProps = {
  getTargetsApproachedByYears,
};

export default connect(null, mapActionToProps)(React.memo(TargetsApproachedByYearsChart));
