import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Map } from 'immutable';
import { bindAll } from 'underscore';

import DropDown, { DropDownElement } from '../helpers/DropDown';
import isFunction from '../../utils/isFunction';
import SelectRange from '../helpers/SelectRange';
import { BrowseAutoComplete } from './BrowseAutoComplete';
import { BrowseCheckbox } from './BrowseCheckbox';
import { BuyerFilterTag } from './BuyerFilterTag';

class BrowseBuyerFilter extends PureComponent {
  constructor(props) {
    super(props);

    bindAll(
      this,
      'handleAddTag',
      'handleRemoveTag',
      'handleApprovedChange',
      'handleActiveChange',
      'handleChangeStatus',
      'handleValuesChange',
    );
  }

  handleApprovedChange(event) {
    const { value } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        approved: {
          name: value,
          value,
        },
      },
    });
  }

  handleActiveChange(event) {
    const { checked } = event.target;

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        active: checked,
      },
    });
  }

  handleAddTag() {
    const { filter, info } = this.props;
    const buyerSelected = info.get('buyer').get('selected');

    if (buyerSelected) {
      const buyerSelectedText = buyerSelected.get(filter.getIn(['buyer', 'renderField']), '');
      let projectSelectedText = '(any project)';
      let approvalSelectedText = '(any approval list)';
      let prioritySelectedText = '(any priority)';
      let approvedSelectedText = '(any approval state)';
      let activeSelectedText = 'active or inactive';

      const selectedBuyer = {
        buyer_id: buyerSelected.get(filter.getIn(['buyer', 'valueField'])),
      };

      const minCurrValue = info.getIn(['currentStatus', 'text', 'min']);
      const maxCurrValue = info.getIn(['currentStatus', 'text', 'max']);
      const minHighValue = info.getIn(['highStatus', 'text', 'min']);
      const maxHighValue = info.getIn(['highStatus', 'text', 'max']);

      selectedBuyer.current_status_range = {
        min: minCurrValue,
        max: maxCurrValue,
      };

      selectedBuyer.high_status_range = {
        min: minHighValue,
        max: maxHighValue,
      };

      const currStatusText = `Curr: ${minCurrValue}-${maxCurrValue}`;
      const highStatusText = `High: ${minHighValue}-${maxHighValue}`;

      const projectSelected = info.get('project').get('selected');

      if (projectSelected) {
        const render = filter.getIn(['project', 'renderField']);

        selectedBuyer.project_id = projectSelected.get(filter.getIn(['project', 'valueField']));
        if (render && isFunction(isFunction)) {
          projectSelectedText = render(projectSelected.toJS());
        } else {
          projectSelectedText = projectSelected.get(render);
        }
      }

      const approvalSelected = info.get('approval').get('selected');

      if (approvalSelected) {
        selectedBuyer.approval_list_id = approvalSelected.get(filter.getIn(['approval', 'valueField']));
        approvalSelectedText = approvalSelected.get(filter.getIn(['approval', 'renderField']));
      }

      const prioritySelected = info.get('priority').get('selected');

      if (prioritySelected) {
        selectedBuyer.priority = prioritySelected.get(filter.getIn(['priority', 'valueField']));
        prioritySelectedText = prioritySelected.get(filter.getIn(['priority', 'renderField']));
      }

      switch (info.getIn(['approved', 'value'], '').toUpperCase()) {
        case 'YES':
          selectedBuyer.approved = true;
          approvedSelectedText = 'Yes';
          break;

        case 'NO':
          selectedBuyer.approved = false;
          approvedSelectedText = 'No';
          break;

        case 'NO RESPONSE':
          selectedBuyer.approved = null;
          approvedSelectedText = 'No Response';
          break;

        default:
          break;
      }

      switch (info.get('active')) {
        case null:

        // fallthrough
        case undefined:
          break;

        case true:
          selectedBuyer.active = true;
          activeSelectedText = 'active';
          break;

        case false:
          selectedBuyer.active = false;
          activeSelectedText = 'inactive';
          break;

        default:
          break;
      }

      this.props.onChange({
        filterName: filter.get('name'),
        filterData: {
          selectedList: info.get('selectedList').push(
            Map({
              tag: `${buyerSelectedText}/${currStatusText}/${highStatusText}/${projectSelectedText}/${approvalSelectedText}/${prioritySelectedText}/${approvedSelectedText}/${activeSelectedText}`,
              exclude: false,
            }),
          ),
          selectedListParam: info.get('selectedListParam').push(selectedBuyer),
          buyer: {
            text: '',
            selected: null,
          },
          project: {
            text: '',
            selected: null,
          },
          approval: {
            text: '',
            selected: null,
          },
          priority: {
            text: '',
            selected: null,
          },
          approved: {
            name: '',
            value: '',
          },
          active: null,
        },
      });
    }
  }

  handleRemoveTag(event) {
    event.stopPropagation();

    const { name } = event.target;
    const tagIndex = parseInt(name.replace('buyerFilterTag', ''), 10);
    let selectedList = this.props.info.get('selectedList');

    selectedList = selectedList.delete(tagIndex);

    let selectedListParam = this.props.info.get('selectedListParam');

    selectedListParam = selectedListParam.delete(tagIndex);

    this.props.onChange({
      filterName: this.props.filter.get('name'),
      filterData: {
        selectedList,
        selectedListParam,
      },
    });
  }

  handleChangeStatus(index) {
    const { filter, info, onChange } = this.props;
    const selectedList = info.get('selectedList');

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList: selectedList.updateIn([index, 'exclude'], exclude => !exclude),
      },
    });
  }

  handleValuesChange({ filterName, filterData }) {
    this.props.onSubFilterChange({
      filterName,
      filterData,
    });
  }

  render() {
    const { filter, info } = this.props;
    const approvedContent = filter.get('approved').map((approvedItem, i) => {
      const { attributes } = approvedItem;

      return (
        <DropDownElement {...attributes} key={i} value={approvedItem.get('value')}>
          {approvedItem.get('name')}
        </DropDownElement>
      );
    });
    const selectedList = info.get('selectedList');
    const tagListContent = selectedList
      .toJS()
      .map(({ tag, exclude }, i) => (
        <BuyerFilterTag
          key={i}
          exclude={exclude}
          index={i}
          onChangeStatus={this.handleChangeStatus}
          onRemoveTag={this.handleRemoveTag}
          value={tag}
        />
      ));

    return (
      <BrowseCheckbox {...this.props}>
        <div className="BrowseControl-content BrowseControl-content--form">
          {tagListContent}
          <div className="form-inline">
            <BrowseAutoComplete
              filter={filter.get('buyer')}
              info={info.get('buyer')}
              onChange={this.props.onSubFilterChange}
              onGetNextSuggestion={this.props.onGetNextBuyerSuggestion}
              onGetSuggestion={this.props.onGetBuyerSuggestion}
            />
            <SelectRange
              className="w90p"
              filter={filter.get('currentStatus')}
              info={info.get('currentStatus')}
              onChange={this.handleValuesChange}
              rangeData={this.props.rangeFilterData}
              title="Current Status"
            />
            <SelectRange
              className="w90p"
              filter={filter.get('highStatus')}
              info={info.get('highStatus')}
              onChange={this.handleValuesChange}
              rangeData={this.props.rangeFilterData}
              title="High Status"
            />
            <BrowseAutoComplete
              filter={filter.get('project')}
              info={info.get('project')}
              onChange={this.props.onSubFilterChange}
              onGetNextSuggestion={this.props.onGetNextProjectSuggestion}
              onGetSuggestion={this.props.onGetProjectSuggestion}
            />
            <BrowseAutoComplete
              filter={filter.get('approval')}
              info={info.get('approval')}
              onChange={this.props.onSubFilterChange}
              onGetNextSuggestion={this.props.onGetNextApprovalSuggestion}
              onGetSuggestion={this.props.onGetApprovalSuggestion}
            />
            <BrowseAutoComplete
              filter={filter.get('priority')}
              info={info.get('priority')}
              onChange={this.props.onSubFilterChange}
              onGetSuggestion={this.props.onGetPrioritySuggestion}
            />
            <div className="form-group form-group--inline form-group--dropdown">
              <label htmlFor="browse-approved">Approved</label>
              <DropDown
                btnContent={info.get('approved')}
                className="btn btn-default btn-xs"
                id="approved-select"
                name="approved-select"
                onChange={this.handleApprovedChange}
              >
                {approvedContent}
              </DropDown>
            </div>
            <div className="form-group form-group--inline form-group--checkbox">
              <label htmlFor="browse-active">Active</label>
              <div className="checkbox-wrap">
                <label>
                  <input checked={info.get('active')} onChange={this.handleActiveChange} type="checkbox" />
                </label>
              </div>
            </div>
            <div className="form-group form-group--add">
              <button className="btn btn-default btn-xs" onClick={this.handleAddTag}>
                <span className="fa fa-plus" />
              </button>
            </div>
          </div>
        </div>
      </BrowseCheckbox>
    );
  }
}

BrowseBuyerFilter.propTypes = {
  filter: PropTypes.instanceOf(Map).isRequired,
  info: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onGetApprovalSuggestion: PropTypes.func.isRequired,
  onGetBuyerSuggestion: PropTypes.func.isRequired,
  onGetNextApprovalSuggestion: PropTypes.func.isRequired,
  onGetNextBuyerSuggestion: PropTypes.func.isRequired,
  onGetNextProjectSuggestion: PropTypes.func.isRequired,
  onGetPrioritySuggestion: PropTypes.func.isRequired,
  onGetProjectSuggestion: PropTypes.func.isRequired,
  onSubFilterChange: PropTypes.func.isRequired,
};

export default BrowseBuyerFilter;
