import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Map, List } from 'immutable';

import AgGridTable from '../helpers/AgGridTable';
import { convertDateString } from '../../utils/dateFormat';
import { clickIfNotDouble } from '../../utils/doubleClickCheck';
import { handleSortModelChanged } from '../../utils/sorting';

class DateRenderer extends Component {
  shouldComponentUpdate(props) {
    return this.props.value !== props.value;
  }

  render() {
    return <span>{convertDateString(this.props.value)}</span>;
  }
}

class CheckboxCellRenderer extends Component {
  render() {
    if (!this.props.data) return <span />;

    return <input defaultChecked={this.props.data.selected} type="checkbox" />;
  }
}

class MailingCreateTable extends Component {
  constructor(props) {
    super(props);

    this.gridApi = null;
    this.gridColumnApi = null;
    this.onGridReady = this.onGridReady.bind(this);
    this.onSort = this.onSort.bind(this);
    this.handleRowDoubleClicked = this.handleRowDoubleClicked.bind(this);
    [this.onCellClicked, this.handleRowDoubleClicked] = clickIfNotDouble(
      this.onCellClicked.bind(this),
      this.handleRowDoubleClicked.bind(this),
      row => row.rowIndex,
    );
  }

  getColumnDefs() {
    if (this.props.columnDefs !== this.lastColumnDefs) {
      this.lastColumnDefs = this.props.columnDefs;
      this.columnDefs = this.props.columnDefs.map(columnDef => {
        const field = columnDef.get('field');

        switch (field) {
          case 'selected':
            return columnDef.set('cellRendererFramework', CheckboxCellRenderer);

          case 'naDate':
            return columnDef.set('cellRendererFramework', DateRenderer);

          default:
            return columnDef;
        }
      });
    }

    return this.columnDefs;
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  }

  onCellClicked(params) {
    const {
      colDef: { field },
      data: { order, selected },
    } = params;

    if (field === 'selected') {
      this.props.onUpdateSelectedRow({
        index: order,
        field: 'selected',
        value: !selected,
      });
    }
  }

  handleRowDoubleClicked(row) {
    this.props.onDoubleClickRow(row);
  }

  onSort(sortModel) {
    handleSortModelChanged(sortModel, this.props.data, this.props.onChange);
  }

  componentDidUpdate(oldProps) {
    if (oldProps.data.getIn(['sortBy', 'sortModel']) !== this.props.data.getIn(['sortBy', 'sortModel'])) {
      // this.gridApi.setSortModel(this.props.data.getIn(['sortBy', 'sortModel']).toJS());
    }

    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  }

  static getRowNodeId(data) {
    return data.order;
  }

  render() {
    const { data, onGetNextPageData, onDoubleClickRow } = this.props;

    return (
      <div className="MailingCreateTable">
        <AgGridTable
          columnDefs={this.getColumnDefs()}
          getRowNodeId={MailingCreateTable.getRowNodeId}
          isFetching={data.get('isFetching')}
          onCellClicked={this.onCellClicked}
          onGetNextPageData={onGetNextPageData}
          onGridReady={this.onGridReady}
          onRowDoubleClicked={onDoubleClickRow}
          onSortModelChanged={this.onSort}
          page={data.get('page')}
          rowData={data.get('queryResults')}
          totalPages={data.get('totalPages')}
          sortable
        />
      </div>
    );
  }
}

MailingCreateTable.propTypes = {
  columnDefs: PropTypes.instanceOf(List).isRequired,
  data: PropTypes.instanceOf(Map).isRequired,
  onChange: PropTypes.func.isRequired,
  onDoubleClickRow: PropTypes.func.isRequired,
  onGetNextPageData: PropTypes.func.isRequired,
};

export default MailingCreateTable;
