import React, { useState } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';

import { Sorter } from './Sorter';
import { Filters } from './Filters';
import { LeadsReportTable } from './LeadsReportTable';
import FootWithExport from '../FootWithExport';
import { generateReport } from '../../actions/leadsReport';
import downloadFile from '../../utils/downloadFile';
import config from '../../config';
import Checkbox from '../helpers/Checkbox';

/**
 * Leads report component.
 *
 * @param {object} props Props.
 * @param {Immutable.Map} props.loggedUser Logged user.
 * @param {Immutable.List} props.usersIds Selected user IDs list.
 * @param {string} props.usersRole Current user role.
 * @param {boolean} props.isResultsFetching Show spinner icon when set to true.
 * @param {string} props.sort Single sort model by table columns.
 * @param {object} props.sorts Multi sort model by smart filter.
 * @param {Function} props.generateReport Method for getting leads report.
 */
const LeadsReportComponent = (props, context) => {
  const [hideDuplicates, setHideDuplicates] = useState(false);
  const { loggedUser, userRole, usersIds, isResultsFetching, sort, sorts, generateReport } = props;
  const userName = `${loggedUser.get('firstName', '')} ${loggedUser.get('lastName', '')}`;
  const isDisabledExport = [config.RESEARCH_INTERN, config.JUNIOR_ANALYST].includes(userRole) || usersIds.size === 0;

  /** Handle generate button click. */
  const handleGenerateClick = () => {
    generateReport(sort, sorts.toJS(), hideDuplicates);
  };

  /** Handle generate button click. */
  const handleExportClick = () => {
    const url = `${config.API_BASE_URL}/api/v1/events/leads_report/export`;

    downloadFile({
      url,
      headers: {
        'content-type': 'application/json',
      },
      method: 'post',
      body: { user_ids: usersIds.toArray(), hide_duplicates: hideDuplicates },
    });
  };

  /** Handle generate button click. */
  const handleHideDuplicatesCheck = event => {
    setHideDuplicates(event.target.checked);
  };

  /** Handle table hide duplicates button click. */
  const handleOpenDuplicatesPopupClick = () => {
    const subheader = (
      <p>
        If a Lead is sent to more than one client, then that Lead may exist with more than one party. Checking this box
        will cause each Target Company to only appear once. The client with the most recent activity will be the only
        one displayed.
      </p>
    );

    context.openPopup('InformationPopup', {
      message: {
        subheader,
        isCustomSubheader: true,
        texts: [],
      },
      header: <span>Hide Duplicates</span>,
    });
  };

  /** Handle table legend button click. */
  const handleOpenLegendPopupClick = () => {
    const subheader = (
      <ul className="pl0">
        <li key="grey">
          Rows in <span className="fa-square fa grey-shaded-effect-legend" /> - associated Buyer is not the Active buyer
          for the Target Company AND the Active Buyer is not Harvey
        </li>
        <li key="blue">
          Rows in <span className="fa-square fa blue-shaded-effect-legend" /> - associated Buyer is not the Active buyer
          for the Target Company AND the Active Buyer is Harvey
        </li>
        <li key="red">
          Tail cell in <span className="fa-square fa highlighted-tail-cell-legend" /> - date was calculated as 24 months
          from the &quot;Lead Sent&quot; date. BOTH &quot;Last Introduced&quot; and &quot;Termination&quot; are
          unselected OR both are selected
        </li>
        <li key="italic">
          Tail cell date in <span className="text-italic text-bold">Italic</span> - 18 month estimate used; agreement
          &quot;End Date&quot; is null
        </li>
      </ul>
    );

    context.openPopup('InformationPopup', {
      message: {
        subheader,
        isCustomSubheader: true,
        texts: [],
      },
      header: <span>Legend</span>,
    });
  };

  return (
    <>
      <div className="container full-height pb50">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="leads-title">{`Leads Report - ${userName} - ${moment().format('L')}`}</h1>
          </div>
        </div>
        <div className="row flex-stretched full-height pb70">
          <div className="col-xs-8 col-sm-8 col-md-10 col-lg-10">
            <div className="controls-right">
              <div className="form-hide-duplicates">
                <Checkbox label="Hide Duplicates" name="hideDuplicates" onChange={handleHideDuplicatesCheck} />
              </div>
              <div className="form-hide-duplicates">
                <i
                  aria-hidden="true"
                  className="fa fa-question cursor-pointer mr6"
                  onClick={handleOpenDuplicatesPopupClick}
                />
              </div>
              <button className="btn btn-default btn-xs mr6" onClick={handleOpenLegendPopupClick} type="button">
                <span className="fa-gear fa" />
                Legend
              </button>
              <button
                className="btn btn-default btn-xs"
                disabled={isResultsFetching}
                onClick={handleGenerateClick}
                type="button"
              >
                <span className="fa-refresh fa" />
                Generate
              </button>
            </div>
            <LeadsReportTable />
          </div>
          <div className="col-xs-4 col-sm-4 col-md-2 col-lg-2 leads-settings">
            <Sorter />
            <Filters />
          </div>
        </div>
      </div>
      <FootWithExport disabled={isDisabledExport} onClick={handleExportClick} />
    </>
  );
};

const mapStateToProps = state => ({
  loggedUser: state.auth.get('user'),
  userRole: state.auth.getIn(['user', 'roles', 0, 'slug']),
  usersIds: state.leadsReport.getIn(['filters', 'usersIds']),
  isResultsFetching: state.leadsReport.get('fetchingResults'),
  sort: state.leadsReport.get('sort'),
  sorts: state.leadsReport.get('sorts'),
});

const mapActionToProps = {
  generateReport,
};

LeadsReportComponent.contextTypes = {
  openPopup: PropTypes.func.isRequired,
};

const LeadsReport = connect(mapStateToProps, mapActionToProps)(React.memo(LeadsReportComponent));

export { LeadsReport };
