import { push } from 'connected-react18-router';

import { CALL_API, CHAIN_API } from '../middleware/api';
import { loadIndustryTags } from './industryTag';
import { loadBusinessModels } from './company/companyTarget';
import { reloadEvents } from './company/companyEvents';

export const UPDATE_COMPANY_INFO = Symbol('UPDATE_COMPANY_INFO');
export const UPDATE_PARENT_COMPANY = Symbol('UPDATE_PARENT_COMPANY');
export const FETCHING_COMPANY = Symbol('FETCHING_COMPANY');
export const FETCHING_COMPANY_SUCCESS = Symbol('FETCHING_COMPANY_SUCCESS');
export const FETCHING_COMPANY_FAILURE = Symbol('FETCHING_COMPANY_FAILURE');
export const FETCHING_LIST_COMPANY_SUCCESS = Symbol('FETCHING_LIST_COMPANY_SUCCESS');
export const FETCHING_LIST_COMPANY = Symbol('FETCHING_LIST_COMPANY');
export const DELETE_COMPANY = Symbol('DELETE_COMPANY');
export const SAVE_COMPANY_ERROR = Symbol('SAVE_COMPANY_ERROR');
export const SAVE_COMPANY_SUCCESS = Symbol('SAVE_COMPANY_SUCCESS');
export const CLOSE_VALIDATION_ERROR = Symbol('CLOSE_VALIDATION_ERROR');
export const LOAD_COUNTRIES_SUCCESS = Symbol('LOAD_COUNTRIES_SUCCESS');
export const COUNTRIES_LOADED = Symbol('COUNTRIES_LOADED');
export const UPDATE_COMPANY_COUNTRY = Symbol('UPDATE_COMPANY_COUNTRY');
export const REVERT_COMPANY_COUNTRY = Symbol('REVERT_COMPANY_COUNTRY');
export const REVERT_COMPANY_PARENT = Symbol('REVERT_COMPANY_PARENT');
export const ON_COMPANY_INFO_HEIGHT_CHANGE = Symbol('ON_COMPANY_INFO_HEIGHT_CHANGE');
export const RELOAD_MAIN_INFO_SUCCESS = Symbol('RELOAD_MAIN_INFO_SUCCESS');
export const UPDATE_CRITERIA_BIC_AFTER_COMPANY_LOADED = Symbol('UPDATE_CRITERIA_BIC_AFTER_COMPANY_LOADED');

export const CONFIRM_TARGET_SUCCESS = Symbol('CONFIRM_TARGET_SUCCESS');
export const CONFIRM_TARGET_ERROR = Symbol('CONFIRM_TARGET_ERROR');
export const CONFIRM_COMPANIES_MERGING_SUCCESS = Symbol('CONFIRM_COMPANIES_MERGING_SUCCESS');
export const CONFIRM_COMPANIES_MERGING_ERROR = Symbol('CONFIRM_COMPANIES_MERGING_ERROR');
export const CONFIRM_COMPANIES_MERGE_REVERT_SUCCESS = Symbol('CONFIRM_COMPANIES_MERGE_REVERT_SUCCESS');
export const CONFIRM_COMPANIES_MERGE_REVERT_ERROR = Symbol('CONFIRM_COMPANIES_MERGE_REVERT_ERROR');

export function updateBIC(data) {
  return {
    type: UPDATE_CRITERIA_BIC_AFTER_COMPANY_LOADED,
    data,
  };
}

/**
 * Update company info.
 *
 * @param info
 * @returns {{type: symbol, info: *}}
 */
function updateCompanyInfo(info) {
  return {
    type: UPDATE_COMPANY_INFO,
    info,
  };
}

/**
 * Revert parent company.
 */
export function revertParentCompany() {
  return {
    type: REVERT_COMPANY_PARENT,
  };
}

/**
 * Update parent company info.
 *
 * @param info
 * @returns {{type: symbol, info: *}}
 */
function updateParentCompany(info) {
  return {
    type: UPDATE_PARENT_COMPANY,
    info,
  };
}

/**
 * @param info
 * @returns {Function}
 */
export function handleUpdateCompanyInfo(info) {
  return function(dispatch) {
    dispatch(updateCompanyInfo(info));
  };
}

export function getCompanyInfo({ companyId, afterSuccess, afterError }, bic = false) {
  const companyAction = {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}`,
      query: {
        include: [
          'events',
          'subsidiaries',
          'entity_contacts',
          'exec_funds',
          'harvco_tags',
          'custom_fields',
          'entity_contacts',
          'exec_funds',
          'target_products',
          'target_fiscal_years',
          'target_competitors',
          'target_markets',
          'target_customers',
          'target_facilities',
          'buyer_industries',
          'industries',
          'buyer_projects',
          'batches',
          'parents_history',
          'targets',
          'buyers',
          'add_on_project',
          'buyer_addon_projects',
          'executives',
          'limited_partners',
          'industry_categories',
          'business_models',
          'buyer_industry_categories',
          'buyer_business_models',
          'company_retainers',
          'comparable',
        ].join(','),
      },
      startType: FETCHING_COMPANY,
      errorType: FETCHING_COMPANY_FAILURE,
      successType: FETCHING_COMPANY_SUCCESS,
      afterSuccess,
      afterError,
    },
  };

  if (!bic) {
    return companyAction;
  }

  return dispatch => Promise.all([dispatch(loadBusinessModels()), dispatch(loadIndustryTags()), dispatch(companyAction)]);
}

export function reloadMainInfo(companyId) {
  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/companies/${companyId}`,
      unifier: `get /api/v1/companies/${companyId}`,
      successType: RELOAD_MAIN_INFO_SUCCESS,
    },
  };
}

export function handleUpdateParentCompany(info) {
  return function(dispatch) {
    dispatch(updateParentCompany(info));
  };
}

export function handleGetSuggestion(like) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/companies',
      query: { like },
      startType: FETCHING_LIST_COMPANY,
      successType: FETCHING_LIST_COMPANY_SUCCESS,
    },
  };
}

export function delCompany({ companyId, afterSuccess, afterError }) {
  const oldAfterSuccess = afterSuccess;

  afterSuccess = ({ dispatch }) => {
    oldAfterSuccess();
    dispatch(push('/'));
  };

  return {
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/companies/${companyId}`,
      successType: DELETE_COMPANY,
      afterSuccess,
      afterError,
    },
  };
}

export function saveCompanyDetail({ companyId, body }) {
  return {
    body,
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/companies/${companyId}`,
      unifier: `save company ${companyId} ${JSON.stringify(body)}`,
      body,
      afterSuccess: ({ dispatch }) => {
        dispatch(reloadEvents({ entityId: companyId }));
        if (Object.keys(body).some(key => /parent/i.test(key))) {
          dispatch(getCompanyInfo({ companyId }));
        }
      },
      errorType: SAVE_COMPANY_ERROR,
      successType: SAVE_COMPANY_SUCCESS,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_VALIDATION_ERROR,
    field,
  };
}

export function getCountries(page = 1) {
  return {
    [CALL_API]: {
      method: 'get',
      path: '/api/v1/browse/countries',
      unifier: `get countries page = ${page} /api/v1/browse/countries`,
      query: {
        page,
        limit: 100,
      },
      successType: LOAD_COUNTRIES_SUCCESS,
    },
  };
}

export function setCountryLoaded() {
  return {
    type: COUNTRIES_LOADED,
  };
}

export function handleUpdateCountry(info) {
  return {
    type: UPDATE_COMPANY_COUNTRY,
    info,
  };
}

export function revertCountry() {
  return {
    type: REVERT_COMPANY_COUNTRY,
  };
}

export function onHeightChange(height) {
  return {
    type: ON_COMPANY_INFO_HEIGHT_CHANGE,
    height,
  };
}

export function approveTarget(companyId, afterSuccess, afterError) {
  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'post',
          path: `/api/v1/companies/${companyId}/approve`,
          unifier: `post /api/v1/companies/${companyId}/approve`,
          successType: CONFIRM_TARGET_SUCCESS,
          errorType: CONFIRM_TARGET_ERROR,
          afterSuccess,
          afterError,
        },
      }),
      () => getCompanyInfo({ companyId }),
    ],
  };
}

export function confirmMerging(companyId, afterSuccess, afterError) {
  return {
    [CHAIN_API]: [
      () => ({
        [CALL_API]: {
          method: 'put',
          path: `/api/v1/companies/${companyId}/confirm_merging`,
          unifier: `put /api/v1/companies/${companyId}/confirm_merging`,
          successType: CONFIRM_COMPANIES_MERGING_SUCCESS,
          errorType: CONFIRM_COMPANIES_MERGING_ERROR,
          afterSuccess,
          afterError,
        },
      }),
      () => getCompanyInfo({ companyId }),
    ],
  };
}

export function confirmMergeRevert(companyId, afterSuccess, afterError) {
  return {
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/companies/${companyId}/revert_merging`,
      unifier: `put /api/v1/companies/${companyId}/revert_merging`,
      successType: CONFIRM_COMPANIES_MERGE_REVERT_SUCCESS,
      errorType: CONFIRM_COMPANIES_MERGE_REVERT_ERROR,
      afterSuccess,
      afterError,
    },
  };
}
