import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import config from '../../../config';

/**
 * Project text input component.
 *
 * @param props {Object}.
 * @param props.field {String} Field name.
 * @param props.onTextChange {Function} Handle event of changing text.
 * @returns {React.Component}
 */
const ProjectTextInputComponent = ({ field, onTextChange }) => {
  const className = field.get('dirty') ? 'form-control dirty-field' : 'form-control';
  const type = config.values.getIn(['project', 'types', 'PROJECT']);

  return (
    <div className="form-group">
      <label className="col-md-4" htmlFor="projectCategoryInput">
        Category:
      </label>
      <div className="col-md-8">
        <input
          className={className}
          name="category"
          onChange={event => onTextChange({ event, data: { type } })}
          type="text"
          value={field.get('name')}
        />
      </div>
    </div>
  );
};

ProjectTextInputComponent.propTypes = {
  field: PropTypes.instanceOf(Immutable.Map).isRequired,
  onTextChange: PropTypes.func.isRequired,
};

export const ProjectTextInput = memo(ProjectTextInputComponent);
