import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';
import { ContextMenuTrigger } from 'react-contextmenu';
import classNames from 'classnames';

import config from '../../../config';
import doubleClickCheck from '../../../utils/doubleClickCheck';
import { unwrap } from '../../../utils/ChangeSpy';
import getPercent from '../../../utils/getPercent';

/**
 * Row in view mode component.
 *
 * @param props {Object}.
 * @param props.row {Immutable.Map} Row information.
 * @param props.onClick {Function} Function to handle event of clicking on row.
 * @param props.onDoubleClick {Function} Function to handle event of double clicking on row.
 * @returns {React.Component}
 */
const ViewComponent = ({ row, onClick, onDoubleClick, contextId, onCollect }) => {
  const className = classNames('cell', { 'cell--marked': row.get('dirty') });
  const dateSentMoment = unwrap(row.get('dateSent'));
  const dateSent = dateSentMoment && dateSentMoment.isValid() ? dateSentMoment.format('L') : '';
  const dateReceivedMoment = unwrap(row.get('dateReceived'));
  const dateReceived = dateReceivedMoment && dateReceivedMoment.isValid() ? dateReceivedMoment.format('L') : '';
  const rowType = config.values.getIn(['project', 'types', 'APPROVALS']);
  const percentApproved = `${row.get('percentApproved')}%`;
  const numberTargets = row.get('numberTargets');
  const numberApproved = row.get('numberApproved');
  const numberOfContacted = row.get('numberOfContacted');
  const numberOfLeads = row.get('numberOfLeads');
  const numberOfCc = row.get('numberOfCc');
  const numberOfVisit = row.get('numberOfVisit');
  const numberOfNextActions = row.get('numberOfNextActions');
  const numberOfPriorityA = row.get('numberOfPriorityA');
  const numberOfPriorityB = row.get('numberOfPriorityB');
  const numberOfPriorityC = row.get('numberOfPriorityC');
  const numberOfApprovedX = row.get('numberOfApprovedX');
  const applistLabel = unwrap(row.get('applistLabel'));

  const handleRowClick = event => {
    event.stopPropagation();
    doubleClickCheck({ row, type: rowType }, ({ eventType, data }) => {
      if (eventType === 'click') {
        onClick(data);
      } else {
        onDoubleClick(data);
      }
    });
  };

  const Tr = ({ children, ...rest }) => (
    <tr {...rest} onClick={handleRowClick}>
      {children}
    </tr>
  );

  return (
    <ContextMenuTrigger collect={onCollect} id={contextId} renderTag={Tr}>
      <td className={className}>
        <div>{applistLabel}</div>
      </td>
      <td className={className}>
        <div>{dateSent}</div>
      </td>
      <td className={className}>
        <div>{dateReceived}</div>
      </td>
      <td className={className}>
        <div>{numberTargets}</div>
      </td>
      <td className={className}>
        <div>
          {numberApproved} ({percentApproved})
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfContacted} ({getPercent(numberOfContacted, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfLeads} ({getPercent(numberOfLeads, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfCc} ({getPercent(numberOfCc, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfVisit} ({getPercent(numberOfVisit, numberApproved)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfNextActions} ({getPercent(numberOfNextActions, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfPriorityA} ({getPercent(numberOfPriorityA, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfPriorityB} ({getPercent(numberOfPriorityB, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfPriorityC} ({getPercent(numberOfPriorityC, numberTargets)}%)
        </div>
      </td>
      <td className={className}>
        <div>
          {numberOfApprovedX} ({getPercent(numberOfApprovedX, numberTargets)}%)
        </div>
      </td>
    </ContextMenuTrigger>
  );
};

ViewComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  row: PropTypes.instanceOf(Immutable.Map).isRequired,
};

export const View = memo(ViewComponent);
