import { push } from 'connected-react18-router';

import { CALL_API, CHAIN_API } from '../middleware/api';

import { loadContactLP } from './contact/contactLP';

// loading
export const CREATE_CONTACT = Symbol('CREATE_CONTACT');
export const DELETE_CONTACT = Symbol('DELETE_CONTACT');
export const LOAD_CONTACT_DETAIL = Symbol('LOAD_CONTACT_DETAIL');
export const SAVE_CONTACT_DETAIL = Symbol('SAVE_CONTACT_DETAIL');
export const SAVED_CONTACT_DETAIL = Symbol('SAVED_CONTACT_DETAIL');
export const ERROR_CONTACT_DETAIL = Symbol('ERROR_CONTACT_DETAIL');
export const ERROR_SAVE_CONTACT_DETAIL = Symbol('ERROR_SAVE_CONTACT_DETAIL');
export const LOADED_CONTACT_DETAIL = Symbol('LOADED_CONTACT_DETAIL');

export const ADD_CONTACT_FIELD = Symbol('ADD_CONTACT_FIELD');
export const EDIT_CONTACT_FIELD = Symbol('EDIT_CONTACT_FIELD');
export const STOP_EDIT_CONTACT_FIELD = Symbol('STOP_EDIT_CONTACT_FIELD');

export const CHANGE_CONTACT_FIELD = Symbol('CHANGE_CONTACT_FIELD');
export const DELETE_CONTACT_FIELD = Symbol('DELETE_CONTACT_FIELD');
export const REVERT_CONTACT_FIELD = Symbol('REVERT_CONTACT_FIELD');
export const SAVE_CONTACT_FIELD = Symbol('SAVE_CONTACT_FIELD');

export const SAVED_CONTACT_PHONE = Symbol('SAVED_CONTACT_PHONE');
export const ERRORED_SAVE_CONTACT_PHONE = Symbol('ERRORED_SAVE_CONTACT_PHONE');
export const SAVED_CONTACT_EMAIL = Symbol('SAVED_CONTACT_EMAIL');
export const ERRORED_SAVE_CONTACT_EMAIL = Symbol('ERRORED_SAVE_CONTACT_EMAIL');
export const SAVED_CONTACT_ADDRESS = Symbol('SAVED_CONTACT_ADDRESS');
export const ERRORED_SAVE_CONTACT_ADDRESS = Symbol('ERRORED_SAVE_CONTACT_ADDRESS');

export const CLOSE_CONTACT_VALIDATION_ERROR = Symbol('CLOSE_CONTACT_VALIDATION_ERROR');
export const SET_NAME_FIELD_ERROR = Symbol('SET_NAME_FIELD_ERROR');

function loadContactData({ id, startType, afterSuccess }) {
  if (id === 'new') return { type: CREATE_CONTACT };

  return {
    [CALL_API]: {
      method: 'get',
      path: `/api/v1/people/${id}`,
      query: {
        include: ['phones', 'emails', 'addresses', 'events'].join(','),
      },
      startType,
      successType: LOADED_CONTACT_DETAIL,
      errorType: ERROR_CONTACT_DETAIL,
      afterSuccess,
    },
  };
}

/**
 * Load contact detail action.
 *
 * @param {number} id Contact id.
 */
export function loadContactDetail({ id }) {
  return loadContactData({
    id,
    startType: LOAD_CONTACT_DETAIL,
    afterSuccess: ({ dispatch }) => {
      dispatch(loadContactLP({ id }));
    },
  });
}

export function reloadContactDetail({ id }, afterSuccess) {
  return loadContactData({ id, afterSuccess });
}

function createContact({ contact }) {
  return {
    [CHAIN_API]: [
      () => ({
        fields: Object.keys(contact),
        [CALL_API]: {
          method: 'post',
          path: '/api/v1/people',
          body: contact,
          startType: SAVE_CONTACT_DETAIL,
          successType: SAVED_CONTACT_DETAIL,
          afterSuccess: ({ dispatch, response }) => {
            const { id } = response;

            dispatch(push(`/contact/${id}`));
          },
          errorType: ERROR_SAVE_CONTACT_DETAIL,
        },
      }),
    ],
  };
}

function updateContact({ id, contact }) {
  return {
    fields: Object.keys(contact),
    [CALL_API]: {
      method: 'put',
      path: `/api/v1/people/${id}`,
      unifier: `put /api/v1/people/${id} {${JSON.stringify(contact)}}`,
      body: contact,
      startType: SAVE_CONTACT_DETAIL,
      successType: SAVED_CONTACT_DETAIL,
      errorType: ERROR_SAVE_CONTACT_DETAIL,
    },
  };
}

export function saveContactDetail({ id, ...rest }) {
  const isCreating = id === 'new';

  if (isCreating) return createContact({ id, ...rest });

  return updateContact({ id, ...rest });
}

export function saveContactPhone({ contactId, id, body }) {
  const isCreating = /^new/.test(id);
  let method = 'put';
  let path = `/api/v1/people/${contactId}/phones/${id}`;

  if (isCreating) {
    method = 'post';
    path = `/api/v1/people/${contactId}/phones`;
  }

  return {
    id,
    fields: Object.keys(body),
    [CALL_API]: {
      method,
      path,
      unifier: `${method} ${path} ${JSON.stringify(body)}}`,
      body,
      successType: SAVED_CONTACT_PHONE,
      errorType: ERRORED_SAVE_CONTACT_PHONE,
    },
  };
}

export function saveContactEmail({ contactId, id, body }) {
  const isCreating = /^new/.test(id);
  let method = 'put';
  let path = `/api/v1/people/${contactId}/emails/${id}`;

  if (isCreating) {
    method = 'post';
    path = `/api/v1/people/${contactId}/emails`;
  }

  return {
    id,
    fields: Object.keys(body),
    [CALL_API]: {
      method,
      path,
      unifier: `${method} ${path} ${JSON.stringify(body)}}`,
      body,
      successType: SAVED_CONTACT_EMAIL,
      errorType: ERRORED_SAVE_CONTACT_EMAIL,
    },
  };
}

export function saveContactAddress({ contactId, id, body }) {
  const isCreating = /^new/.test(id);
  let method = 'put';
  let path = `/api/v1/people/${contactId}/addresses/${id}`;

  if (isCreating) {
    method = 'post';
    path = `/api/v1/people/${contactId}/addresses`;
  }

  return {
    id,
    fields: Object.keys(body),
    [CALL_API]: {
      method,
      path,
      unifier: `${method} ${path} ${JSON.stringify(body)}}`,
      body,
      successType: SAVED_CONTACT_ADDRESS,
      errorType: ERRORED_SAVE_CONTACT_ADDRESS,
    },
  };
}

// changing
export const CHANGE_CONTACT_INFO_FIELD = Symbol('CHANGE_CONTACT_FIELD');

export function changeContactInfoField({ name, value }) {
  if (/emails.\d+.value/i.test(name) && value) {
    value = value.trim();
  }

  return {
    type: CHANGE_CONTACT_INFO_FIELD,
    name,
    value,
  };
}

export function addContactField({ field }) {
  return {
    type: ADD_CONTACT_FIELD,
    field,
  };
}

export function setNameFieldError({ field, value }) {
  return {
    type: SET_NAME_FIELD_ERROR,
    field,
    value,
  };
}

export function editContactField({ field, index }) {
  return {
    type: EDIT_CONTACT_FIELD,
    field,
    index,
  };
}

export function changeContactField({ field, value, name, index }) {
  return {
    type: CHANGE_CONTACT_FIELD,
    field,
    value,
    name,
    index,
  };
}

export function revertContactField() {
  return {
    type: REVERT_CONTACT_FIELD,
  };
}

export function saveContactField() {
  return {
    type: SAVE_CONTACT_FIELD,
  };
}

export function stopEditContactField() {
  return {
    type: STOP_EDIT_CONTACT_FIELD,
  };
}

export function delContactField({ id, afterSuccess, field, fieldId, index }) {
  if (id === 'new' || fieldId === 'new') {
    afterSuccess();

    return { type: DELETE_CONTACT_FIELD, field, index };
  }

  return {
    index,
    field,
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/people/${id}/${field}/${fieldId}`,
      successType: DELETE_CONTACT_FIELD,
      afterSuccess,
    },
  };
}

export function delContact({ contactId, afterSuccess }) {
  const oldAfterSuccess = afterSuccess;

  afterSuccess = ({ dispatch }) => {
    oldAfterSuccess();
    dispatch(push('/'));
  };

  return {
    [CALL_API]: {
      method: 'delete',
      path: `/api/v1/people/${contactId}`,
      successType: DELETE_CONTACT,
      afterSuccess,
    },
  };
}

export function closeValidationError({ field }) {
  return {
    type: CLOSE_CONTACT_VALIDATION_ERROR,
    field,
  };
}
