import React, { Component } from 'react';
import { SortableContainer } from 'react-sortable-hoc';
import { ContextMenu, MenuItem, ContextMenuTrigger } from 'react-contextmenu';
import { arrayMoveImmutable } from 'array-move';

import { SortableItem } from '../../decorators/SortableItem';
import uniqueId from '../../../utils/uniqueId';
import { isWrapped, unwrap } from '../../../utils/ChangeSpy';

const ContactItem = ({ children, ...rest }) => (
  <div {...rest} className="list-item">
    {children}
  </div>
);

const SortableContactItem = SortableItem(ContactItem);

const ContextMenuWrapper = ({ onDel, onCopy, onEdit }) => {
  const ContextMenuWithCopyDel = (type, value, index) => {
    const ContextMenuWrapped = content => {
      const id = uniqueId();

      return (
        <div>
          <ContextMenuTrigger holdToDisplay={-1} id={id}>
            {content}
          </ContextMenuTrigger>

          <ContextMenu id={id}>
            <MenuItem data={{ index, type, value }} onClick={onEdit}>
              <i className="fa fa-pencil" /> Edit
            </MenuItem>
            <MenuItem data={{ index, type, value }} onClick={onCopy}>
              <i className="fa fa-save" /> Copy
            </MenuItem>
            <MenuItem data={{ index, type, value }} onClick={onDel}>
              <i className="fa fa-remove" /> Delete
            </MenuItem>
          </ContextMenu>
        </div>
      );
    };

    return ContextMenuWrapped;
  };

  return ContextMenuWithCopyDel;
};

class ItemSorter extends Component {
  constructor(...rest) {
    super(...rest);

    this.setItems = this.setItems.bind(this);
    this.onSortEnd = this.onSortEnd.bind(this);
    this.state = {
      items: this.setItems(this.props.items || []),
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      items: this.setItems(nextProps.items || []),
    });
  }

  setItems(items) {
    return items.map((item, i) => ({ ...item, channelIndex: i })).sort(this.sortItems);
  }

  onSortEnd({ oldIndex, newIndex }) {
    this.setState(
      ({ items }) => ({
        items: arrayMoveImmutable(items, oldIndex, newIndex),
      }),
      () => {
        if (this.props.reorderChannels) {
          // when users stop dragging
          const { items } = this.state;

          return this.props.reorderChannels(items);
        }
      },
    );
  }

  sortItems(first, second) {
    if (first.priority < second.priority) return -1;
    if (first.priority > second.priority) return 1;

    return 0;
  }

  handleClick(event, item) {
    event.stopPropagation();
    event.preventDefault();
    if (item) {
      window.location = `mailto:${encodeURI(item.email.value)}`;
    }
  }

  render() {
    const {
      className,
      name,
      readOnly,
      verifyField = 'isVerify',
      isVisibleVerifyIcon,
      onDel,
      onCopy,
      onEditChannel,
    } = this.props;
    const { items } = this.state;
    const contactsValues = [];

    items.forEach((item, i) => {
      const itemValue = name === 'phones' ? item.phone.value : item.email.value;
      const type = item.type.value;

      if (readOnly) {
        contactsValues.push(
          <strong key={i}>
            {itemValue} {type ? `(${type})` : null}
          </strong>,
        );
      } else {
        let icon = null;

        if (isVisibleVerifyIcon) {
          if ((!isWrapped(item[verifyField]) && item[verifyField]) || unwrap(item[verifyField])) {
            icon = <i className="text-success fa fa-check" />;
          }
        }

        const content = ContextMenuWrapper({
          onDel: event => onDel(event, name, item.channelIndex),
          onCopy,
          onEdit: () => onEditChannel(name, item.channelIndex),
        })(
          name,
          itemValue,
          i,
        )(
          <div className="item-sorter">
            <strong className="truncate-text w-auto">
              <a
                href="#"
                onClick={event => {
                  this.handleClick(event, name === 'emails' ? item : null);
                }}
              >
                {itemValue} {type ? `(${type})` : null}
              </a>
            </strong>
            {icon}
          </div>,
        );

        contactsValues.push(
          <SortableContactItem key={i} index={i}>
            {content}
          </SortableContactItem>,
        );
      }
    });

    return (
      <div className={className}>
        <SortableContactList contacts={contactsValues} distance={1} lockAxis="y" onSortEnd={this.onSortEnd} />
      </div>
    );
  }
}

const SortableContactList = SortableContainer(({ contacts }) => <ul className="pl0">{contacts}</ul>);

export default ItemSorter;
