import React, { memo } from 'react';
import DatePicker from 'react-datepicker';

import SelectRange from '../helpers/SelectRange';
import { BrowseCheckbox } from './BrowseCheckbox';
import { BrowseAutoComplete } from './BrowseAutoComplete';
import { BrowseDropDown } from '../../components/Browse/BrowseDropDown';
import { BrowseAutoCompleteTag } from './BrowseAutoCompleteTag';

const BrowseActivityFilterComponent = ({
  filter,
  info,
  projectTypeFilter,
  projectTypeInfo,
  usersFilter,
  usersInfo,
  statuses,
  onChange,
  onGetSuggestion,
  onGetActivityUserSuggestion,
  onGetNextActivityUserSuggestion,
  onSubActivityFilterChange,
}) => {
  const handleChangeProjectType = data => {
    onChange({
      filterName: filter.get('name'),
      filterData: {
        [data.filterName]: data.filterData,
      },
    });
  };

  const handleValuesChange = ({ filterName, filterData }) => {
    onSubActivityFilterChange({
      filterName,
      filterData,
    });
  }

  const handleStartDateChange = date => {
    if (date.isValid()) {
      onChange({
        filterName: filter.get('name'),
        filterData: {
          startDate: date,
        },
      });
    }
  }

  const handleEndDateChange = date => {
    if (date.isValid()) {
      onChange({
        filterName: filter.get('name'),
        filterData: {
          endDate: date,
        },
      });
    }
  }

  const handleEventDescriptionChange = event => {
    const { value } = event.target;

    onChange({
      filterName: filter.get('name'),
      filterData: {
        eventDescription: value,
      },
    });
  }

  const handleAddTag = () => {
    const name = filter.get('name');
    const eventActivity = filter.get('eventActivity');
    const eventSelected = info.getIn(['selected', eventActivity.get('renderField')]) || '(any activity)';
    const eventDescription = info.get('eventDescription') || '(any description)';
    const selected = `${eventSelected} - ${eventDescription}`;
    let selectedList = info.get('selectedList');

    if (!selectedList.includes(selected)) {
      selectedList = selectedList.push(selected);

      onChange({
        filterName: name,
        filterData: {
          selectedList,
          text: '',
          selected: null,
          eventDescription: '',
        },
      });
    }
  }

  const handleRemoveTag = event => {
    const { name } = event.target;
    const tagIndex = parseInt(name.replace('buyerFilterTag', ''), 10);
    const selectedList = info.get('selectedList').delete(tagIndex);

    onChange({
      filterName: filter.get('name'),
      filterData: {
        selectedList,
      },
    });
  }

  const handleLastActivityChange = event => {
    onChange({
      filterName: filter.get('name'),
      filterData: {
        lastActivity: event.target.checked,
        statusChanged: !event.target.checked,
      },
    });
  }

  const handleStatusChangedChange = event => {
    onChange({
      filterName: filter.get('name'),
      filterData: {
        statusChanged: event.target.checked,
        lastActivity: !event.target.checked,
      },
    });
  }

  const hasActivityUser = usersFilter && usersInfo;

  const selectedList = info.get('selectedList');
  const statusChanged = info.get('statusChanged');
  const tagListContent = statusChanged
    ? null
    : selectedList.map((tag, i) => (
      <div key={i} className="MultiselectItem">
        {tag}
        <button
          aria-hidden="true"
          aria-label="close"
          className="MultiselectItem-close close btn-xs"
          name={`buyerFilterTag${i}`}
          onClick={handleRemoveTag}
          type="button"
        >
          &times;
        </button>
      </div>
    ));

  return (
    <BrowseCheckbox filter={filter} info={info} onChange={onChange}>
      <div className="BrowseControl-content BrowseControl-content--activity">
        <div className="form-inline">
          <div className="form-group">
            <div className="form-group form-group--inline form-group--checkbox">
              <label htmlFor="last-activity-checkbox">
                <div className="checkbox-wrap">
                  <input
                    checked={info.get('lastActivity')}
                    id="last-activity-checkbox"
                    onChange={handleLastActivityChange}
                    type="checkbox"
                  />
                </div>
                Last Activity Only
              </label>
            </div>

            <label>From</label>
            <DatePicker
              className="form-control"
              dateFormat="MM/DD/YYYY"
              dropdownMode="select"
              onChange={handleStartDateChange}
              selected={info.get('startDate')}
              showMonthDropdown
              showYearDropdown
            />
          </div>
          <div className="form-group">
            <label>To</label>
            <DatePicker
              className="form-control"
              dateFormat="MM/DD/YYYY"
              dropdownMode="select"
              onChange={handleEndDateChange}
              selected={info.get('endDate')}
              showMonthDropdown
              showYearDropdown
            />
          </div>
          <div className="form-group form-group--inline form-group--checkbox">
            <label htmlFor="status-changed-checkbox">
              <div className="checkbox-wrap">
                <input
                  checked={statusChanged}
                  id="status-changed-checkbox"
                  onChange={handleStatusChangedChange}
                  type="checkbox"
                />
              </div>
              Status Changed
            </label>
          </div>
          {info.get('statusChanged') ?
            <div>
              <span>From</span>
              <SelectRange
                filter={filter.get('statusChangedFrom')}
                info={info.get('statusChangedFrom')}
                onChange={handleValuesChange}
                rangeData={statuses}
              />
              <span>To</span>
              <SelectRange
                filter={filter.get('statusChangedTo')}
                info={info.get('statusChangedTo')}
                onChange={handleValuesChange}
                rangeData={statuses}
              />
            </div> : null}
          {tagListContent}
          <BrowseAutoComplete
            className="form-control"
            filter={filter.get('eventActivity')}
            info={info}
            isHidden={statusChanged}
            onChange={onChange}
            onGetSuggestion={onGetSuggestion}
          />
          {!statusChanged && (
            <div className="form-group input-group">
              <input
                className="form-control"
                onChange={handleEventDescriptionChange}
                placeholder="Event Description"
                type="text"
                value={info.get('eventDescription')}
              />
              <span className="input-group-addon" onClick={handleAddTag}>
                <i aria-hidden="true" className="fa fa-plus" />
              </span>
            </div>
          )}
          {hasActivityUser && (
            <BrowseAutoCompleteTag
              filter={usersFilter}
              info={usersInfo}
              onChange={onChange}
              onGetNextSuggestion={onGetNextActivityUserSuggestion}
              onGetSuggestion={onGetActivityUserSuggestion}
              noCheckbox
            />
          )}
          {projectTypeFilter && projectTypeInfo && (
            <div className="form-group">
              <BrowseDropDown
                filter={projectTypeFilter}
                info={projectTypeInfo}
                onChange={handleChangeProjectType}
              />
            </div>
          )}
        </div>
      </div>
    </BrowseCheckbox>
  );
}

/** Browse activity filter component. */
export const BrowseActivityFilter = memo(BrowseActivityFilterComponent);
