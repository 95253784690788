import PropTypes from 'prop-types';
import React, { memo } from 'react';
import Immutable from 'immutable';

import config from '../../../config';

const getThTitle = field => {
  switch (field) {
    case 'numberApproved':
      return 'Approved% = #Approved / #Targets';

    case 'numberOfContacted':
      return 'Contacted% = #Contacted / #Approved';

    case 'numberOfLeads':
      return 'Leads% = #Leads / #Approved';

    case 'numberOfCc':
      return 'CC% = #CC / #Approved';

    case 'numberOfVisit':
      return 'Visit% = #Visit / #Approved';

    case 'numberOfNextActions':
      return 'NAs% = #NAs / #Targets';

    case 'numberOfPriorityA':
      return 'A% = #A / #Targets';

    case 'numberOfPriorityB':
      return 'B% = #B / #Targets';

    case 'numberOfPriorityC':
      return 'C% = #C / #Targets';

    case 'numberOfApprovedX':
      return 'X% = #X / #Targets';

    default:
      return '';
  }
};

/**
 * Table columns component.
 *
 * @param props {Object}.
 * @param props.common {Immutable.Map} Common information.
 * @param props.columns {Immutable.List} List of column configuration.
 * @param props.onClick {Function} Function to handle event of clicking column head.
 * @param props.onInsert {Function} Function to handle event of adding a new row.
 * @returns {React.Component}
 */
const ColumnsComponent = ({ common, columns, onClick, onInsert }) => {
  const sortDirection = common.get('approvalSortDirection');
  const sortField = common.get('approvalSortField');
  const type = config.values.getIn(['project', 'types', 'APPROVALS']);

  return (
    <thead>
      <tr>
        {columns.map((column, index) => {
          const field = column.get('field');
          const headerName = column.get('headerName');

          if (field === 'applistLabel') {
            return (
              <th key={index}>
                <span
                  className="add-row"
                  onClick={event => {
                    event.stopPropagation();
                    onInsert(type);
                  }}
                  title="Add row"
                >
                  +
                </span>
                <a
                  className="text-regular"
                  onClick={event =>
                    onClick(event, {
                      direction: sortDirection === 'up' ? 'down' : 'up',
                      field,
                      type,
                    })
                  }
                >
                  {headerName} {field === sortField ? <i className={`fa fa-caret-${sortDirection}`} /> : null}
                </a>
              </th>
            );
          }

          return (
            <th key={index} title={getThTitle(field)}>
              <a
                className="text-regular"
                onClick={event =>
                  onClick(event, {
                    direction: sortDirection === 'up' ? 'down' : 'up',
                    field,
                    type,
                  })
                }
              >
                {headerName} {field === sortField ? <i className={`fa fa-caret-${sortDirection}`} /> : null}
              </a>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

ColumnsComponent.propTypes = {
  columns: PropTypes.instanceOf(Immutable.List).isRequired,
  common: PropTypes.instanceOf(Immutable.Map).isRequired,
  onClick: PropTypes.func.isRequired,
  onInsert: PropTypes.func.isRequired,
};

export const Columns = memo(ColumnsComponent);
