import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { List } from 'immutable';

import Spinner from '../helpers/Spinner';
import Button from '../helpers/Button';

import { UserCheckBoxGroup } from './UserCheckBoxGroup';

const UserListComponent = ({
  users,
  loading = false,
  onChange,
  onReloadClick,
  onOneOffApproachClick,
  onClick
}) => {
  if (loading) {
    return (
      <div className="top-user-bg">
        <Spinner />
      </div>
    );
  }

  // Split user list to an array which array's elements have two users
  // Convert to array because of using Maps as children is not yet fully supported.
  const pairUserList = users.groupBy((element, index) => Math.floor(index / 2)).toArray();

  const mapUserCheckbox = (pair, index) => <UserCheckBoxGroup key={index} onChange={onChange} pairUser={pair} />;

  return (
    <div className="top-user-bg">
      {pairUserList.map(mapUserCheckbox)}
      <div className="col-xs-12 col-sm-12 col-md-6 pull-right mailing-history">
        <div className="form-group group-inline">
          <div className="btn-toolbar backlog-userList-pull-right">
            <Button onClick={onOneOffApproachClick}>One-Off Approach</Button>
            <Button onClick={onReloadClick}>Reload</Button>
            <Button onClick={onClick}>Mailing History</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

UserListComponent.propTypes = {
  loading: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  users: PropTypes.instanceOf(List).isRequired,
};

/** User list component. */
export const UserList = memo(UserListComponent);
