import PropTypes from 'prop-types';

import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { Map } from 'immutable';
import { push } from 'connected-react18-router';
import connectOptions, { mergeProps } from '../../utils/connectOptions';

import { getChanged } from '../../utils/ChangeSpy';
import ContactLP from '../../components/Contact/ContactLP';
import { loadContactLP, changeField, findUsers, saveContactLp, clearSuggest } from '../../actions/contact/contactLP';
import AutoCompleteContainer from '../AutoComplete';
import { reloadContactDetail } from '../../actions/contactDetail';

class ContactLPContainer extends PureComponent {
  checkIfLp() {
    const { loading, match, isLP, push, loadContactLP } = this.props;
    const { hasPopup, openPopup, onClosePopup } = this.context;

    if (hasPopup()) return;
    if (loading) return;

    const { contactId } = match.params;
    let name;

    if (!isLP) {
      if (!isNaN(contactId)) name = 'LPRolePopup';
      else name = 'AddLpContactPopup';
      openPopup(name, { contactId });
      onClosePopup(() => {
        const { contactId } = match.params;

        if (!isLP || contactId !== 'new') {
          return push(`/contact/${contactId}`);
        }
      });
    } else {
      loadContactLP({ id: contactId });
    }
  }

  componentWillUnmount() {
    this.context.removeOnSaveCallback(this.onBlur);
  }

  componentDidMount() {
    this.checkIfLp();
    this.context.addOnSaveCallback(this.onBlur);
  }

  componentDidUpdate(lastProps) {
    if (lastProps.loading) this.checkIfLp();
  }

  constructor(props, context) {
    super(props, context);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);

    this.onUpdateRelation = this.onUpdateRelation.bind(this);
    this.onFetchUsers = this.onFetchUsers.bind(this);
    this.onSelectRelation = this.onSelectRelation.bind(this);
  }

  onChange(event) {
    const { name, value } = event.target;

    return this.props.changeField({ name, value });
  }

  onFetchUsers({ value }) {
    this.props.findUsers({ filter: value });
  }

  onUpdateRelation(param) {
    if ('text' in param) {
      const name = 'suggestRelation';
      const value = param.text;

      this.props.changeField({ name, value });
    }

    if ('suggestions' in param) {
      const name = 'suggests';
      const value = param.suggestions;

      this.props.changeField({ name, value });
    }
  }

  onSelectRelation(e, { suggestion }) {
    const name = 'suggestRelation';

    this.props.changeField({ name, value: suggestion.text });
  }

  onBlur() {
    const body = getChanged(this.props.contactLP);
    const { contactId } = this.props.match.params;

    if (Object.keys(body).length === 0) return;

    this.props.saveContactLp({ contactId, ...body });
  }

  static onSubmit(event) {
    event.preventDefault();
  }

  static onGetValue(suggestion) {
    return suggestion.value;
  }

  getSuggestRelation() {
    return {
      onFetch: this.onFetchUsers,
      onUpdate: this.onUpdateRelation,
      getValue: ContactLPContainer.onGetValue,
      onSelect: this.onSelectRelation,
      value: this.props.contactLP.get('suggestRelation', ''),
    };
  }

  getSuggest() {
    this.___suggests___ = this.___suggests___ || (
      <AutoCompleteContainer
        change={this.props.changeField}
        find={this.props.findUsers}
        idName="lpPrimaryRelId"
        rootPath={['contact', 'lp']}
        suggestsName="suggests"
        valueName="suggestRelation"
      />
    );

    return this.___suggests___;
  }

  render() {
    const { isLP, contactLP } = this.props;

    if (!isLP) {
      return null;
    }

    const suggestRelation = this.getSuggest();
    const loading = contactLP.get('loading');

    return (
      <ContactLP
        {...this.props}
        loading={loading}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onSubmit={ContactLPContainer.onSubmit}
        suggestRelation={suggestRelation}
      />
    );
  }
}

ContactLPContainer.propTypes = {
  changeField: PropTypes.func.isRequired,
  contactLP: PropTypes.instanceOf(Map).isRequired,
  loadContactLP: PropTypes.func.isRequired,
};

ContactLPContainer.contextTypes = {
  hasPopup: PropTypes.func.isRequired,
  onClosePopup: PropTypes.func.isRequired,
  openPopup: PropTypes.func.isRequired,
  addOnSaveCallback: PropTypes.func.isRequired,
  removeOnSaveCallback: PropTypes.func.isRequired,
};

ContactLPContainer.defaultProps = {
  loading: true,
};

const isLp = createSelector(
  state => state.contact.info,
  info => info.get('isLP'),
);

function mapStateToProps(state) {
  return {
    isLP: isLp(state),
    contactLP: state.contact.lp,
  };
}

export { ContactLPContainer };
export default connect(
  mapStateToProps,
  {
    loadContactLP,
    saveContactLp,
    changeField,
    reloadContactDetail,
    findUsers,
    clearSuggest,
    push,
  },
  mergeProps,
  connectOptions,
)(ContactLPContainer);
